import React, { Component }     from 'react';
import FileSaver                from 'file-saver';
import moment                   from 'moment';
import                          './CubeGrid.css';
import config                   from '../../../config';
import Utils                    from '../../../common/CommonUtilities';
import FetchService             from '../../../common/FetchService';
import {
     MuiThemeProvider
    ,createTheme
}                               from '@material-ui/core/styles';
import Paper                    from '@material-ui/core/Paper';
import FormGroup                from '@material-ui/core/FormGroup';
import FormControlLabel         from '@material-ui/core/FormControlLabel';
import Button                   from '@material-ui/core/Button';
import IconButton               from '@material-ui/core/IconButton';
import MenuItem                 from '@material-ui/core/MenuItem';
import Pagination               from '@material-ui/lab/Pagination';
import Tooltip                  from '@material-ui/core/Tooltip';
import ToggleButton             from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup        from '@material-ui/lab/ToggleButtonGroup';
import ChevronRightIcon         from '@material-ui/icons/ChevronRight';
import HomeIcon                 from '@material-ui/icons/Home';
import KeyboardTabIcon          from '@material-ui/icons/KeyboardTab';
import { CubeTable }            from './CubeTable';
import { Switch }               from '@material-ui/core';
import FlipCameraAndroidIcon    from '@material-ui/icons/FlipCameraAndroid';
import LockIcon                 from '@material-ui/icons/PinDrop';
import GridOnIcon               from '@material-ui/icons/GridOn';
import InputIcon                from '@material-ui/icons/Input';
import ReplayIcon               from '@material-ui/icons/Replay';
import CubeCommons              from './CubeCommons';
import Graph                    from '../../../common/Graph';
import PersonIcon               from '@material-ui/icons/Person';
import Menu                     from '@material-ui/core/Menu';
import PeopleIcon               from '@material-ui/icons/People';
import VpnKeyIcon               from '@material-ui/icons/VpnKey';
import ExitToAppIcon            from '@material-ui/icons/ExitToApp';

const bDebug = false;

const CONFIG_PAGE_ROWS = config.PAGE_ROWS || 100;
const GRAPH_LIMIT      = 100;
const GRAPH_LIMIT_BARS = 30;

const theme = createTheme({
    overrides: { MuiTooltip: { tooltip: { fontSize: '0.9em' } } },
    palette: {
        primary: {
            main: '#509556',
        },
        secondary: {
            main: 'rgb(33, 33, 33)'
        }
    }
});

class CubeGrid extends Component {

    constructor(props) {
        super(props);
        this.cubeTableRef = React.createRef();
        this.state = {
            
             aoQueryResults:         []
            ,anMeasureColumns:       [0]
            ,bTabularMode:           true
            ,bInvertPivot:           false
            ,bRowTotal:              false
            ,sSubTotals:             ''     // 'top' (default) oppure '' (no subtotali)
            ,bShowAllValues:         false
            ,bColsLocked:            true
            
            ,nCurrentPage:           0            
            ,bAutoReload:            true
            ,sOldParams:             ''
            ,bShowTable:             false
            ,bQueryParamsChanged:    false
            ,sGraphSelected:         ''
            ,anchorEl:               null
            
        }
    }

    componentDidUpdate      = async ( prevProps, prevState ) => {
        // debug && Utils.logDifferences( '--- CubeGrid (cambio di props) ---', prevProps, this.props );
        // debug && Utils.logDifferences( '--- CubeGrid (cambio di state) ---', prevState, this.state );

        // resetto tutti i parametri
        if ( this.props.bReset !== prevProps.bReset ) {
            await Utils.setStateAsync({
                 anMeasureColumns   : ( ( this.props.oLayout && this.props.oLayout.oStructure ) || {} ).anMeasureColumns || [0] // default sempre almeno '#' (numero) selezionato
                ,bTabularMode       : true
                ,bInvertPivot       : false
                ,bRowTotal          : false
                ,sSubTotals         : ''
                ,bShowAllValues     : false
                ,bColsLocked        : true
                ,nCurrentPage       : 0
            }, this);
        }

        // recupero dall'eventuale layout selezionato i parametri salvati (se presenti)
        if (
               ( this.props?.oLayout?.column !== prevProps?.oLayout?.column )
            || ( this.props?.bReloadLayout    !== prevProps?.bReloadLayout  )
        ) {
            
            const oStructure = this.props?.oLayout?.oStructure;
            
            if ( oStructure ) {
                const oParamsToLoadFromLayout = { // TODO migliorare controlli di validazione dei dati presi da layout salvati su DB
                     anMeasureColumns   : oStructure.anMeasureColumns || [0]
                    ,bTabularMode       : !!oStructure.bTabularMode
                    ,bInvertPivot       : !!oStructure.bInvertPivot
                    ,bRowTotal          : !!oStructure.bRowTotal
                    ,sSubTotals         : !!oStructure.sSubTotals     ? 'top' : ''
                    ,bShowAllValues     : !!oStructure.bShowAllValues
                    ,bColsLocked        : !!oStructure.bColsLocked
                }
                await Utils.setStateAsync(oParamsToLoadFromLayout, this);
            }

        }

        // solo se viene cambiata la prop "bReloadAll" viene rilanciata la "loadData"
        if ( this.props.bReloadAll     !== prevProps.bReloadAll     ) {
            await this.loadData();
        }

        if ( this.state.aoQueryResults !== prevState.aoQueryResults ) {
            await this.setState({ bShowTable: true });
        }
    
        if ( this.props.sGraphSelected !== prevProps.sGraphSelected ) {
            // console.log('sGraphSelected',this.props.sGraphSelected);
            await this.setState({
                 sGraphSelected:   this.props.sGraphSelected
                ,bShowTable:      !this.props.sGraphSelected
            });
        }
    
    }

    loadData                = async ( resetPaging = true ) => {

        // preselezione di default delle DIMENSIONS
        const aoDimensions          = this.props.aoDimensions || [];

        let aoDimensionsSelected    = aoDimensions.filter( oDimension => oDimension.selected && !oDimension.pivoted );
        if ( aoDimensionsSelected.length === 0 ) {
            console.error(             'No dimensions selected' );
            this.props.toggleNotifica( 'No dimensions selected' );
            return;
        }

        // chiamata al db per ottenere i dati
        const queryParams           = this.getParamsForDB();
        queryParams['pNumRecords' ] = CONFIG_PAGE_ROWS;
        queryParams['pFirstRecord'] = CONFIG_PAGE_ROWS * ( this.state.nCurrentPage || 0 );
        let sCheckParams            = JSON.stringify(queryParams);

        // solo se sono cambiati i parametri
        // if ( sCheckParams !== this.state.sOldParams ) {

        this.props.loading.set( true, 'CubeGrid - loadData' );

        const oStateToUpdate = { bShowTable: false };
        if ( resetPaging ) {
            //se non sto paginando (quindi cambio dimensioni) si torna sempre alla pagina 1
            oStateToUpdate['nCurrentPage']  = 0;
            queryParams.pFirstRecord        = 0;
            sCheckParams                    = JSON.stringify(queryParams);
        }

        await Utils.setStateAsync(oStateToUpdate, this);
        
        bDebug && console.log('--- CubeGrid: INIZIO chiamata a DB "get-cube-to-frontend" (nCubeCod:'+queryParams.nCubeCod+') ---');
        const response          = await FetchService.asyncPost({
            url: '/cubes/get-cube-to-frontend',
            params: queryParams,
            displayErrorHandler: this.props.toggleNotifica
        });
        bDebug && console.log('--- CubeGrid: FINE   chiamata a DB "get-cube-to-frontend" (nCubeCod:'+queryParams.nCubeCod+') ---');
        let aoQueryResults      = ( response && response.rows ) || [];

        /*
        if ( !response || !response.rows || ( response && response.err ) ) {
            console.error(response && response.err);
            this.props.toggleNotifica( 'An error occurred. Please contact system adminstrator.' );
        }
        */

        await Utils.setStateAsync({
            sOldParams:     sCheckParams,
            aoQueryResults
        }, this);

        this.props.loading.set( false, 'CubeGrid - loadData' );

    }

    getParamsForDB          = () => {

        const {
             anMeasureColumns
            ,bTabularMode
            ,bInvertPivot
            ,bRowTotal
            ,sSubTotals
            ,bShowAllValues
            ,bColsLocked
        } = this.state;

        const aoDimensions = this.props.aoDimensions || [];
        const aoMeasures   = this.props.aoMeasures   || [];
        // console.log('- CubeGrid - getParamsForDB - aoDimensions PUNTO_ORA_INI_BREAK filterType: ', aoDimensions.find( o => o.column === 'PUNTO_ORA_INI_BREAK' )?.filterType);
        const
            // filtro il parametro aoMeasures scegliendo solo quelle selezionate
            aoMeasuresSelected      = aoMeasures.filter(   oMeasure   => oMeasure.selected ),

            // filtro il parametro aoDimensions scegliendo solo quelle selezionate e non pivottate
            aoDimSelectedNotPivoted = aoDimensions.filter( oDimension => oDimension.selected && !oDimension.pivoted ),

            // le dimensioni selezionate saranno 1 nel caso di DrillDownMode e N nel caso di TabularMode
            aoDimensionsSelected    = bTabularMode ? aoDimSelectedNotPivoted : [ aoDimSelectedNotPivoted[ aoDimSelectedNotPivoted.length - 1 ] ],

            // prendo l'unica dimensione pivottata (se c'è)
            aoPivotDimensions       = aoDimensions.filter( oDimension => oDimension.pivoted ),

            // prendo l'unica misura con ordinamento (se c'è)
            oMeasureSorted          = aoMeasures.find(     oMeasure   => oMeasure.sortDirection ),

            // da aoDimensions recupero da ogni dimensione selezionata in che modo ognuna deve essere ordinata
            sDimensionSorting       = aoDimensionsSelected.map( oDimension => oDimension.column + ' ' + oDimension.sortDirection ).join(', '),

            // salvo in un array a parte, per ogni dimensione i relativi filtri scelti (preparandoli già in un'unica stringa per il DB)
            getAoFilters            = ( ao ) => ao
                .filter( o => o.asCurrentFilters && o.asCurrentFilters.length > 0 )
                .map(    o => ({
                             sColumn:         o.column,
                             sFilterType:     o.filterType,
                             sFilterDataType: o.filterDataType,
                             bExclude:        o.exclude,
                             asValues:        o.asCurrentFilters
                         })
                )
        ;
        // console.log(aoDimensions.filter( o => o.selected || o.pivoted).length);
        // console.table(aoDimensions.filter( o => o.selected || o.pivoted));
        let
            sSortingClause          = oMeasureSorted  // se è stato scelto un ordinamento per misura
                                      && ( sSubTotals
                                            // se sono abilitati i subtotali ordino per i subtotali 
                                            // e la misura stessa (Es. 'GROSS_0 DESC, GROSS_1 DESC, GROSS DESC')
                                            ? Utils.formatSortingMeasureClauseForDB(
                                                oMeasureSorted.column,
                                                oMeasureSorted.sortDirection,
                                                aoDimensions.filter( o => o.selected || o.pivoted).length
                                            )
                                            // se non sono abilitati i subtotali ordino solo per la misura (Es. 'GROSS DESC')
                                            : oMeasureSorted.column + ' ' + oMeasureSorted.sortDirection
                                         )
         // ,pSortingValue           = ( oMeasureSorted && oMeasureSorted.pSortingValue  ) || ''
        ;

        // inutile fare controlli per evitare di passare il pSortingValue in alcuni casi (tipo quando viene escluso)
        // perché ci sono casi in cui in base ad altre condizioni la colonna del pSortingValue potrebbe comunque NON essere estratta
        // quindi conviene controllare nei risultati se la colonna del pSortingValue è stata estratta e indicare l'effettivo sorting

        const pSortingClause        = ( sSortingClause ? sSortingClause + ', ' : '' ) + sDimensionSorting; // in ogni caso aggiungo le dimensioni in coda all'ordinamento

        // drilldown filters
        // recupero da aoDimensions solo le dimensioni selezionate non pivottate (tranne l'ultima),
        // di ognuna concateno il nome della dimensione con il valore scelto per il drilldown
        const oDrillDownFilters      = {}; // Es. " { CHANNEL_CODE: 'AP' }"
        for ( const { column, drillDownValue } of aoDimSelectedNotPivoted.slice( 0, -1 ) ) {
            if ( [ 'string', 'number' ].includes( typeof drillDownValue ) && ( drillDownValue !== '' ) ) {
                oDrillDownFilters[column] = drillDownValue;
                // const sDrillDownValueForDB = ( [ 'N', 'M' ].includes(filterDataType) ) ? drillDownValue : ( `'` + drillDownValue.toString().replace( /'/g, `''` ) + `'`)
                // sDrillDownFilters += ` AND ${ column }=${ sDrillDownValueForDB }`;
            }
        }
        
        return {
             nCubeCod:           this.props.nCubeCod
            ,sCubeDesc:          this.props.sCubeDesc
            ,sCubeDateTime:      this.props.cubeDateTime
            
            ,aoDimensions:       aoDimensionsSelected
            ,aoPivotDimensions
            ,aoMeasures:         aoMeasuresSelected
            ,anMeasureColumns
            ,bTabularMode
            ,bInvertPivot       
            ,bRowTotal          
            ,sSubTotals
            ,bShowAllValues     
            ,bColsLocked        
            
            ,PROCEDURE_NAME:     this.props.PROCEDURE_NAME   // verrà usato direttamente all'interno della store database
            ,pSortingClause                                  // verrà usato direttamente all'interno della store database
            ,oDrillDownFilters
            ,oFilters:           {
                aoDimensionsFilters: getAoFilters( aoDimensions ),
                aoMeasuresFilters:   getAoFilters( aoMeasures ),
            }
        };

    }

    exportExcel             = async ( bGrandTotal ) => {
        
        this.props.loading.set( true, 'CubeGrid - exportExcel' );
        
        const aoFiltersRowsOptions = CubeCommons.getAoFiltersRowsOptions({
             oLayout       : { oStructure: await this.gridGetParamsForLayout() }
            ,aoDimensions  : this.props.aoDimensions
            ,aoMeasures    : this.props.aoMeasures
        });
        
        const params        = this.getParamsForDB();
        
        const filename      = this.props.sCubeDesc + '_' + moment().format('YYYY-MM-DD_HHmmss') + '.xlsx';
        
        const
             oFirstRecord   = this.state.aoQueryResults?.[0]
            ,nTotRecords    = ( oFirstRecord && ( oFirstRecord['PROG_ID'] * -1 ) ) || 0
            ,nPagerCount    = Math.floor( nTotRecords / CONFIG_PAGE_ROWS ) + 1
        ;
        
        let blob;
        
        // se è una sola pagina eseguo l'excel nel frontend
        if ( nPagerCount === 1 ) {
            
            if ( this.cubeTableRef.current && this.cubeTableRef.current.createTableElement ) {
                
                // genero il file excel direttamente nel frontend
                const excelBuffer = this.cubeTableRef.current.createTableElement( 
                    true // parametro true per generare file excel
                    ,{
                        aoFiltersRowsOptions, sCubeDesc: this.props.sCubeDesc, sCubeDateTime: this.props.cubeDateTime, bGrandTotal
                    }
                );
                
                // Converto l'ArrayBuffer in un Blob specificando il tipo MIME per i file Excel
                blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                
            }
            
        // altrimenti eseguo l'excel nel backend
        } else {
            
            // chiamata al backend per far generare il file excel
            blob = await FetchService.asyncPost({
                 url:    '/cubes/get-cube-xlsx'
                ,params: { ...params, aoFiltersRowsOptions, bGrandTotal }
                ,type:   'blob'
                ,displayErrorHandler: this.props.toggleNotifica
            });
        }
        
        try {
            if ( blob ) { FileSaver.saveAs( blob, [ filename ] ); }
        } catch (error) {
            console.error(error);
            this.props.toggleNotifica( 'An error occurred on file creation.' );
        }
        
        this.props.loading.set( false, 'CubeGrid - exportExcel' );

    }

    handleClosePivotMenu    = () => {
        this.setState({
            anchorPivotMenu: null
        })
    }

    drillDown               = async ( { oRecord, sSelectedDimensionDrillDown } = {} ) => {

        let 
             aoDimensions                       = this.props.aoDimensions || []
            ,aoDimensionsNotPivoted             = aoDimensions.filter( oDimension => !oDimension.pivoted )
            ,aoDimensionsSelectedNotPivoted     = aoDimensionsNotPivoted.filter( oDimension => oDimension.selected )
            ,aoDimensionsNotSelectedNotPivoted  = aoDimensionsNotPivoted.filter( oDimension => !oDimension.selected )
            ,oActualDimension                   = aoDimensionsSelectedNotPivoted[ aoDimensionsSelectedNotPivoted.length - 1 ]
        ;
        
        // rimuovo subtotal da tutte le dimensioni per cui non è necessario
        const nLastSelectedIndex = aoDimensions.findLastIndex( oDimension => oDimension.selected && !oDimension.pivoted );
        aoDimensions.slice( nLastSelectedIndex ).forEach( oDim => {
            delete oDim.subtotal;
        });
        
        let oNextDimension  = aoDimensionsNotSelectedNotPivoted.find( oDimension => oDimension.searched ) || aoDimensionsNotSelectedNotPivoted[0];
    
        if ( oNextDimension ) {
            const aoDimsUpdated = await this.props.onDblClickonDimension( oNextDimension.column );
            if ( aoDimsUpdated ) {
                aoDimensions                    = aoDimsUpdated;
                aoDimensionsNotPivoted          = aoDimensions.filter( oDimension => !oDimension.pivoted );
                aoDimensionsSelectedNotPivoted  = aoDimensionsNotPivoted.filter( oDimension => oDimension.selected );
                oActualDimension                = aoDimensionsSelectedNotPivoted[ aoDimensionsSelectedNotPivoted.length - 1 ];
            }
            // console.log('oNextDimension',oNextDimension.column);
        }

        if ( sSelectedDimensionDrillDown ) { // passaggio da Tabular a DrillDown

            // per ognuna delle dimensioni
            // fino ad arrivare a quella cliccata (sSelectedDimensionDrillDown)
            // imposto il relativo drillDownValue al valore del record cliccato
            aoDimensionsSelectedNotPivoted.some( oDimension => {
                if ( oRecord[ oDimension.column ] != null ) { // il valore che arriva da DB potrebbe essere 0 o stringa vuota ''
                    oDimension.drillDownValue = oRecord[ oDimension.column ];
                    // salvo in drillDownValue il valore che arriva da DB solo per l'attuale colonna
                    // alle colonne precedenti non tocco il valore di drilldown
                }
                return oDimension.column === sSelectedDimensionDrillDown // esco dal ciclo quando arrivo alla dimensione cliccata
            });

            const nDimensionIndexSelected = aoDimensionsNotPivoted.findIndex( oDimension => oDimension.column === sSelectedDimensionDrillDown );

            const aoNextDimensions = aoDimensionsNotPivoted.slice( nDimensionIndexSelected + 1 );

            aoNextDimensions.forEach( oDimension => {
                delete oDimension.drillDownValue;
                delete oDimension.selected;
            });
            oNextDimension = aoNextDimensions[0];

            // resetto sSubTotals e bShowAllValues
            await Utils.setStateAsync({
                 bAutoReload:      true
                ,bTabularMode:     false
                ,sSubTotals:       ''
                ,bShowAllValues:   false
            }, this);

        } else if ( oRecord ) {  // già in DrillDown
            
            oActualDimension.drillDownValue = oRecord[ oActualDimension.column ];
            
        }

        if ( oNextDimension ) {
            oNextDimension.selected = true;
        }
        
        await this.props.refreshCubeBuilderState({ aoDimensions }, this.state.bAutoReload );

    }

    drillUp                 = async( sColumnClicked = '' ) => {

        // per il drillUp bisogna impostare selected a 'N' per tutte le dimensioni selezionate non pivottate successive all'elemento cliccato
        // in più bisogna svuotare il campo 'drillDownValue' per l'elemento cliccato e tutte le dimensioni selezionate non pivottate successive

        const
             aoDimensions            = this.props.aoDimensions || []
            ,aoDimSelectedNotPivoted = aoDimensions.filter( oDimension => oDimension.selected && !oDimension.pivoted )
            ,nIndexClickedDimension  = aoDimSelectedNotPivoted.findIndex( o => o.column === sColumnClicked )
         // ,aoPrevDimensions        = aoDimSelectedNotPivoted.slice( 0, nIndexClickedDimension )
            ,aoNextDimensions        = aoDimSelectedNotPivoted.slice( nIndexClickedDimension + 1 )
            ,[ oFirstNext, ...aoOthersNext ] = aoNextDimensions
        ;

        if ( this.state.bTabularMode ) {

            aoNextDimensions.forEach(  oDimension => {
                delete oDimension.selected;
            });

        } else {

            oFirstNext.drillDownValue = '';

            aoOthersNext.forEach( oDimension => {
                delete oDimension.selected;
                delete oDimension.drillDownValue;
            });

        }

        // serve per disabilitare sSubTotals e bShowAllValues quando c'è una sola dimensione E in drill down
        if ( aoDimensions.filter( oDimension => oDimension.selected && !oDimension.pivoted ).length === 1 ) {
            
            await Utils.setStateAsync({
                sSubTotals:     '',
                bShowAllValues: false
            }, this);
            
            aoDimensions.forEach( oDim => {
                delete oDim.subtotal;
            });
            
        }
        
        // rimuovo subtotal da tutte le dimensioni per cui non è necessario
        const nLastSelectedIndex = aoDimensions.findLastIndex( oDimension => oDimension.selected && !oDimension.pivoted );
        aoDimensions.slice( nLastSelectedIndex ).forEach( oDim => {
            delete oDim.subtotal;
        });

        await this.props.refreshCubeBuilderState({ aoDimensions }, this.state.bAutoReload );

    }

    handleChangePage        = async (event, value) => {
        await Utils.setStateAsync({ nCurrentPage: value - 1 }, this);
        await this.loadData(false);
    }

    // cotruisco un nuovo oggetto con le sole proprietà elencate (alcune specifiche solo per misure o dimensioni)
    getOnlySomeProps        = ( oObject, sType ) => {

        const { cod, column, selected, asCurrentFilters, exclude } = oObject;
        let oFinalObject = {
            cod,
            column,
            ...( ( selected === true )                                              && { selected         } ), // solo se è true
            ...( ( exclude  === true )                                              && { exclude          } ), // solo se è true (di default i filtri si presumono come "inclusi")
            ...( ( Array.isArray(asCurrentFilters) && asCurrentFilters.length > 0 ) && { asCurrentFilters } )  // solo se è un array non vuoto
        };
        if ( sType === 'dimension' ) {
            const { pivoted, drillDownValue, sortDirection, subtotal } = oObject;
            if ( pivoted === true )                                                                      { oFinalObject.pivoted        = pivoted;        } // solo se è true
            if ( [ 'string', 'number' ].includes( typeof drillDownValue ) && ( drillDownValue !== '' ) ) { oFinalObject.drillDownValue = drillDownValue; } // numero o stringa non vuota
            if ( ( typeof sortDirection === 'string' ) && sortDirection && ( sortDirection !== 'ASC')  ) { oFinalObject.sortDirection  = sortDirection;  } // stringa non vuota diversa da ASC
            if ( subtotal === 'Y'                                                                      ) { oFinalObject.subtotal       = subtotal;       } // stringa solo se Y
        }
        if ( sType === 'measure' ) {
            const { pSortingValue, sortDirection } = oObject;
            if ( ( typeof sortDirection === 'string' ) && sortDirection ) {
                oFinalObject.sortDirection  = sortDirection; // stringa non vuota
                // il pSortingValue viene salvato solo se c'è effettivamente un ordinamento (altrimenti è ordinato per dimensioni)
                if ( [ 'string', 'number' ].includes( typeof pSortingValue ) && ( pSortingValue !== '' ) ) {
                    oFinalObject.pSortingValue  = pSortingValue; // numero o stringa non vuota
                }
            }
        }

        return oFinalObject;

    };

    gridGetParamsForLayout  = async () => ({
         ...( this.state.bTabularMode   && { bTabularMode:   true                  }) // viene salvato solo se truthy
        ,...( this.state.bInvertPivot   && { bInvertPivot:   true                  }) // viene salvato solo se truthy
        ,...( this.state.bRowTotal      && { bRowTotal:      true                  }) // viene salvato solo se truthy
        ,...( this.state.sSubTotals     && { sSubTotals:     this.state.sSubTotals }) // viene salvato solo se truthy
        ,...( this.state.bShowAllValues && { bShowAllValues: true                  }) // viene salvato solo se truthy
        ,...( this.state.bColsLocked    && { bColsLocked:    true                  }) // viene salvato solo se truthy
        ,aoDimensions:     this.props.aoDimensions.map( oDimension => this.getOnlySomeProps( oDimension , 'dimension' ) )
        ,aoMeasures:       this.props.aoMeasures.map(   oMeasure   => this.getOnlySomeProps( oMeasure   , 'measure'   ) )
        ,anMeasureColumns: this.state.anMeasureColumns
    });

    gridTogglePivot         = async ( sDimensionPivotedColumnName ) => {

        const anMeasureColumns     = this.state.anMeasureColumns || [];
        let   bInvertPivot         = this.state.bInvertPivot;
        let   bRowTotal            = this.state.bRowTotal;
        let   bColsLocked          = this.state.bColsLocked;
        let   aoDimensions         = this.props.aoDimensions     || [];
        const aoMeasures           = this.props.aoMeasures       || [];
        const oDimensionPivoted    = aoDimensions.find( oDimension => oDimension.column === sDimensionPivotedColumnName );

        if ( !oDimensionPivoted ) {
            console.error('Dimension: "' + sDimensionPivotedColumnName + '" non riconosciuta');
            return;
        }

        if ( aoDimensions.length < 2 ) {
            return;
        }

        if ( oDimensionPivoted.pivoted === true ) { // se è già pivot, deve essere tolta (unpivot)

            // sulle MISURE:

            //      in tutti i casi rimuovo l'eventuale ordinamento specifico per un valore pivot (Es. pSortingValue: '2020' con GROSS DESC)
            for ( const oMeasure of aoMeasures ) {
                oMeasure.sortDirection = '';
                oMeasure.pSortingValue = '';
            }
            
            //      se si torna dal pivot e tra i tipi di misure selezionati ci sono dei delta, vengono tolte
            let anNewMeasureColumns = anMeasureColumns.filter( nMeasureColumn => nMeasureColumn !== 2 && nMeasureColumn !== 3 );

            //      se si torna dal pivot e tra i tipi di misure selezionati non c'è nè il valore nè la percentuale -> reset a solo valore
            if ( anNewMeasureColumns.length === 0 ) { anNewMeasureColumns = [0]; }

            await Utils.setStateAsync({ anMeasureColumns: anNewMeasureColumns }, this); // aggiorno le MeasureColumns

            // sulle DIMENSIONI:
            
            // recupero la posizione dell'attuale dimensione pivottata
            const oldIndex  = aoDimensions.findIndex( o => o.column === oDimensionPivoted.column );
            
            // escludendo le dimensioni pivottate, trovo la posizione della prima NON selezionata
            const newIndex  = aoDimensions.filter( o => !o.pivoted ).findIndex( o => !o.selected );
            
            // sposto la dimensione pivottata in quella posizione (quindi in fondo all'elenco delle selezionate)
            aoDimensions    = Utils.arrayMove(aoDimensions, oldIndex, ( newIndex > -1 ) ? newIndex : ( aoDimensions.length - 1 ) );

            delete oDimensionPivoted.pivoted;   // tolgo pivot
            delete oDimensionPivoted.selected;  // deseleziono la dimensione pivottata
            delete oDimensionPivoted.subtotal;  // deseleziono subtotal
            bInvertPivot    = false;            // resetto l'inversione delle intestazioni
            bRowTotal       = false;            // nascondo i totali di riga
            bColsLocked     = true;             // resetto il lock delle colonne

            if ( this.state.bTabularMode ) {
                this.deselectDimensionsOverLimit( aoDimensions );
            }

        } else  {
            // se sto abilitando come pivot la dimensione scelta (pivot)
            
            if ( aoDimensions.filter( o => o.pivoted ).length >= +localStorage.getItem( 'MAX_NUM_PIVOT') ) {
                return;
            }

            oDimensionPivoted.pivoted        = true; // abilito pivot
            delete oDimensionPivoted.drillDownValue; // rimuovo eventuale valore drilldown
            delete oDimensionPivoted.subtotal;       // deseleziono subtotal (non necessario quando è pivot)

            // in tutti i casi rimuovo i valori di DrillDown e deseleziono a partire dalla dimensione pivottata in poi
            const nPivotedIndex = aoDimensions.findIndex( oDimension => oDimension.pivoted );
            for ( let nDimensionPosition = 0; nDimensionPosition < aoDimensions.length; nDimensionPosition++ ){
                const oDimension = aoDimensions[nDimensionPosition];
                if ( nDimensionPosition > nPivotedIndex ) {
                    delete oDimension.drillDownValue;
                    delete oDimension.selected;      
                }
            }
            
            // sposto la dimensione da pivottare all'ultima posizione delle dimensioni
            const nActualIndexDimToPivot = aoDimensions.findIndex( o => o.column === oDimensionPivoted.column );
            aoDimensions = Utils.arrayMove( aoDimensions, nActualIndexDimToPivot, aoDimensions.length - 1 );
            
            // in tutti i casi rimuovo l'eventuale ordinamento per misura (Es. 'GROSS DESC')
            for ( const oMeasure of aoMeasures ) {
                oMeasure.sortDirection = '';
                oMeasure.pSortingValue = '';
            }
            
            // TODO da sistemare (2024-05-03)
            // nInvertSortPivot = ( ( this.props.aoDimensions.find(   oDimension => oDimension.pivoted ) || {} ).FLAG_PIVOT_SORTING_TYPE === 'D' ) ? -1 : 1;
            
            // reset dell'ordinamento
            oDimensionPivoted.sortDirection = ( oDimensionPivoted.FLAG_PIVOT_SORTING_TYPE === 'D' ) ? 'DESC' : 'ASC';
            
        }
        
        // se non rimane nessuna dimensione selezionata (per esempio nel caso di pivot sulla prima dimensione) si deve per forza selezionarne una
        if ( !aoDimensions.filter( o => !o.pivoted ).some( oDim => oDim.selected ) ) {
            const oFirstDimension    = ( aoDimensions.find( oDimension => !oDimension.pivoted ) || {} );
            oFirstDimension.selected = true;
        }

        // serve per disabilitare sSubTotals e bShowAllValues quando c'è una sola dimensione
        if ( aoDimensions.filter( o => o.selected && !o.pivoted ).length === 1 ) {
            await Utils.setStateAsync({
                sSubTotals:     '',
                bShowAllValues: false
            }, this);
        }
        
        this.handleClosePivotMenu();

        await Utils.setStateAsync({ bInvertPivot, bRowTotal, bColsLocked }, this);
        await this.props.refreshCubeBuilderState({ aoDimensions, aoMeasures }, this.state.bAutoReload );  // aggiorno le dimensioni e le misure

    }
    
    // measureType è un numero che corrisponde a: 
    // 0 = #  valore originale
    // 1 = %S valore in percentuale rispetto a 'S' subtotali, 'C' colonna, 'G' grandtotal (  11 = %C    12 = %G   )
    // 2 = Δ  solo pivot, differenza tra l'attuale valore e il prossimo valore pivot
    // 3 = Δ% solo pivot, differenza in percentuale tra l'attuale valore e il prossimo valore pivot
    toggleMeasureColumn     = async ( nMeasureType ) => {

        let anMeasureColumns     = this.state.anMeasureColumns || [0];
        let updateMeasureColumns = false;

        if ( !anMeasureColumns.includes(nMeasureType) ) {
            anMeasureColumns.push(nMeasureType);
            updateMeasureColumns = true;
        } else if ( anMeasureColumns.length > 1 ) {
            anMeasureColumns     = anMeasureColumns.filter( nMeasure => nMeasure !== nMeasureType );
            updateMeasureColumns = true;
        }

        anMeasureColumns.sort((a, b) => a - b ); //re-order array

        if ( updateMeasureColumns === true ) {
            await Utils.setStateAsync({ anMeasureColumns: [...anMeasureColumns] }, this); // aggiorno le MeasureColumns
        }

    }

    deselectDimensionsOverLimit = ( aoDimensions ) => {
        const excessDimensions = aoDimensions.filter( o => !o.pivoted ).slice( this.props.maxTabDim > 1 ? this.props.maxTabDim : 5 );
        excessDimensions.forEach( oDimension => {
            oDimension.selected = false;
        });
        return aoDimensions;
    }

    toggleTabularMode       = async () => {

        let oStateToChange;
        if ( !this.state.bTabularMode ) { // se veniamo dal drill down mode

            let aoDimensions = this.props.aoDimensions || [];
            aoDimensions     = this.deselectDimensionsOverLimit(aoDimensions);
            aoDimensions.forEach( oDimension => {
                oDimension.drillDownValue = ''; // svuoto tutti i valori di drillDown entrando in tabular
            });
            oStateToChange   = { aoDimensions };

        }

        await Utils.setStateAsync({
            bTabularMode: !this.state.bTabularMode
        }, this);

        await this.props.refreshCubeBuilderState(oStateToChange);

    }

    toggleInvertPivot       = async () => {
        await Utils.setStateAsync({
            bInvertPivot: !this.state.bInvertPivot
        }, this);
        await this.props.refreshCubeBuilderState();
    }
    
    toggleRowTotal          = async () => {
        await Utils.setStateAsync({
            bRowTotal: !this.state.bRowTotal
        }, this);
        await this.props.refreshCubeBuilderState();
    }
    
    toggleColsLocked        = async () => {
        await Utils.setStateAsync({
            bColsLocked: !this.state.bColsLocked
        }, this);
        await this.props.refreshCubeBuilderState();
    }

    toggleSubtotalsMode     = async () => {

        const sSubTotals   = ( !this.state.sSubTotals ) ? 'top' : '';
        const aoDimensions = this.props.aoDimensions;
        
        // in ogni caso disabilito tutti i subtotali
        aoDimensions.forEach( oDim => {
            delete oDim.subtotal;
        });
        
        // poi se invece sono stati abilitati, li abilito sulle sole dimensioni selezionate (tranne l'ultima e la pivot)
        if ( !!sSubTotals ) {
            aoDimensions
                .filter( oDim => oDim.selected && !oDim.pivoted )   // solo selezionate non pivottate
                .slice(0,-1)                                        // tranne l'ultima
                .forEach( ( oDim ) => { oDim.subtotal = 'Y'; } )    // abilito il subtotale
            ;
        }
        
        await Utils.setStateAsync({ sSubTotals }, this);
        await this.props.refreshCubeBuilderState({ aoDimensions }, this.state.bAutoReload );
        
    }

    toggleShowAllValues     = async (event) => {
        event.preventDefault();
        if ( this.state.sSubTotals ) {
            await Utils.setStateAsync({
                bShowAllValues: !this.state.bShowAllValues
            }, this);
        }
    }
    
    forceAutoReload         = async () => {
        if ( !this.state.bAutoReload ) {
            await Utils.setStateAsync({ bAutoReload: true }, this);
            await this.props.refreshCubeBuilderState({ aoDimensions: this.props.aoDimensions, aoMeasures: this.props.aoMeasures }, true );
        }
    }
    
    openSubMenu             = (sSubmenu) => (event) => { this.setState({ anchorEl: event.currentTarget, sSubmenu }); }
    closeSubMenu            = () => { this.setState({ anchorEl: null, sSubmenu: '' }); }
    
    toggleAutoReload        = async () => {
        await Utils.setStateAsync({ bAutoReload: !this.state.bAutoReload }, this);
        if ( this.state.bAutoReload ) {
            await this.props.refreshCubeBuilderState({}, true, true );
        }
    }
    
    showGridHeader          = () => {

        const
            bAutoReload                       = !!this.state.bAutoReload,
            bTabularMode                      = !!this.state.bTabularMode,
            bInvertPivot                      = !!this.state.bInvertPivot,
            sGraphSelected                    = this.state.sGraphSelected,
            bRowTotal                         = this.state.bRowTotal,
            bColsLocked                       = this.state.bColsLocked,
            aoDimensions                      = this.props.aoDimensions || [],
            aoMeasures                        = this.props.aoMeasures   || [],
            isOneDimPivoted                   = aoDimensions.some( oDim => oDim.pivoted ),
            aoDimNotPivoted                   = aoDimensions.filter( oDimension => !oDimension.pivoted ),
            aoDimSelectedNotPivoted           = aoDimensions.filter( oDimension => ( oDimension.selected ) && !oDimension.pivoted ),
            aoDimSelectedNotPivotedExceptLast = aoDimSelectedNotPivoted.slice( 0, -1 ),
            aoLastDimension                   = aoDimSelectedNotPivoted.slice(-1),
            oLastDimension                    = ( aoLastDimension && aoLastDimension[0] ) || {}
        ;
        // const firstDimensionNotSelected = aoDimensions.find( d => d.selected === 'N' );
        // aoDimSelectedNotPivotedExceptLast.length > 0 && console.table(aoDimSelectedNotPivotedExceptLast);

        const gridHeader = [];
    
    
        !sGraphSelected && !bTabularMode && gridHeader.push(
            <div key="tabular-mode-button" className="tabular-mode-button">
                <Tooltip title="Enter Tabular mode">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Button
                                    variant ={ !bTabularMode ? 'outlined' : 'contained'  }
                                    color   ="primary"
                                    onClick ={ this.toggleTabularMode }
                                ><GridOnIcon/></Button>
                            }
                            label=""
                        />
                    </FormGroup>
                </Tooltip>
            </div>
        );
    
    
        !sGraphSelected && bTabularMode && gridHeader.push(
            <div key="drill-mode-button" className="drill-mode-button">
                <Tooltip title="Enter Drill Down mode">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Button
                                    variant ={ bTabularMode ? 'outlined' : 'contained'  }
                                    color   ="primary"
                                    onClick ={ async () => {
                                        await Utils.setStateAsync({
                                             bTabularMode: false
                                            ,bAutoReload: true
                                        }, this);
                                        this.drillUp();
                                    } }
                                ><InputIcon/></Button>
                            }
                            label=""
                        />
                    </FormGroup>
                </Tooltip>
            </div>
        );
    
    
        !sGraphSelected && isOneDimPivoted && ( aoMeasures.filter( o => o.selected ).length > 1 ) && gridHeader.push(
            <div key="invert-pivot-button" className="invert-pivot-button">
                <Tooltip title="Invert Pivot Headers">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Button
                                    variant ={ !bInvertPivot ? 'outlined' : 'contained'  }
                                    color   ="primary"
                                    onClick ={ this.toggleInvertPivot }
                                ><FlipCameraAndroidIcon className="rotate90toLeft" /></Button>
                            }
                            label=""
                        />
                    </FormGroup>
                </Tooltip>
            </div>
        );
    
    
        !sGraphSelected && isOneDimPivoted && gridHeader.push(
            <div key="rowtotal-button" className="rowtotal-button">
                <Tooltip title="Row Totals">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Button
                                    variant ={ !bRowTotal ? 'outlined' : 'contained'  }
                                    color   ="primary"
                                    onClick ={ this.toggleRowTotal }
                                >∑</Button>
                            }
                            label=""
                        />
                    </FormGroup>
                </Tooltip>
            </div>
        );
        
        !sGraphSelected && isOneDimPivoted && gridHeader.push(
            <div key="colslocked-button" className="colslocked-button">
                <Tooltip title="Pin Columns">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Button
                                    variant ={ !bColsLocked ? 'outlined' : 'contained'  }
                                    color   ="primary"
                                    onClick ={ this.toggleColsLocked }
                                ><LockIcon /></Button>
                            }
                            label=""
                        />
                    </FormGroup>
                </Tooltip>
            </div>
        );
        
        if ( !bTabularMode ) {  // se è DrillDown mode
            gridHeader.push(
                <div key="home" className={ "breadcrumbs" + ( sGraphSelected ? ' hide ' : '' ) }>
                    <span className="home" onClick={() => this.drillUp() }><HomeIcon /></span>
                    {
                        aoDimSelectedNotPivotedExceptLast.map( ( oDimension, nIndex, arr ) => ( [
                            <ChevronRightIcon key={'i'+nIndex} />,
                            <Tooltip key={'t'+nIndex} title={ oDimension.description || '' }>
                                <span className={'bread'}
                                    onClick={ ( nIndex !== arr.length - 1 )
                                            ? () => this.drillUp(oDimension.column)
                                            : () => {}
                                    }
                                >{
                                    Utils.convertDataType( oDimension.drillDownValue, oDimension.filterDataType )
                                }</span>
                            </Tooltip>
                        ] ))
                    }
                </div>
            );
        } else { // se è Tabular mode
            
            if ( ['dimensions','measures','layouts'].includes(this.props.sTabSelected) ) {
                gridHeader.push(
                    <div key="autoreload-button" className="autoreload-button">
                        <Tooltip title={ ( !bAutoReload ? 'Enable' : 'Disable' ) + ' relaunch query on changes' }>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Button
                                            variant ={ bAutoReload ? 'contained' : 'outlined' }
                                            color   ="primary"
                                            onClick ={ this.toggleAutoReload }
                                        ><ReplayIcon/></Button>
                                    }
                                    label=""
                                />
                            </FormGroup>
                        </Tooltip>
                    </div>
                );
            }
            
            gridHeader.push(
                <div key="tabular-header" className={ "tabular-header" + ( sGraphSelected ? ' hide ' : '' ) } >
                    {
                        aoDimSelectedNotPivotedExceptLast.map( ( oDimension, nDimension ) => (
                            <div key={ 'dim' + nDimension } className="step" onClick={ () => this.drillUp(oDimension.column) }>
                                <span className="link">
                                    { ( nDimension === 0 ) ? <span className="home"><HomeIcon /></span> : '' }
                                    { oDimension.description }
                                </span>
                                <ChevronRightIcon />
                            </div>
                        ))
                    }
                    <div className="step" >
                        { ( aoDimSelectedNotPivotedExceptLast.length === 0 ? <span className="home"><HomeIcon /></span> : '' ) }
                        { oLastDimension.description || '' }
                    </div>
                    {
                        ( aoDimSelectedNotPivotedExceptLast.length < Math.min( aoDimNotPivoted.length - 1, this.props.maxTabDim - 1 ) ) &&
                        <div key="next" className="next" onClick={ () => this.drillDown() }>
                            <IconButton className="nextArrow" aria-label="Next" size="small">
                                <KeyboardTabIcon />
                            </IconButton>
                        </div>
                    }
                </div>
            );
            
        }
        
        return gridHeader;

    }
    
    resetAllFilters         = async () => {
        [ ...( this.props.aoDimensions || [] ) ,...( this.props.aoMeasures   || [] ) ]
            .forEach( oDimOrMea => { oDimOrMea.asCurrentFilters = []; }); // azzero i filtri
        await this.props.refreshCubeBuilderState({ aoDimensions: this.props.aoDimensions, aoMeasures: this.props.aoMeasures });
    }
    
    render() {

        const {
             aoQueryResults
            ,anMeasureColumns
            ,bTabularMode
            ,bInvertPivot
            ,bRowTotal
            ,sSubTotals
            ,bShowAllValues
            ,bColsLocked
            
            ,nCurrentPage
            ,bAutoReload
            ,bShowTable
            ,sGraphSelected
            
        } = this.state;

        const
             aoPivotDimensions    = this.props.aoDimensions.filter(               oDimension => oDimension.pivoted  )
            ,asPivotDimensions    = aoPivotDimensions.map(                        oDimension => oDimension.column   )
            ,isPivoted            = !!aoPivotDimensions?.length
            ,aoDimensionsNotPivoted         = this.props.aoDimensions.filter(     oDimension => !oDimension.pivoted )
            ,aoDimensionsSelectedNotPivoted = aoDimensionsNotPivoted.filter(      oDimension => oDimension.selected )
            ,asDimensionsSelectedNotPivoted = aoDimensionsSelectedNotPivoted.map( oDimension => oDimension.column   )
            ,aoMeasuresSelected   = this.props.aoMeasures.filter(                 oMeasure   => oMeasure.selected   )
            ,asMeasuresSelected   = aoMeasuresSelected.map(                       oMeasure   => oMeasure.column     )
            ,oFirstRecord         = aoQueryResults[0]
            ,nTotRecords          = ( oFirstRecord && oFirstRecord['PROG_ID'] * -1 ) || 0
            ,nPagerCount          = Math.floor( nTotRecords / CONFIG_PAGE_ROWS ) + 1
            ,nPagerCurrentPage    = nCurrentPage + 1
        ;

        // const dimensionsNotPivot = aoDimensions.filter((item) => item.pivoted === false);
        /* const columnsSortingDisabled = aoDimensionsSelected.slice(1).map( d =>
            ({ columnName: d.column, sortingEnabled: false })
        );*/
        /* const isDateType         = ( columnName, grandTotal, value ) => (
            ( grandTotal !== 'Y' ) && ( aoDimensions.find( d => d.column === columnName ) || {} ).filterDataType === 'D' ?
            Utils.getFormatDate(value) : value ) || ''
        ;*/

        const nTot          = ( ( bShowTable || sGraphSelected ) && Utils.formatPositiveIntNum( nTotRecords ) ) || 0;
        const bExcelEnabled = ( nTotRecords > 0 ) &&
        (
            ( // fino a 1 milione di record circa solo con lambda, senza pivot e senza subtotali
                   ( nTotRecords < 1048576 )
                && ( localStorage.getItem('LAMBDA_ENABLED') === 'Y' )
                && !isPivoted
                && !sSubTotals
            )
            || ( nTotRecords < 300000 )  // fino a 300.000 in tutti gli altri casi
        );
        
        
        const aoDimSubtotals = aoDimensionsSelectedNotPivoted.slice(0,-1);
        
        const nFirstIndexPositive = aoQueryResults.findIndex( o => o.PROG_ID > 0 );
        
        const fFindIndexMaxPROG_IDlimit = () => {
            const nOffset = CONFIG_PAGE_ROWS * ( nPagerCurrentPage - 1 );
            const nPROG_IDlimit = (
                ( sGraphSelected === 'bar' ) ? ( nOffset + GRAPH_LIMIT_BARS + 1 )
                                             : ( nOffset + GRAPH_LIMIT      + 1 )
            );
            const iProgId = aoQueryResults.findIndex( o => o.PROG_ID === nPROG_IDlimit );
            return ( iProgId < 0 ) ? aoQueryResults.length : iProgId;
        };
        
        return (
            <div className="cube-grid">

                <div className={ 'cube-top ' + ( isPivoted ? 'pivot-enabled' : '' ) }>{ this.showGridHeader() }</div>

                <Paper className="dimGrid" >
                    <MuiThemeProvider theme = { theme }>
                        {
                            
                            this.props.loading.get()
                                ? null :
                            
                            !( aoQueryResults && aoQueryResults[0] )
                                ? <table className="tableNoData"><tbody><tr><td>NO DATA</td></tr></tbody></table> :
                            
                            !bAutoReload
                                ? <table className="tableNoData"><tbody>
                                    <tr>
                                        <td>RELAUNCH QUERY ON CHANGES: <b>DISABLED</b><br/>
                                        <u onClick={ this.toggleAutoReload }>Click here to enable</u></td>
                                    </tr>
                                </tbody></table> :
                            
                            ( bShowTable && !sGraphSelected )
                                ? (
                                    <CubeTable
                                        aoQueryResults          ={[...aoQueryResults]}
                                        aoAllDimensions         ={this.props.aoDimensions}
                                        aoAllMeasures           ={this.props.aoMeasures}
                                        anMeasureColumns        ={anMeasureColumns}
                                        bTabularMode            ={bTabularMode}
                                        bInvertPivot            ={bInvertPivot}
                                        bRowTotal               ={bRowTotal}
                                        sSubTotals              ={sSubTotals}
                                        bShowAllValues          ={ bShowAllValues || ( nTotRecords === 1 ) }
                                        bColsLocked             ={bColsLocked}
                                        
                                        drillDown               ={this.drillDown}
                                        nTotRecords             ={nTotRecords}
                                        nCurrentPage            ={ nCurrentPage }
                                        refreshCubeBuilderState ={this.props.refreshCubeBuilderState}
                                        ref                     ={this.cubeTableRef}
                                    />
                                ) : 
                            
                            sGraphSelected
                                ?  (
                                    <Graph
                                        className       ="grafico"
                                        oGraphContent   ={{
                                             sChartType    : sGraphSelected
                                            ,aoRows        : (
                                                !isPivoted ? aoQueryResults.slice( nFirstIndexPositive ,GRAPH_LIMIT )
                                                           : aoQueryResults.slice( nFirstIndexPositive ,fFindIndexMaxPROG_IDlimit() )
                                            )
                                            ,oDrillDown    : this.getParamsForDB().oDrillDownFilters
                                            ,asElementX    : asDimensionsSelectedNotPivoted    // es. [ 'DATA_RIF' ]
                                            ,asElementY    : asMeasuresSelected                // es. [ 'BACINO' ,'BACINO_MM_FAST' ,'BACINO_MM_SLOW' ]
                                            ,asElementZ    : isPivoted ? asPivotDimensions  : null
                                            ,excludeTOT    : true
                                            ,oMappingField : [
                                                 ...this.props.aoDimensions
                                                ,...this.props.aoMeasures
                                            ].reduce( ( oResult, o ) => ({ ...oResult, [o.column]: o }), {})
                                        }}
                                    />
                                )
                            
                            : null
                            
                        }
                    </MuiThemeProvider>
                </Paper>

                <div className="cube-bottom">

                    <div className={ 'total-count' + ( sGraphSelected && ( nTotRecords < 101 ) ? ' hide ' : '' ) }>{
                        sGraphSelected
                            ? ( ( nTotRecords > ( sGraphSelected === 'bar' ? GRAPH_LIMIT_BARS : GRAPH_LIMIT ) ) ? <><span>(Limited displayed rows)</span></> : null )
                            : <><span>Total rows:</span><span>{nTot}</span></>
                    }</div>

                    <Paper elevation={0} className={ 'paper-column-ctrl value-types showInMenu ' + ( this.props.sTabSelected === 'measures' ? 'show' : 'hide' ) }>
                        
                        { /* <p><span>Measure&nbsp;</span><span>calculations</span></p> */ }

                        <ToggleButtonGroup
                                size      ="small"
                                value     ={anMeasureColumns}
                                aria-label="text alignment"
                            >

                            {/* <Tooltip title="Value"> lo rende inutilizzabile */}
                            <ToggleButton value={0} aria-label="centered" className="btn-label"
                                          title="Value"             onClick={() => this.toggleMeasureColumn(0)}>
                                <span>#</span>
                            </ToggleButton>
                            {/* </Tooltip> */ }
                            
                            {/* 1 = %S valore in percentuale rispetto a 'S' subtotali, 'C' colonna, 'G' grandtotal (  11 = %C    12 = %G   ) */}
                            <ToggleButton value={1} aria-label="centered" className="btn-label"
                                          title="Percentage"        onClick={() => this.toggleMeasureColumn(1)}>
                                <span>%</span>
                            </ToggleButton>
                            
                            {/* 1 = %S valore in percentuale rispetto a 'S' subtotali, 'C' colonna, 'G' grandtotal (  11 = %C    12 = %G   )
                            <ToggleButton value={11} aria-label="centered" className="btn-label"
                                          title="Percentage"        onClick={() => this.toggleMeasureColumn(11)}>
                                <span>%C</span>
                            </ToggleButton>
                            
                            {/* 1 = %S valore in percentuale rispetto a 'S' subtotali, 'C' colonna, 'G' grandtotal (  11 = %C    12 = %G   )
                            <ToggleButton value={12} aria-label="centered" className="btn-label"
                                          title="Percentage"        onClick={() => this.toggleMeasureColumn(12)}>
                                <span>%G</span>
                            </ToggleButton>
                            */}
                            {
                                !isPivoted ? null :
                                <ToggleButton value={2} aria-label="centered" className="btn-label"
                                              title="Delta"             onClick={() => this.toggleMeasureColumn(2)}>
                                    <span className={'delta-measure'}>
                                        <span>&#916;</span>
                                    </span>
                                </ToggleButton>
                            }

                            {
                                !isPivoted ? null :
                                <ToggleButton value={3} aria-label="centered" className="btn-label"
                                              title="Delta percentage"  onClick={() => this.toggleMeasureColumn(3)}>
                                    <span className={'delta-measure'}>
                                        <span>&#916;%</span>
                                    </span>
                                </ToggleButton>
                            }


                        </ToggleButtonGroup>

                    </Paper>

                    <div className="submenu-bottombar resetIcon">
                        
                        <MenuItem
                            className    = "menu-item"
                            onClick      = { this.openSubMenu('filters') }
                        >
                            <i className="fas fa-filter fa-lg" title="Filters"/>
                            <span className="internalLabel">&nbsp;Filters</span>
                        </MenuItem>
                        
                        <Menu
                            className   ="submenu-filters"
                            anchorEl    ={ this.state.anchorEl }
                            keepMounted
                            open        ={ this.state.sSubmenu === 'filters' }
                            onClose     ={ this.closeSubMenu }
                        >
                            <div>
                                <MenuItem className="" onClick={ (event) => { this.closeSubMenu(); this.resetAllFilters(); } }>
                                    <span>Reset Filters</span>
                                </MenuItem>
                                <MenuItem className="" onClick={ (event) => { this.closeSubMenu(); this.props.onClickFiltersBtn(event); } }>
                                    <span>Open Filters</span>
                                </MenuItem>
                            </div>
                        </Menu>
                        
                    </div>

                    <div
                        className={ 'submenu-bottombar excelIcon ' +  ( bExcelEnabled ? '' : 'disabled' ) + ( sGraphSelected ? ' hide ' : '' ) }
                        title={ !nTotRecords ? '' : ( bExcelEnabled ? 'Export Excel' : 'Max rows for export exceeded' ) }
                    >
                        
                        <MenuItem className="menu-item"
                            onClick      = { bExcelEnabled ? this.openSubMenu('export') : () => {} }
                        >
                            <i className="fas fa-file-excel" />
                            <span className="internalLabel">&nbsp;Export</span>
                        </MenuItem>
                        
                        <Menu
                            className   ="submenu-export"
                            anchorEl    ={ this.state.anchorEl }
                            keepMounted
                            open        ={ this.state.sSubmenu === 'export' }
                            onClose     ={ this.closeSubMenu }
                        >
                            <div>
                                <MenuItem className="" onClick={ () => { this.closeSubMenu(); this.exportExcel(false ); } }>
                                    <span>Export Excel without Grand Total</span>
                                </MenuItem>
                                <MenuItem className="" onClick={ () => { this.closeSubMenu(); this.exportExcel(true  ); } }>
                                    <span>Export Excel with Grand Total</span>
                                </MenuItem>
                            </div>
                        </Menu>
                    
                    </div>
                    
                    <div className={ 'submenu-bottombar subtotals-mode' + ( sSubTotals ? ' enabled ' : '' ) + (
                        !bTabularMode || ( ( aoDimensionsSelectedNotPivoted.length < 2 ) || ( nTotRecords < 2 ) ) ? ' hidden' : ''
                    ) + ( sGraphSelected ? ' hide ' : '' ) }>
                        
                        <MenuItem
                            className    = "menu-item"
                            onClick      = { this.openSubMenu('subtotals') }
                        >
                            <i className="fas fa-stream" title="Subtotals" />
                            <span className="internalLabel">&nbsp;Subtotals</span>
                        </MenuItem>
                        
                        <Menu
                            className   ="submenu-subtotals"
                            anchorEl    ={ this.state.anchorEl }
                            keepMounted
                            open        ={ this.state.sSubmenu === 'subtotals' }
                            onClose     ={ this.closeSubMenu }
                        >
                            <div>
                                
                                {
                                    !!sSubTotals && ( aoDimSubtotals.length > 1 ? aoDimSubtotals : [] ).map( o => {
                                        // condizione per impedire che si possano disabilitare tutti i subtotali singolarmente
                                        const conditionDisabled = (
                                            !!sSubTotals
                                            && ( o.subtotal === 'Y' )
                                            && ( aoDimSubtotals.filter( o => ( o.subtotal === 'Y' ) ).length < 2 )
                                        );
                                        return <MenuItem
                                            key     ={ o.column }
                                            onClick ={ async (event) => {
                                                event.preventDefault();
                                                if ( !conditionDisabled ) {
                                                    if ( o.subtotal === 'Y' ) {
                                                        delete o.subtotal;
                                                    } else {
                                                        o.subtotal = 'Y';
                                                    }
                                                    await this.props.refreshCubeBuilderState(
                                                        { aoDimensions: this.props.aoDimensions }
                                                        ,this.state.bAutoReload
                                                    );
                                                }
                                            } }
                                        >
                                            <div>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                color    ="primary"
                                                                checked  ={ ( o.subtotal === 'Y' ) }
                                                                disabled ={ conditionDisabled }
                                                                name     ="toggle-subtotal-dim"
                                                            />
                                                        }
                                                        label={ o.description }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </MenuItem>
                                    })
                                }
                                
                                { !!sSubTotals && ( aoDimSubtotals.length > 1 ) ? <hr/> : null }
                                
                                { ( nTotRecords > 1 ) && (
                                    <MenuItem className="" onClick={ this.toggleShowAllValues }>
                                        <div className={ 'show-all-values' + ( sGraphSelected ? ' hide ' : '' ) }>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color    ="primary"
                                                            checked  ={ !!bShowAllValues }
                                                            name     ="show-all-values"
                                                            title    ="Show all values"
                                                            disabled ={ !sSubTotals }
                                                        />
                                                    }
                                                    label="Show all values"
                                                />
                                            </FormGroup>
                                        </div>
                                    </MenuItem>
                                ) }
                                
                                <MenuItem className="" onClick={ (event) => { this.closeSubMenu(); this.toggleSubtotalsMode(); } }>
                                    <span>{ !!sSubTotals ? 'Disable' : 'Enable' } all Subtotals</span>
                                </MenuItem>
                                
                            </div>
                        </Menu>
                    
                    </div>

                    <div className={ 'pager' + ( ( !bAutoReload || sGraphSelected ) ? ' hide ' : '' ) }>
                        { bShowTable && ( nPagerCount > 1 ) && <Pagination
                            count={ nPagerCount } page={ nPagerCurrentPage } onChange={ this.handleChangePage }
                            siblingCount={0} boundaryCount={1}
                        /> }
                    </div>

                </div>

            </div>
        );
    }
}

export default CubeGrid;
