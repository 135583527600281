import React                from 'react';
import {SortableElement}    from 'react-sortable-hoc';
// import SearchIcon           from '@material-ui/icons/Search';
import EditIcon             from '@material-ui/icons/Edit';
import VisibilityIcon       from '@material-ui/icons/Visibility';
// import SaveIcon             from '@material-ui/icons/Save';
// import DeleteIcon           from '@material-ui/icons/Delete';
// import UndoIcon             from '@material-ui/icons/Undo';
// import StarOutlineOutlinedIcon from '@material-ui/icons/StarOutlineOutlined';
// import StarOutlinedIcon     from '@material-ui/icons/StarOutlined';
import Tooltip              from '@material-ui/core/Tooltip';
import { Checkbox }         from '@material-ui/core';
import Utils                from '../../../common/CommonUtilities';

class FilterCell extends React.Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    onClickFunction       = (event) => {
        event.stopPropagation();
        const {filter} = this.props;
        this.props.onClickFunction && this.props.onClickFunction(filter.column);
    }
    
    onRightClickFunction = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const {filter} = this.props;
        this.props.onRightClickFunc && this.props.onRightClickFunc(filter.column);
    }

    openFilter       = (event) => {
        event.stopPropagation();
        const {filter} = this.props;
        this.props.openFilter && this.props.openFilter(filter.column);
    }

    togglePivot       = (event) => {
        event.stopPropagation();
        const {filter} = this.props;
        this.props.togglePivot && this.props.togglePivot(filter.column);
    }
    
    render() {
        
        const
             {filter, filtersType, drawerOpen } = this.props
             // eslint-disable-next-line no-useless-computed-key
            ,{ column, description, icon, tooltip, ['default']: isDefault, selected, filterFlagPivot, pivoted, asCurrentFilters, searched } = filter
            ,isNewLayout           = ( column === -1 )
            ,descWords             = ( description || '' ).split(' ').filter(v=>v)
            ,descShort             = ( descWords.length > 1 ) ? ( descWords[0][0] + descWords[1][0] ) : ( descWords[0][0] + ( descWords[0][1] || '' ) )
            ,descToDisplay         = drawerOpen ? ( <>{ icon || null }{ description }</> ) : ( icon ? icon : ( isNewLayout ? '+' : descShort.toUpperCase() ) )
            ,classOuter            = ( drawerOpen ? '' : ' drawerClosed ' )
                                   + ( pivoted ? ' pivoted ' : ( selected ? ' selected ' : '' ) )
                                   + ( ( searched === false ) ? ' not-searched ' : '' )
            ,classInner            = asCurrentFilters && ( asCurrentFilters.length > 0 ) ? ' active ' : ''
        ;
        
        return (
            <div
                className   ={
                      'chip-wrapper '
                    + classOuter
                    + ( isNewLayout ? ' newLayout ' : '' )
                    + ( isDefault   ? ' isDefault ' : '' )
                    + ( filter.sharedToMe ? ' shared ToMe ' : filter.sharedByMe ? ' shared ByMe ' : '' )
                }
                onMouseDown   ={ ( ( filtersType === 'layout' ) && isNewLayout ) || ( filtersType === 'grafico' ) ? this.onClickFunction : undefined }
                onContextMenu ={ this.onRightClickFunction || undefined }
            >

                {
                    ( filtersType === 'layout' )
                    ?   isNewLayout
                            ? <div/>
                            : <div className={ 'chip-filter-handle edit-undo' } >
                                    {
                                        this.props.openFilter && (
                                            ( filter.sharedToMe || !selected )
                                            ? <Tooltip title="View"><VisibilityIcon className={ 'icon view' } onMouseDown={ this.openFilter } /></Tooltip>    
                                            : <Tooltip title="Edit"><EditIcon       className={ 'icon edit' } onMouseDown={ this.openFilter } /></Tooltip>
                                        )
                                    }
                            </div>
                    :
                        <div className={ 'chip-filter-handle ' + classInner } onMouseDown={ this.openFilter } >
                            <Tooltip title="Filter">
                                <i className="fas fa-filter fa-lg"/>
                            </Tooltip>
                        </div>
                }

                {
                    !isNewLayout 
                        ?   <Tooltip
                                title={ <>
                                    { ( tooltip || description ) + ( isDefault ? ' (default)' : '' ) }
                                    { ( filter.sharedToMe ? <><br/>shared by { filter.ownerDesc }</> : '' ) }
                                    { ( filter.data_mod   ? <><br/>last modified on { Utils.getFormatDateTime(filter.data_mod) }</> : '' ) }
                                </> }
                            >
                                    <span className="chip-label">{descToDisplay}</span>
                            </Tooltip>
                        :   <span className="chip-label">{descToDisplay}</span>
                }

                {
                    ( filterFlagPivot === 'Y' ) && <div
                        className   ={ 'chip-pivot ' + ( pivoted ? 'selected' : '' ) }
                        onMouseDown ={ this.togglePivot }
                    >
                        <Tooltip title={ pivoted ? 'Unpivot' : 'Pivot' } >
                            <span className="span-pivot">P</span>
                        </Tooltip>
                    </div>
                }

                {
                    ( ( filtersType === 'measure' ) || ( ( filtersType === 'layout' ) && !isNewLayout ) ) && 
                        <Tooltip title={ selected ? 'Deselect' : 'Select' } >
                            <Checkbox
                                className   ="chip-filter-handle eye"
                                checked     ={ !!selected }
                                onMouseDown ={ this.onClickFunction }
                                name        ={ 'chip-filter-handle-' + column }
                            />
                        </Tooltip>
                }

            </div>
        )
    }
}


export const SortableFilterCell = SortableElement(FilterCell);
export const SingleFilterCell   = FilterCell;
