import {useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import                               './CubeTable.css';
import Utils                    from '../../../common/CommonUtilities';
import ArrowUpwardIcon          from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon        from '@material-ui/icons/ArrowDownward';
import config                   from '../../../config';
import * as XLSX                from 'xlsx-js-style';
import moment                   from 'moment';

const bDebug = false;
const bDebugTimers = false;

// FIX per risolvere il problema noto dei decimali (vedere sito https://0.30000000000000004.com )
function formatNum( value, decimals = 2, sZero = 0, sBetweenZeroAndOne = '~', isToRound = true ) {
    // const preciseRound = ( num, dec ) => +( Math.round(num + ( 'e+' + dec ) )  + ( 'e-' + dec ) ); // preciseRound( 1.005, 2 ) === 1.01
    // return value.toFixed( decimals )
    return Utils.formatNumberWithOptions( 
        value,
        { nOuputDecimals: decimals, sZero, sBetweenZeroAndOne, isToRound }
    );
}
function formatNumSmallDec( value, decimals = 2, sZero = 0, sBetweenZeroAndOne = '~', isToRound = true ) {
    // const preciseRound = ( num, dec ) => +( Math.round(num + ( 'e+' + dec ) )  + ( 'e-' + dec ) ); // preciseRound( 1.005, 2 ) === 1.01
    // return value.toFixed( decimals )
    return Utils.formatNumberWithOptions(
        value,
        { nOuputDecimals: decimals, sZero, sBetweenZeroAndOne, isToRound, withSmallDecimals: true }
    );
}

function getPrevLevelHierarchy( sGerarchia, sSeparatore = '|||' ) {
    if ( sGerarchia && sSeparatore ) {
        const indice = sGerarchia.lastIndexOf( sSeparatore || '' );
        if ( indice !== -1 ) {
            return sGerarchia.substring( 0, indice );
        }
    }
    return sGerarchia;
}

let minValue = null;
let maxValue = null;

function processArrayForMinMax(arrayOfObjects) {
    for (const obj of arrayOfObjects) {
        for (const value of Object.values(obj)) {
            if (typeof value === 'number' && !isNaN(+value)) {
                if (minValue === null || value < minValue) {
                    minValue = value;
                }
                if (maxValue === null || value > maxValue) {
                    maxValue = value;
                }
            }
        }
    }
}

const getBackgroundColor = ( value ) => {
    
    let color;
    
    if (value < 0) {
        // Scala per valori negativi
        const percentage     = Math.max(0, Math.min(100, (value / minValue) * 100));
        const whiteComponent = Math.round(255 * (1 - percentage / 100));
        color = `rgb(${whiteComponent}, ${whiteComponent},255)`;
        
    } else if (value > 0) {
        // Scala per valori positivi
        const percentage     = Math.max(0, Math.min(100, (value / maxValue) * 100));
        const whiteComponent = Math.round(255 * (1 - percentage / 100));
        color = `rgb(255, ${whiteComponent}, ${whiteComponent})`;
        
    } else {
        // Valore zero: bianco
        color = 'rgb(255, 255, 255)';
    }
    
    return color;
    
}

//  const fConvertColorForXlsx = ( sColor ) => ({ rgb: sColor.replace( '#', '' ) });

// TODO ATTENZIONE!!! cercare deepMerge in CommonUtilities e usarla da lì!
/**
 * Esegue un merge profondo di più oggetti in un oggetto target.
 * (è già efficiente sia come utilizzo di memoria sia come tempi di esecuzione)
 *
 * @param {...Object} sources - Gli oggetti sorgente da unire nell'oggetto target.
 * @returns {Object} L'oggetto target modificato dopo il merge.
 *
 * @example
 * const obj1 = { a: { b: 2 } };
 * const obj2 = { a: { c: 3 }, d: 5 };
 * const risultato = deepMerge(obj1, obj2);
 * // risultato sarà { a: { b: 2, c: 3 }, d: 5 }
 */
function deepMerge(...sources) {
    
    // Inizializza l'oggetto target con il primo oggetto sorgente, o un oggetto vuoto
    let target = sources[0] || {};
    
    // Itera sugli oggetti sorgente a partire dal secondo (indice 1)
    for ( let source of sources ) {
        
        // Itera su tutte le proprietà dell'oggetto sorgente corrente
        for ( let key in source ) {
            
            // Verifica se la proprietà appartiene direttamente all'oggetto (non ereditata)
            if ( source.hasOwnProperty(key) ) {
                
                // Verifica se il valore della proprietà è un oggetto (e non null)
                if ( source[key] && ( typeof source[key] === 'object' ) ) {
                    // Se la proprietà è un oggetto, esegue una chiamata ricorsiva per unire gli oggetti annidati
                    if ( !target[key] || ( typeof target[key] !== 'object' ) ) {
                        target[key] = {};
                    }
                    deepMerge( target[key], source[key] );
                    
                } else {
                    // Se la proprietà non è un oggetto, assegna direttamente il valore
                    target[key] = source[key];
                }
                
            }
            
        }
        
    }
    
    // Restituisce l'oggetto target modificato
    return target;
    
}


const
    
    // __________ NUMBER FORMATS _________________________________
     sNumFormatGeneric      = '#,##'
    ,sNumFormatThou         = '#,###;[Color3]-#,###;[Color48]#,###'
    ,sNumFormatThouZero     = '#,##0;[Color3]-#,##0;[Color48]#,##0'
    
    // nel caso di valore esattamente uguale a zero non viene mostrato nulla
    ,sNumFormatThou0dec     = '#,##0;[Color3]-#,##0;[Color48]0'
    ,sNumFormatThou1dec     = '#,##0.0;[Color3]-#,##0.0;[Color48]0.0'
    ,sNumFormatThou2dec     = '#,##0.00;[Color3]-#,##0.00;[Color48]0.00'
    
    ,sNumFormatThouZero2dec = '#,##0.00;[Color3]-#,##0.00;[Color48]#,##0.00'
    ,sNumFormat2dec         = '0.00;[Color3]-0.00;[Color48]0.00'
    ,sNumFormatPerc2dec     = '0.00%;[Color3]-0.00%;[Color48]0.00%'
    ,sNumFormatPerc0dec     = '0%;[Color3]-0%;[Color48]0%'
    
    // __________ FONT ___________________________________________
    ,oBold                  = { bold: true }
    
    // __________ BORDERS ________________________________________
    ,oBorderThinBlack       = { style: 'thin', color: { rgb: '000000' } }
    ,oBorderThinWhite       = { style: 'thin', color: { rgb: 'FFFFFF' } }
    ,oAllBordersThinBlack   = {
        left:       oBorderThinBlack,
        right:      oBorderThinBlack,
        top:        oBorderThinBlack,
        bottom:     oBorderThinBlack
    }
    ,oAllBordersThinWhite   = {
        left:       oBorderThinWhite,
        right:      oBorderThinWhite,
        top:        oBorderThinWhite,
        bottom:     oBorderThinWhite
    }
    
    // __________ ALIGNMENTS _____________________________________
    ,oTop                   = { vertical:   'top' }
    ,oHAlignCenter          = {
        horizontal: 'center',
        vertical:   'top'
    }
    ,oHAlignCenterAndWrap   = {
        wrapText:   true,
        horizontal: 'center',
        vertical:   'top'
    }
    ,oRightIndent           = {
        horizontal: 'right',
        vertical:   'top',
        indent:     1
    }
    ,oLeftIndent            = {
        horizontal: 'left',
        vertical:   'top',
        indent:     1
    }
    ,backGrey               = {
        type:        'pattern',
        patternType: 'solid',
        bgColor:     { rgb: '191919' },
        fgColor:     { rgb: 'E1E1E1' }
    }
    
    // __________ COMBINED STYLES ________________________________
    ,oStyles                =  {
         bold                : {
            font:               oBold
        }
        ,noStyle             : {
            fill: {
                type:           'pattern',
                patternType:    'solid',
                fgColor:        { rgb: 'FFFFFF' },
                bgColor:        { rgb: 'FFFFFF' }
            }
        }
        ,debug               : {
            font:               oBold,
            alignment:          {
                vertical:       'top',
                horizontal:     'center',
            },
            border:             oAllBordersThinBlack
        }
        ,emptyCell           : {
            fill: {
                type:           'pattern',
                patternType:    'solid',
                fgColor:        { rgb: 'FFFFFF' }
            }
        }
        ,text                : {
            font:               { size: 12 },
            alignment:          oTop
        }
        ,textGrey            : {
            font:               { size: 12 },
            alignment:          oTop,
            fill: {
                type:        'pattern',
                patternType: 'solid',
                bgColor:     { rgb: 'DCDCDC' },
                fgColor:     { rgb: 'DCDCDC' }
            }
        }
        ,grandTotal          : {
            font:               { size: 12, bold: true },
            alignment:          oLeftIndent,
            fill:               backGrey
        }
        
        ,number              : {
            font:               { size: 12 },
            alignment:          oRightIndent,
            numberFormat:       sNumFormatGeneric
        }
        ,number0             : {
            font:               { size: 12 },
            alignment:          oRightIndent,
            numberFormat:       sNumFormatThou0dec
        }
        ,number1             : {
            font:               { size: 12 },
            alignment:          oRightIndent,
            numberFormat:       sNumFormatThou1dec
        }
        ,number2             : {
            font:               { size: 12 },
            alignment:          oRightIndent,
            numberFormat:       sNumFormatThou2dec
        }
        ,number0GrandTotal   : {
            font: {
                bold:           true,
                size:           12
            },
            alignment:          oRightIndent,
            numberFormat:       sNumFormatThou0dec,
            fill:               backGrey
        }
        ,number1GrandTotal   : {
            font: {
                bold:           true,
                size:           12
            },
            alignment:          oRightIndent,
            numberFormat:       sNumFormatThou1dec,
            fill:               backGrey
        }
        ,number2GrandTotal   : {
            font: {
                bold:           true,
                size:           12
            },
            alignment:          oRightIndent,
            numberFormat:       sNumFormatThou2dec,
            fill:               backGrey
        }
        ,textBold            : {
            font:               oBold,
            alignment:          oTop
        }
        ,numberBold          : {
            font:               oBold,
            alignment:          oTop,
            numberFormat:       sNumFormatThou2dec
        }
        ,centerBold          : {
            font:               oBold,
            alignment:          oHAlignCenterAndWrap
        }
        ,centerGrey          : {
            font:               { color: { rgb: 'A9A9A9' } },
            alignment:          oHAlignCenterAndWrap
        }
        ,centerBoldBorders   : {
            font:               oBold,
            alignment:          oHAlignCenterAndWrap,
            border:             oAllBordersThinBlack
        }
        ,left                : {
            alignment:          oLeftIndent
        }
        ,leftBold            : {
            font:               oBold,
            alignment:          oLeftIndent
        }
        ,right               : {
            alignment:          oRightIndent
        }
        ,rightBold           : {
            font:               oBold,
            alignment:          oRightIndent
        }
        ,numberLeft          : {
            alignment:          {
                horizontal:     'left',
                vertical:       'top'
            },
            numberFormat:       sNumFormatThou2dec
        }
        ,numberLeftBold      : {
            font:               oBold,
            alignment:          {
                horizontal:     'left',
                vertical:       'top'
            },
            numberFormat:       sNumFormatThou2dec
        }
        ,numberRight         : {
            alignment:          {
                horizontal:     'right',
                vertical:       'top'
            },
            numberFormat:       sNumFormatThou2dec
        }
        ,numberRightBold     : {
            font:               oBold,
            alignment:          {
                horizontal:     'right',
                vertical:       'top'
            },
            numberFormat:       sNumFormatThou2dec
        }
        
        /* !!! tenere sincronizzati con frontend react !!! */
        ,subtotal1  : { font: { size: 12, color: { rgb: '2D2D2D' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'E6E6E6' } } }
        ,subtotal2  : { font: { size: 12, color: { rgb: '313131' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'E7E7E7' } } }
        ,subtotal3  : { font: { size: 12, color: { rgb: '353535' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'E8E8E8' } } }
        ,subtotal4  : { font: { size: 12, color: { rgb: '393939' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'E9E9E9' } } }
        ,subtotal5  : { font: { size: 12, color: { rgb: '3D3D3D' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'EAEAEA' } } }
        ,subtotal6  : { font: { size: 12, color: { rgb: '414141' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'EBEBEB' } } }
        ,subtotal7  : { font: { size: 12, color: { rgb: '454545' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'ECECEC' } } }
        ,subtotal8  : { font: { size: 12, color: { rgb: '494949' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'EDEDED' } } }
        ,subtotal9  : { font: { size: 12, color: { rgb: '4D4D4D' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'EEEEEE' } } }
        ,subtotal10 : { font: { size: 12, color: { rgb: '515151' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'EFEFEF' } } }
        ,subtotal11 : { font: { size: 12, color: { rgb: '555555' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'F0F0F0' } } }
        ,subtotal12 : { font: { size: 12, color: { rgb: '595959' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'F1F1F1' } } }
        ,subtotal13 : { font: { size: 12, color: { rgb: '5D5D5D' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'F2F2F2' } } }
        ,subtotal14 : { font: { size: 12, color: { rgb: '616161' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'F3F3F3' } } }
        ,subtotal15 : { font: { size: 12, color: { rgb: '656565' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'F4F4F4' } } }
        ,subtotal16 : { font: { size: 12, color: { rgb: '696969' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'F5F5F5' } } }
        ,subtotal17 : { font: { size: 12, color: { rgb: '6D6D6D' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'F6F6F6' } } }
        ,subtotal18 : { font: { size: 12, color: { rgb: '717171' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'F7F7F7' } } }
        ,subtotal19 : { font: { size: 12, color: { rgb: '757575' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'F8F8F8' } } }
        ,subtotal20 : { font: { size: 12, color: { rgb: '797979' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'F9F9F9' } } }
        ,subtotal21 : { font: { size: 12, color: { rgb: '7D7D7D' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'FAFAFA' } } }
        ,subtotal22 : { font: { size: 12, color: { rgb: '818181' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'FBFBFB' } } }
        ,subtotal23 : { font: { size: 12, color: { rgb: '858585' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'FCFCFC' } } }
        ,subtotal24 : { font: { size: 12, color: { rgb: '898989' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'FDFDFD' } } }
        ,subtotal25 : { font: { size: 12, color: { rgb: '8D8D8D' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'FEFEFE' } } }
        ,subtotal26 : { font: { size: 12, color: { rgb: '919191' } }, fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'FFFFFF' } } }

        ,verde06: {                                                   fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: '8BB191' } } }
        ,verde05: {                                                   fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'A0C0A5' } } }
        ,verde04: {                                                   fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'B6CEBA' } } }
        ,verde03: {                                                   fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'CBDCCE' } } }
        ,verde02: {                                                   fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'E0EBE2' } } }
        ,verde01: {                                                   fill: { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'F0F4F0' } } }
    }
;

/* per debug
function usePrevPropValue(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
*/

/*

                                  | SECONDS                        
                                  | 2023                           
                                  | Feb                 | Jul      
    STATUS          TYPE          |         #        %  |        # 
    ---------------------------------------------------------------
    <GRAND TOTAL>                 |    62.780   100,00  |   62.780 
    Locked          Digital       |         0     0,00  |        0 
    Locked          Standard      |    16.230     0,00  |        0 
    Potential       Digital       |         0     0,00  |        0 
    Potential       Standard      |         0     0,00  |        0 

*/

export const CubeTable = forwardRef((props, ref) => {
    
    const nTimerCubeTable  = performance.now();
    
    const sRowTotalLabel   = 'ROW TOTAL';
    const sGrandTotalLabel = 'GRAND TOTAL';
    
    const {

         aoQueryResults:            aoQueryResultsFull
        /* aoQueryResultsFull
            [
                {
                    "PROG_ID"           : -1,
                    "ADVERTISER_STATUS" : null,
                    "REVENUE_YEAR"      : 2022,
                    "REVENUE_MONTH"     : null,
                    "SPOT_LENGTH"       : 8410649,
                    "GROSS"             : 32798733
                },
                {
                    "PROG_ID"           : 1,
                    "ADVERTISER_STATUS" : "POTENZIAL",
                    "REVENUE_YEAR"      : 2023,
                    "REVENUE_MONTH"     : 2,
                    "SPOT_LENGTH"       : 12365,
                    "GROSS"             : 79350

                }
            ]
        */
        ,aoAllDimensions
        /* aoAllDimensions
            [{
                 "cod"                      : 35
                ,"column"                   : "REVENUE_YEAR"
                ,"description"              : "Revenue Year"
                ,"tooltip"                  : "Revenue Year"
                ,"columnWidth"              : 250
                ,"flagAlignment"            : "R"
                ,"filterType"               : "T"
                ,"filterDataType"           : "N"
                ,"filterFlagPivot"          : "Y"
                ,"FLAG_PIVOT_SORTING_TYPE"  : "D"
                ,"selected"                 : false
                ,"pivoted"                  : true
                ,"exclude"                  : false
                ,"asCurrentFilters"         : [ "2022", "2023" ]
                ,"sortDirection"            : "DESC"
                ,"drillDownValue"           : ""
                ,"subtotal"                 : ""
            }]
        */
        ,aoAllMeasures
        /* aoAllMeasures
            [{
                 "cod"                      : 8
                ,"column"                   : "SPOT_LENGTH"
                ,"description"              : "Seconds"
                ,"tooltip"                  : "Seconds"
                ,"columnWidth"              : 160
                ,"decimals"                 : 0
                ,"filterType"               : "R"
                ,"filterDataType"           : "N"
                ,"summable"                 : "Y"
                ,"selected"                 : true
                ,"exclude"                  : false
                ,"asCurrentFilters"         : []
                ,"sortDirection"            : ""
                ,"pSortingValue"            : ""
            }]
        */
        ,anMeasureColumns           // [ 0, 1 ]
        
        ,bTabularMode
        ,bInvertPivot
        ,bRowTotal
        ,sSubTotals                 = 'top' // 'top' (default), 'bottom' oppure '' (no subtotali)
        ,bShowAllValues
        
        ,bColsLocked
        ,drillDown
        
        ,refreshCubeBuilderState
        ,nCurrentPage
        
    } = props;
    
    // aoQueryResultsFull = aoQueryResultsFull.slice( 0 ,85 ); // solo per debug
    
    // per debug
    // const prevProps = usePrevPropValue({...props});
    // Utils.logDifferences( '--- CubeTable (cambio di props) ---', prevProps, props );
    
    const
         [ nRowSelected, setnRowSelected ] = useState(-1)
        ,[ tableElement, setTableElement ] = useState(<table><tbody><tr><th></th></tr></tbody></table>)
        ,[ anColWidths,  set_anColWidths ] = useState([])
        ,colsRefs       = useRef([])
        ,isHeatEnabled  = false
        ,noValue        = '-'
        ,zeroOrNeg      = ( value ) => ( value === 0 ) ? ' zero' : ( ( value < 0 ) ? ' negative' : '' )
        ,nRecordsBefore = ( config.PAGE_ROWS || 100 ) * nCurrentPage
    ;
    
    // console.info(JSON.stringify(anColWidths||[]));
    
    /*
     * Per ogni stringa (campo) in asKeys recupero il suo valore nel record e lo concateno
     *
     * @param   {string[]} asKeys  : es. [ 'YEAR', 'MONTH' ]
     * @param   {Object}   oRecord : es. { PROG_ID: -2, STATUS: null, TYPE: null, MONTH: 7, YEAR: 2022, LENGTH: 23745 }
     * @returns {string}           : es. '2022|||7'
     */
    const makeKeyComb           = ( asKeys, oRecord ) => {
        let result = '';
        for ( let nKey = 0; nKey < asKeys.length; nKey++ ) {
            const sKey = asKeys[nKey];
            
            if ( ( oRecord[sKey] === 0 ) || oRecord[sKey] ) { // filtro zero o truthy
                if ( result !== '' ) { // contateno in una stringa con ||| come separatore dei valori
                    result += '|||';
                }
                result += oRecord[sKey];
            }
            
        }
        return result; // es. '2022|||7'
    };
    
    bDebugTimers && console.info('nTimerCubeTable', ( ( performance.now() - nTimerCubeTable ) / 1000 ).toFixed(3) + 's' );
    
    /**
     * Funzione createTableElement
     *
     * Questa funzione genera la struttura della tabella per il rendering e l'export Excel.
     * La tabella è suddivisa in quattro aree principali:
     *
     * 1. ASX (in Alto a Sinistra): Contiene le intestazioni delle colonne per le dimensioni.
     *    Si trova all'inizio della funzione, nel ciclo che genera le intestazioni delle dimensioni.
     *
     * 2. ADX (in Alto a Destra): Contiene le intestazioni delle colonne per le misure e i calcoli.
     *    Si trova nel ciclo che genera le intestazioni delle misure e dei calcoli.
     *
     * 3. CSX (in Centro a Sinistra): Contiene le celle con i valori delle dimensioni per ogni riga.
     *    Si trova all'interno del ciclo principale per la generazione delle righe di dati, in creaIntestazioniRiga.
     *
     * 4. CDX (in Centro a Destra): Contiene le celle con i valori delle misure e dei calcoli per ogni riga.
     *    Si trova nel ciclo che genera le celle dei valori per ciascuna riga.
     *
     *   ASX                ADX                                    
     * +------------------+---------+---------+---------+---------+
     * | Advertiser Status| Seconds |         | Gross   |         |
     * |                  |    #    |    %    |    #    |    %    |
     * +------------------+---------+---------+---------+---------+
     *   CSX                CDX                                    
     * +------------------+---------+---------+---------+---------+
     * | GRAND TOTAL      |94476597 | 100.00% |491963150| 100.00% |
     * | LOCKED           | 1578060 |   1.67% | 7347021 |   1.49% |
     * | OK               |92886147 |  98.32% |484518331|  98.49% |
     * | POTENTIAL        |   12390 |   0.01% |   97797 |   0.02% |
     * +------------------+---------+---------+---------+---------+
     *
     * Ciascuna di queste aree è identificata da un commento specifico nel codice sottostante.
     */
    const createTableElement    = ( isForExcel = false, { aoFiltersRowsOptions, sCubeDesc, sCubeDateTime, bGrandTotal = true } = {} ) => {
        
        minValue = null;
        maxValue = null;
        
        const nTimerCreateTableElement = performance.now();
        
        if ( isHeatEnabled ) {
            processArrayForMinMax(aoQueryResultsFull);
        }
        
        // ----- EXCEL - FOGLIO CUBO - INIZIO ----- //
        bDebug && console.info( 'excel file creation started' );
        
        const wb = XLSX.utils.book_new();
        const wsCube = XLSX.utils.aoa_to_sheet([]);
        const wsFilters = XLSX.utils.aoa_to_sheet([]);
        XLSX.utils.book_append_sheet(wb, wsCube, sCubeDesc);
        XLSX.utils.book_append_sheet(wb, wsFilters, 'Filters');
        
        let nExcelRow = 1;
        let nExcelCol = 1;
        
        let nLastRowUsed = 0;
        let nLastColUsed = 0;
        
        /**
         * Riempie una cella del foglio di lavoro con un valore e applica gli stili.
         * @param {Object}   oParams                - Parametri della funzione
         * @param {Object}   oParams.ws             - Foglio di lavoro (default: wsCube)
         * @param {number}   oParams.nRow           - Numero di riga (1 based)
         * @param {number}   oParams.nCol           - Numero di colonna (1 based)
         * @param {*}        oParams.value          - Valore da inserire nella cella
         * @param {string}   oParams.filterDataType - Tipo di dati ('C' per stringa, 'N' per numero)
         * @param {Object[]} oParams.aoStyles       - Stili aggiuntivi da applicare
         * @param {boolean}  oParams.bForceString   - Forza il valore come stringa
         * @example
         * fillSheetCell( {
         *     ws             : foglioDiLavoro,
         *     nRow           : 1,
         *     nCol           : 1,
         *     value          : 'Esempio',
         *     filterDataType : 'C',
         *     style          : { font: { bold: true } }
         * } );
         * // Risultato: Cella A1 contiene 'Esempio' in grassetto
         */
        function fillSheetCell( { ws = wsCube, nRow, nCol, value, filterDataType = 'C', aoStyles, bForceString } ) {
            
            // console.info( JSON.stringify({ nRow, nCol, value }));
            const sCellAddress = XLSX.utils.encode_cell( { r: nRow - 1, c: nCol - 1 } );
            const oCell        = { t: 's', v: '' };
            
            // per le celle numeriche
            if ( !bForceString && ( filterDataType === 'N' ) && !Number.isNaN( +value ) ) {
                oCell.t = 'n';
                oCell.v = +value || 0;
            
            // per tutte le altre
            } else {
                oCell.t = 's';
                oCell.v = ( value || '' ) + '';
            }
            
            // Applica gli stili
            if ( aoStyles?.length ) {
                
                oCell.s       = {};
                
                let oStyleObj = deepMerge( ...structuredClone(aoStyles) );
                
                /*
                let oStyleObj = aoStyles[0] ? structuredClone( aoStyles[0] ) : {};
                
                if ( aoStyles?.length > 1 ) {
                    for ( let i = 1; i < aoStyles.length; i++ ) {
                        if ( aoStyles[i] ) {
                            oStyleObj = structuredClone( aoStyles[i] );
                            oStyleObj = Utils.staticDeepMerge( oStyleObj, structuredClone(oStyleObj) );
                            i++;
                        }
                    }
                }
                */
                
                for ( const sStyleProp of [ 'font', 'fill', 'border', 'alignment' ] ) {
                    if ( oStyleObj[ sStyleProp ] ) {
                        oCell.s[ sStyleProp ] = oStyleObj[ sStyleProp ];
                        // VERSIONE PER QUANDO SI APPLICANO STILI DA UNA CELLA IN MOMENTI DIVERSI SUCCESSIVI
                        // deepMerge( oCell?.s?.[ sStyleProp ] || {}, oStyleObj[ sStyleProp ] );
                    }
                }
                
                // Gestione specifica per numberFormat
                if ( oStyleObj.numberFormat ) {
                    oCell.z = oStyleObj.numberFormat;
                }
                    
            }
            
            /* Converti i colori in formato RGB
            if ( oCell.s ) {
                if ( oCell.s.font?.color   ) {
                    oCell.s.font.color = fConvertToRGB( oCell.s.font.color );
                }
                if ( oCell.s.fill?.fgColor ) {
                    oCell.s.fill.fgColor = fConvertToRGB( oCell.s.fill.fgColor );
                }
                if ( oCell.s.border        ) {
                    for ( const sSide in oCell.s.border ) {
                        if ( oCell.s.border[ sSide ]?.color ) {
                            oCell.s.border[ sSide ].color = fConvertToRGB( oCell.s.border[ sSide ].color );
                        }
                    }
                }
            }
            */
            
            // Inserisce la cella nel foglio di lavoro
            ws[ sCellAddress ] = oCell;
            
            // Gestione della larghezza delle colonne
            ws[ '!cols' ]             = ws[ '!cols' ]             || [];
            ws[ '!cols' ][ nCol - 1 ] = ws[ '!cols' ][ nCol - 1 ] || { wch: 10 }; // Larghezza predefinita
            
            nLastRowUsed = Math.max(nLastRowUsed, nRow);
            nLastColUsed = Math.max(nLastColUsed, nCol);
            
        }
        
        function applicaColoreTestoComeSfondo( oStileCOriginaleCella ) {
            const oStileCella = structuredClone(oStileCOriginaleCella) || {};
            if ( !oStileCella?.font ) { oStileCella.font = {}; }
            oStileCella.font.color = oStileCella?.fill?.fgColor || { rgb: 'FFFFFF' }; // Imposto il colore del testo uguale al colore di sfondo
            return oStileCella;
        }
        
        let nCol = 1;
        let nRow = 1;
        
        // ----- EXCEL - FOGLIO CUBO - FINE ----- //
        
        // strutture di appoggio per evitare di ciclare più volte su aoAllDimensions e aoAllMeasures
        const
             oDimensions            = {}
            ,aoDimensionsSelected   = []
            ,asDimSelectedColNames  = []
            
            ,aoPivotDimensions      = []
            ,asPivotColNames        = []
            ,asPivotColSorts        = []
            
            ,oMeasures              = {}
            ,aoMeasures             = []
            ,asMeasuresColNames     = []
        ;
        
        const nTimerDichiarazioni   = performance.now();
        
        // TODO eliminare gli array di oggetti, usare solo array di stringhe e, separatamente, oggetti per accedere alle proprietà
        for ( const oDim of aoAllDimensions ) {
            
            if ( oDim.selected && !oDim.pivoted ) {
                aoDimensionsSelected.push(  oDim );
                asDimSelectedColNames.push( oDim.column );
                oDimensions[                oDim.column ] = oDim;
            }
            
            if ( oDim.pivoted ) {
                aoPivotDimensions.push( oDim );
                asPivotColNames.push(   oDim.column );
                asPivotColSorts.push(   oDim.sortDirection );
                oDimensions[            oDim.column ] = oDim;
            }
            
        }
        
        for ( const oMea of aoAllMeasures ) {
            if ( oMea.selected ) {
                aoMeasures.push(         oMea        );
                asMeasuresColNames.push( oMea.column );
                oMeasures[               oMea.column ] =  oMea;
            }
        }
        
        const
             nPivotDimensions       = asPivotColNames.length
            ,isPivotEnabled         = !!nPivotDimensions
            
                                    // se è tabular, allora tutte le dimensioni, altrimenti solo l'ultima
            ,aoDimensions           = bTabularMode ? aoDimensionsSelected : [ aoDimensionsSelected[ aoDimensionsSelected.length - 1 ] ]
            ,asCampiRecordPerIntest = [ 'PROG_ID', ...asDimSelectedColNames ]
            
            ,asMeasureCalcs         = [ '#'         ,'%'       ,'Δ'        ,'Δ%'           ]
            ,asMeasureCalcsClasses  = [ 'COLnumber' ,'COLperc' ,'COLdelta' ,'COLdeltaperc' ]
            ,aoMeasureCalcs         = anMeasureColumns.map( n => ({
                 column:        asMeasureCalcs[n]               // #
                ,description:   asMeasureCalcs[n]               // '[' + asMeasureCalcs[n] + ']'  // [#]
                ,cssClass:      asMeasureCalcsClasses[n]        // COLnumber
            }) )
            ,oMeasureCalcs          = {
                 '#'  : { column: '#'  ,description: '#'  ,cssClass: 'COLnumber'    }
                ,'%'  : { column: '%'  ,description: '%'  ,cssClass: 'COLperc'      }
                ,'%C' : { column: '%C' ,description: '%C' ,cssClass: 'COLperc'      }
                ,'%G' : { column: '%G' ,description: '%G' ,cssClass: 'COLperc'      }
                ,'Δ'  : { column: 'Δ'  ,description: 'Δ'  ,cssClass: 'COLdelta'     }
                ,'Δ%' : { column: 'Δ%' ,description: 'Δ%' ,cssClass: 'COLdeltaperc' }
            }
            
            ,oSubtotals             = {}
            ,aoRawPivotRecords      = []
        ;
        asMeasureCalcs[        11 ] = '%C'     ;
        asMeasureCalcsClasses[ 11 ] = 'COLperc';
        asMeasureCalcs[        12 ] = '%G'     ;
        asMeasureCalcsClasses[ 12 ] = 'COLperc';
        // console.info(Utils.logAAO([aoQueryResultsFull]));
        // console.info(aoQueryResultsFull[aoQueryResultsFull.length - 1 ]);
        let oGrandTotal,  nIndexPositiveRecords  = 0;
        
        // comincio a scorrere i record arrivati da DB (aoQueryResultsFull) fino al primo PROG_ID positivo
        // per creare le seguenti strutture dati:
        //      oGrandTotal    (il primo record)
        //      oSubtotals     (PROG_ID negativi)
        //        ⤷ aoRawPivotRecords (copio una parte dei PROG_ID negativi)
        for ( let nRecord = 0; nRecord < aoQueryResultsFull.length; nRecord++ ) {
            const oRecord = aoQueryResultsFull[ nRecord ];
            
            // es. di oRecord:
            //  {
            //       "PROG_ID"           : 4
            //          (dimensioni)
            //      ,"STATUS"            : "POTENTIAL"
            //      ,"TYPE"              : "STANDARD"
            //          (dimensioni pivot)
            //      ,"YEAR"              : 2022
            //      ,"MONTH"             : 7
            //          (misure)
            //      ,"SPOT_LENGTH"       : 35443
            //  }
            
            if ( nRecord === 0 ) {
                
                oGrandTotal = oRecord; // mi salvo a parte il primo record (GRAND TOTAL)
                
            } else {
                
                if ( oRecord?.PROG_ID < 0 ) {
                    
                    // estraggo e copio solo l'ultimo livello dei subtotali in aoRawPivotRecords
                    if ( oRecord?.PROG_ID === -nPivotDimensions ) {
                        aoRawPivotRecords.push( oRecord )
                    }
                    
                    // --- SEZIONE SUBTOTALI (oSubtotals) ---
                    
                    // costruisco la struttura che contiene i valori dei subtotali
                    const asColumnKeys          = [
                         ...asPivotColNames
                        ,...asDimSelectedColNames
                    ];
                    // console.info('asColumnKeys',JSON.stringify(asColumnKeys || []));
                    
                    // es. asColumnKeys: [ 'YEAR', 'MONTH', 'STATUS', 'TYPE' ]
                    // es. oRecord:      { PROG_ID: -2, STATUS: null, TYPE: null, MONTH: 7, YEAR: 2022, LENGTH: 23745 }
                    // es. sKeyCombo:    '2022|||7'
                    const sKeyCombo             = makeKeyComb( asColumnKeys , oRecord );
                    // a quella combinazione di valori delle dimensioni interessate associo l'intero record (che contiene le misure es. LENGTH)
                    // oSubtotals[ '2022|||7' ] = { PROG_ID: -2, STATUS: null, TYPE: null, MONTH: 7, YEAR: 2022, LENGTH: 23745 }
                    oSubtotals[ sKeyCombo ]     = oRecord;
                    
                    if ( bRowTotal ) {
                        
                        // combinazione dei valori delle colonne che fanno da intestazioni di riga
                        // 'POTENTIAL|||BASKET'     = makeKeyComb( [ 'STATUS', 'TYPE' ] { PROG_ID: -2, STATUS: null, TYPE: null, MONTH: 7, YEAR: 2022, LENGTH: 23745 } );
                        let sKeyComboForRowTot      = makeKeyComb( asDimSelectedColNames , oRecord );
                        
                        if ( !oSubtotals[ sKeyComboForRowTot ] ) {
                              oSubtotals[ sKeyComboForRowTot ] = {};
                        }
                        
                        //   a oSubtotals[ sKeyComboForRowTot   ][ sMeasuresColName ] viene assegnato il totale di riga
                        // es. oSubtotals[ 'POTENTIAL|||BASKET' ][ 'LENGTH'         ] = 65151
                        
                        // per ogni misura
                        for ( const sMeasuresColName of asMeasuresColNames ) {
                            // in oSubTotals assegno alla combinazione di chiavi il valore della misura (sommandolo al precedente)
                            oSubtotals[ sKeyComboForRowTot ][ sMeasuresColName ] = (
                                // TODO qua viene sommato il totale di riga
                                ( oSubtotals[ sKeyComboForRowTot ][ sMeasuresColName ] || 0 ) + oRecord[ sMeasuresColName ]
                            );
                        }
                        
                    }
                    
                    /* oSubtotals
                        {
                            "2022"             : {
                                 "PROG_ID"           : -1
                                ,"ADVERTISER_STATUS" : null
                                ,"CONTRACT_TYPE"     : null
                                ,"REVENUE_YEAR"      : 2022
                                ,"REVENUE_MONTH"     : null
                                ,"SPOT_LENGTH"       : 44660
                            },
                            "2023"             : {
                                 "PROG_ID"           : -1
                                ,"ADVERTISER_STATUS" : null
                                ,"CONTRACT_TYPE"     : null
                                ,"REVENUE_YEAR"      : 2023
                                ,"REVENUE_MONTH"     : null
                                ,"SPOT_LENGTH"       : 18120
                            },
                            "2023|||2"         : {
                                 "PROG_ID"           : -2
                                ,"ADVERTISER_STATUS" : null
                                ,"CONTRACT_TYPE"     : null
                                ,"REVENUE_YEAR"      : 2023
                                ,"REVENUE_MONTH"     : 2
                                ,"SPOT_LENGTH"       : 16230
                            },
                            "2023|||7"         : {
                                 "PROG_ID"           : -2
                                ,"ADVERTISER_STATUS" : null
                                ,"CONTRACT_TYPE"     : null
                                ,"REVENUE_YEAR"      : 2023
                                ,"REVENUE_MONTH"     : 7
                                ,"SPOT_LENGTH"       : 1890
                            },
                            "2022|||2"         : {
                                 "PROG_ID"           : -2
                                ,"ADVERTISER_STATUS" : null
                                ,"CONTRACT_TYPE"     : null
                                ,"REVENUE_YEAR"      : 2022
                                ,"REVENUE_MONTH"     : 2
                                ,"SPOT_LENGTH"       : 20915
                            },
                            "2022|||7"         : {
                                 "PROG_ID"           : -2
                                ,"ADVERTISER_STATUS" : null
                                ,"CONTRACT_TYPE"     : null
                                ,"REVENUE_YEAR"      : 2022
                                ,"REVENUE_MONTH"     : 7
                                ,"SPOT_LENGTH"       : 23745
                            },
                            ""                 : {
                                 "PROG_ID"           : -4
                                ,"ADVERTISER_STATUS" : null
                                ,"CONTRACT_TYPE"     : null
                                ,"REVENUE_YEAR"      : null
                                ,"REVENUE_MONTH"     : null
                                ,"SPOT_LENGTH"       : 62780
                            },
                            "ROW TOTAL" : {
                                 "SPOT_LENGTH"       : 125560
                            }
                        }
                    */
                    
                } else {
                    
                    // al primo record con PROG_ID positivo mi segno l'indice e esco dal ciclo 
                    // (tanto scorrerò di nuovo l'intero recordset più avanti, ma partendo dall'indice del primo PROG_ID positivo)
                    nIndexPositiveRecords = nRecord;
                    break;
                    
                }
                
            }
            
        }
        
        bDebugTimers && console.info('nTimerDichiarazioni', ( ( performance.now() - nTimerDichiarazioni ) / 1000 ).toFixed(3) + 's' );
        const nTimerElaborazioni  = performance.now();
        
        // TODO ATTENZIONE A NON SOVRASCRIVERE COSI' UNA CHIAVE VUOTA GIA' ESISTENTE 
        oSubtotals[''] = oGrandTotal; // includo il record GrandTotal nei subtotali con chiave stringa vuota 
        
        const aaoColDimensionValues = []; // tabella delle intestazioni delle colonne pivot
        
        if ( isPivotEnabled ) {
            
            // aoRawPivotRecords sono i record relativi ai totali di colonna dei valori delle colonne pivot
            // sono quelli del PROG_ID negativo pari al numero di dimensioni pivot. Esempio PROG_ID -2
            
            // li ordino in base all'ordinamento delle singole colonne Pivot
            // TODO ordinare solo se l'ordinamento richiesto è diverso dai default delle colonne
            const aoPivotRecords = Utils.sortBy(
                 aoRawPivotRecords
                ,asPivotColNames
                ,asPivotColSorts /* ASC o DESC per ogni dimensione */
            );
            
            // per ogni DIMENSIONE PIVOT
            // (che corrisponderà ad una RIGA aggiuntiva della griglia di intestazioni)
            for ( let nPivotLevel = 0; nPivotLevel < nPivotDimensions; nPivotLevel++ ) {
                const sPivotColName = asPivotColNames[ nPivotLevel ];
                
                if ( !aaoColDimensionValues[ nPivotLevel ] ) {
                      aaoColDimensionValues[ nPivotLevel ] = [];
                }
                
                // per ogni RECORD PIVOT nel PROG_ID negativo (es. tutti i record con PROG_ID "-2", se sono due pivot)
                // (che corrisponderà ad una COLONNA della griglia di intestazioni)
                for ( let nPivotRecord = 0; nPivotRecord < aoPivotRecords.length; nPivotRecord++ ) {
                    const oPivotRecord = aoPivotRecords[ nPivotRecord ];
                    // es. { "PROG_ID": 4 ,"STATUS": "POTENTIAL" ,"TYPE": "STANDARD" ,"YEAR": 2022 ,"MONTH": 7 ,"LENGTH": 35443 }
                    
                    // popolo una griglia di valori (aaoColDimensionValues)
                    // che sarà la "tabella delle intestazioni delle colonne pivot"
                    // la cui description è il valore della colonna Pivot di quel livello
                    // e contiene anche i totali della riga GRAND TOTAL
                    aaoColDimensionValues[ nPivotLevel ].push(
                        {
                             column:        oPivotRecord[ sPivotColName ]
                            ,description:   oPivotRecord[ sPivotColName ]
                            , ...oPivotRecord
                        }
                    );
                    
                }
                
            }
            
        }
        
        /*  aaoColDimensionValues
        ____________________________________________________________________________________________________________________________________________
         column            : ROWTOT | column            : 2023  | column            : 2023  | column            : 2022  | column            : 2022  
         description       : ROWTOT | description       : 2023  | description       : 2023  | description       : 2022  | description       : 2022  
         PROG_ID           : -2     | PROG_ID           : -2    | PROG_ID           : -2    | PROG_ID           : -2    | PROG_ID           : -2    
         ADVERTISER_STATUS : null   | ADVERTISER_STATUS : null  | ADVERTISER_STATUS : null  | ADVERTISER_STATUS : null  | ADVERTISER_STATUS : null  
         CONTRACT_TYPE     : null   | CONTRACT_TYPE     : null  | CONTRACT_TYPE     : null  | CONTRACT_TYPE     : null  | CONTRACT_TYPE     : null  
         REVENUE_YEAR      : ROWTOT | REVENUE_YEAR      : 2023  | REVENUE_YEAR      : 2023  | REVENUE_YEAR      : 2022  | REVENUE_YEAR      : 2022  
         REVENUE_MONTH     : null   | REVENUE_MONTH     : 2     | REVENUE_MONTH     : 7     | REVENUE_MONTH     : 2     | REVENUE_MONTH     : 7     
         SPOT_LENGTH       : 62780  | SPOT_LENGTH       : 16230 | SPOT_LENGTH       : 1890  | SPOT_LENGTH       : 20915 | SPOT_LENGTH       : 23745 
        ____________________________________________________________________________________________________________________________________________
         column            : ROWTOT | column            : 2     | column            : 7     | column            : 2     | column            : 7     
         description       : ROWTOT | description       : 2     | description       : 7     | description       : 2     | description       : 7     
         PROG_ID           : -2     | PROG_ID           : -2    | PROG_ID           : -2    | PROG_ID           : -2    | PROG_ID           : -2    
         ADVERTISER_STATUS : null   | ADVERTISER_STATUS : null  | ADVERTISER_STATUS : null  | ADVERTISER_STATUS : null  | ADVERTISER_STATUS : null  
         CONTRACT_TYPE     : null   | CONTRACT_TYPE     : null  | CONTRACT_TYPE     : null  | CONTRACT_TYPE     : null  | CONTRACT_TYPE     : null  
         REVENUE_YEAR      : null   | REVENUE_YEAR      : 2023  | REVENUE_YEAR      : 2023  | REVENUE_YEAR      : 2022  | REVENUE_YEAR      : 2022  
         REVENUE_MONTH     : ROWTOT | REVENUE_MONTH     : 2     | REVENUE_MONTH     : 7     | REVENUE_MONTH     : 2     | REVENUE_MONTH     : 7     
         SPOT_LENGTH       : 62780  | SPOT_LENGTH       : 16230 | SPOT_LENGTH       : 1890  | SPOT_LENGTH       : 20915 | SPOT_LENGTH       : 23745 
        ____________________________________________________________________________________________________________________________________________
        */
        
        const
             nFixedColumnWidth      = 350
            ,oStyleFixedcolumnWidth = ( nColumnWidth = nFixedColumnWidth ) => ({
                maxWidth: ( !bTabularMode ? nFixedColumnWidth : nColumnWidth )
             })
        ;
        
        // --- viene aggiunta una colonna alle intestazioni IN ALTO A DESTRA per i totali di riga
        
        // se i totali di riga sono attivi (solo pivot)
        if ( isPivotEnabled && bRowTotal ) {
            
            // per ogni dimensione Pivot
            for ( let nPivotLevel = 0; nPivotLevel < asPivotColNames.length; nPivotLevel++ ) {
                const sPivotColName = asPivotColNames[ nPivotLevel ];
                
                // viene creata una colonna fittizia (copiando il primo record dei subtotali)
                const oSubtotalsTotRiga             = { ...oSubtotals[''] } || {};
                oSubtotalsTotRiga.column            = ''; // sRowTotalLabel + sPivotColName;
                oSubtotalsTotRiga.description       = ''; // sRowTotalLabel + sPivotColName;
                oSubtotalsTotRiga[ sPivotColName ]  = ''; // sRowTotalLabel + sPivotColName;
                // all'inizio di ogni riga viene aggiunta una colonna alle intestazioni IN ALTO A DESTRA
                aaoColDimensionValues[ nPivotLevel ].unshift(oSubtotalsTotRiga);
                // TODO qui si possono aggiungere colonne di intestazioni in base al numero di dimensioni pivot
                // una colonna per ogni valore della prima dimensione pivot
                // una colonna per ogni valore della seconda dimensione pivot (moltiplicando il gruppo per ogni valore della prima)
                // ...
            }
            
        }
        
        // console.info('aaoColDimensionValues');
        // console.info('-----------------');
        // console.info('aaoColDimensionValues');
        // console.info( Utils.logAAO(aaoColDimensionValues)); //, ['column', 'REVENUE_YEAR', 'REVENUE_MONTH'] ) );
        
        const aHeads = [], /* aaHeadIntCols = [], */ aaHeadIntRows = [];
        
        // svuoto l'ordinamento per tutte le altre misure (solo UNA alla volta può essere ordinata)
        function resetOrderForMeasures( sMeasureCode ) {
            for ( const m of aoAllMeasures ) {
                if ( m.column !== sMeasureCode ) {
                     m.sortDirection = '';
                     m.pSortingValue = '';
                }
            }
        }
        
        // resetto l'ordinamento per tutte le dimensioni (tranne le pivot, che devono mantenere il loro ordinamento)
        function resetOrderForDimensions() {
            for ( const oDim of aoAllDimensions ) {
                if ( !oDim.pivoted ) {
                    oDim.sortDirection = 'ASC';
                }
            }
        }
        
        function onClickSortCol( event ) {
            
            const
                 target               = event.currentTarget
                ,sColumnName          = target.getAttribute('data-column-name'   )  // recupero il nome della colonna da ordinare
                ,sActualSortDirection = target.getAttribute('data-sort-direction')  // recupero lo stato attuale dell'icona ( 'ASC' o 'DESC' )
                
                ,parent               = target.parentNode.parentNode
                ,sMeasure             = parent.getAttribute('data-misura'        )  // recupero il nome della misura
            ;
            let oMeasure              = null;
            let isOneMeasureOrdered   = false;
            
            // serve per trovare la misura cliccata come oggetto 
            // e segnarsi se almeno una misura è ordinata
            for ( const oMea of aoMeasures ) {
                if ( !oMeasure && ( oMea.column === sMeasure ) ) {
                    oMeasure = oMea;
                }
                if ( !isOneMeasureOrdered && oMea.sortDirection ) {
                    isOneMeasureOrdered = true;
                }
                if ( oMeasure && isOneMeasureOrdered ) break;
            }
            
            if ( oMeasure ) { // se è una misura
                
                let pSortingValue = '';
                for ( const sPivotColName of asPivotColNames ) {
                                                                        // recupero il valore della colonna pivot da ordinare
                    pSortingValue += ( pSortingValue ? '|||' : '' ) + parent.getAttribute('data-pivot-' + sPivotColName.toLowerCase() );
                }
                
                resetOrderForDimensions();
                // al 1° click su una misura senza sortDirection di default è DESC, altrimenti un normale toggle
                oMeasure.sortDirection = !sActualSortDirection || ( sActualSortDirection === 'ASC' ) ? 'DESC' : 'ASC';                    
                oMeasure.pSortingValue = pSortingValue;
                resetOrderForMeasures( oMeasure.column );

            } else { // altrimenti se è una dimensione

                resetOrderForMeasures(''); // rimuovo l'ordinamento da tutte le misure

                if ( isOneMeasureOrdered ) {    // ma se prima c'era una misura ordinata
                    resetOrderForDimensions();  // rimuovo l'ordinamento da tutte le dimensioni
                    
                } else {
                    // se invece erano ordinate le dimensioni
                    // procedo solo a invertire l'ordinamento della attuale dimensione cliccata
                    const oDimension = aoAllDimensions.find( oDimension => oDimension.column === sColumnName );
                    if ( oDimension ) {
                        oDimension.sortDirection = ( sActualSortDirection === 'ASC' ) ? 'DESC' : 'ASC';
                    }

                }

            }
            
            refreshCubeBuilderState({ aoDimensions: aoAllDimensions, aoMeasures: aoAllMeasures });

        }
        
        function assignSortIcon( { sColumnName, pSortingValue } ) {
            // al primo caricamento di questo componente recupero dal padre lo stato attuale di ordinamento
            
            const
                // serve per ottenere un array di stringhe a partire da una stringa con ||| come separatore degli elementi
                 get_asSortValues           = (s) => ( ( typeof s === 'string' ) ? s : '' ).split('|||').filter(v=>v)
                
                // identificazione della colonna cliccata (click su icona sort)
                ,oDimension                 = oDimensions[ sColumnName ]
                ,oMeasure                   = oMeasures[   sColumnName ]
                
                // recupero della misura attualmente selezionata (prima del click)
                ,oMeasureOrdered            = aoMeasures.find( oMea => oMea.sortDirection )
                ,asMeasureOrderedSortValues = get_asSortValues( oMeasureOrdered?.pSortingValue )
                
                ,asMeasureClickedSortValues = get_asSortValues( pSortingValue )
                
                ,isOneMeasureOrdered        = (
                    !!oMeasureOrdered // se una misura è ordinata E 
                    && (
                        // o non siamo in pivot
                        !isPivotEnabled
                        
                        // oppure (siamo in pivot) e il pSortingValue è INCLUSO nei possibili VALORI PIVOT
                        // TODO ATTENZIONE! In caso di virtualizzazione orizzontale potrebbero non venire estratti tutti i valori pivot
                        // ma si deve poter ordinare comunque per un pSortingValue
                        || aaoColDimensionValues[ aaoColDimensionValues.length - 1 ]?.some(
                            oCol => asMeasureOrderedSortValues.includes( oCol.description + '' )
                        )
                        
                    )
                )
                ,sSortDirection             = (
                    oDimension ? ( oDimension.sortDirection  ) : // se è una dimensione prendo la sortDirection
                    oMeasure   ? ( // se è una misura
                            // se è previsto un pSortingValue (nel caso pivot)
                            // verifico che ogni pSortingValue sia uguale al rispettivo della misura (come stringhe)
                            get_asSortValues( oMeasure?.pSortingValue ).every( ( val, index ) => val === asMeasureClickedSortValues[ index ] )
                            // infine restituisco la sortDirection della misura
                            && oMeasure.sortDirection 
                        )
                    : ''
                )
                
                // classe css che serve proprio per "accendere" l'icona del sorting
                ,active                     = (
                        ( !isOneMeasureOrdered && ( oDimension || {} ).sortDirection )
                     || (  isOneMeasureOrdered && ( oMeasure   || {} ).sortDirection )
                     ? ' active' : ''
                )
                
                ,classSortingType = ( sSortDirection === 'ASC'  ) ? 'ascending' : 'descending'
                ,oArrowProps      = {
                     'data-column-name'    : sColumnName
                    ,'data-misura'         : oMeasure?.column
                    ,'data-sort-direction' : sSortDirection || ''
                    ,'className'           : 'sortIcon ' + classSortingType + active
                    ,'onClick'             : onClickSortCol
                }
            ;
            
            for ( let nPivot = 0; nPivot < nPivotDimensions; nPivot++ ) {
                oArrowProps[ 'data-pivot-' + asPivotColNames[nPivot].toLowerCase() + '-value' ] = asMeasureClickedSortValues[nPivot];
                // es. 'data-pivot-revenue_year-value' = '2024'
            }
            
            // in ogni caso viene renderizzata l'icona (ma visibile solo al passaggio del mouse) per effettuare il sort, di fianco al nome della colonna
            return ( sSortDirection === 'ASC' )
                ? <span className="sortIcon" title={ 'Sort ' + classSortingType } ><ArrowUpwardIcon   { ...oArrowProps } /></span>
                : <span className="sortIcon" title={ 'Sort ' + classSortingType } ><ArrowDownwardIcon { ...oArrowProps } /></span>
            ;

        }
        
        function onClickDrillIcon( event, oRecord ) {
            event.stopPropagation();
            const sSelectedDimensionDrillDown = event.currentTarget.getAttribute('data-column-name');
            setnRowSelected(0);
            drillDown( { oRecord, sSelectedDimensionDrillDown } );
        }
        
        // -------------------------------------------------------------------------------------------------------------
        
        const
             nMeasures     = asMeasuresColNames.length
            ,nPivotCols    = aaoColDimensionValues?.[0]?.length 
            ,nMeasureCalcs = aoMeasureCalcs.length
            ,nMaxHeadRows  = ( ( nMeasures > 0 ) ? 1 : 0 ) + asPivotColNames.length + ( ( nMeasureCalcs > 0 ) ? 1 : 0 )
            ,creaCellaVuota= ( o, n ) => <th key={ 'headIntRiga' + ( o?.column || '' ) + n } className="dimension" ></th>
            ,aCelleVuote   = aoDimensions.slice(1).map( creaCellaVuota )
        ;
        
        // bInvertPivot === TRUE     =>    prima PIVOT e poi MISURA
        // bInvertPivot === FALSE    =>    prima MISURA e poi PIVOT
        
        const aoHeadRows = [];
        
        if ( bInvertPivot && asPivotColNames.length ) {
            aoHeadRows.push( ...aoPivotDimensions ); // aaoColDimensionValues
        }
        
        aoHeadRows.push({}); // oggetto vuoto perché per la misura non c'è bisogno di visualizzare niente
        
        if ( !bInvertPivot && asPivotColNames.length ) {
            aoHeadRows.push( ...aoPivotDimensions ); // aaoColDimensionValues
        }
        
        aoHeadRows.push({}); // oggetto vuoto perché per le calc non c'è bisogno di visualizzare niente
        
        let nExcelColADXinitial = 1;
        
        // ASX: in Alto a Sinistra
        // per creare le celle che conterranno i nomi delle colonne delle dimensioni (intestazioni di riga IN ALTO A SINISTRA)
        // ● ciclo sulle dimensioni (es. CHANNEL, ADVERTISER... )
        for ( let nRiga = 0; nRiga < nMaxHeadRows; nRiga++ ) {
            
            // per tutte le righe tranne l'ultima
            if ( nRiga < ( nMaxHeadRows - 1 ) ) {
                
                const { column, description, /* sHeadType */ } = aoHeadRows[ nRiga ];
                
                const nCelleVuote = aCelleVuote.length - 1;
                
                if ( isForExcel ) {
                    for ( let i = 0; i <= nCelleVuote ; i++ ) {
                        fillSheetCell({
                             nRow:      nExcelRow
                            ,nCol:      nExcelCol
                            ,value:     ''
                            ,aoStyles:  [ oStyles[ 'verde0' + ( ( nMaxHeadRows + 1 ) - nExcelRow ) ] ]
                        });
                        nExcelCol++;
                    }
                }
                
                let cellaPivot;
                
                if ( !column ) {
                    
                    if ( isForExcel ) {
                        fillSheetCell({
                             nRow:      nExcelRow
                            ,nCol:      nExcelCol
                            ,value:     ''
                            ,aoStyles:  [ oStyles[ 'verde0' + ( ( nMaxHeadRows + 1 ) - nExcelRow ) ] ]
                        });
                        nExcelCol++;
                    }
                    
                    cellaPivot = creaCellaVuota();
                    
                } else {
                    
                    if ( isForExcel ) {
                        fillSheetCell({
                             nRow:      nExcelRow
                            ,nCol:      nExcelCol
                            ,value:     description
                            ,aoStyles:  [ oStyles.leftBold, oStyles[ 'verde0' + ( ( nMaxHeadRows + 1 ) - nExcelRow ) ] ]
                        });
                        nExcelCol++;
                    }
                    
                    cellaPivot = <th
                        key       ={ 'headIntRiga' + column + nCelleVuote }
                        className ={ 'first dimension PIVOT ' + column }
                    >{ description }{ assignSortIcon({ sColumnName: column }) }</th>;
                    
                }
                
                aaHeadIntRows[ nRiga ] = [ aCelleVuote, cellaPivot ];
                
            // nell'ultima riga
            } else {
                
                aaHeadIntRows[ nRiga ] = []; // forzo inizializzazione
                
                for ( let nIndex = 0; nIndex < aoDimensions.length; nIndex++ ) {
                    const { column, description, columnWidth } = aoDimensions[nIndex];
                    
                    if ( isForExcel ) {
                        fillSheetCell({
                             nRow:      nExcelRow
                            ,nCol:      nExcelCol
                            ,value:     description
                            ,aoStyles:  [ oStyles.leftBold, oStyles[ 'verde0' + ( ( nMaxHeadRows + 1 ) - nExcelRow ) ] ]
                        });
                        nExcelCol++;
                    }
                    
                    aaHeadIntRows[nRiga].push(
                        <th
                            key       ={ 'headIntRiga' + column + nIndex }
                            ref       ={ el => { colsRefs.current[ nIndex ] = el; } }
                            className ={ ( ( nIndex === aoDimensions.length - 1 ) ? 'first' : '' ) + ' dimension '  + column }
                            style     ={ {
                                 minWidth: ( !bTabularMode ? nFixedColumnWidth : 'unset' )
                                ,...oStyleFixedcolumnWidth(columnWidth)
                            } }
                        >{ description }{ assignSortIcon( { sColumnName: column } ) }</th>
                    );
                }
            }
            
            if ( isForExcel ) {
                // alla fine di ogni riga ASX (aoHeadRows)
                nExcelRow++;    // Nuova riga
                nExcelColADXinitial = nExcelCol;// Mi segno l'ultima colonna utilizzata, che serve alla parte ADX
                nExcelCol = 1; // RESETTO alla colonna 1
            }
            
        }
        
        /* mi segno l'ordine dei blocchi che compongono l'intestazione (esclusi CALC)
        const asNomiBlocchi = [];
        
        if ( bInvertPivot && aoPivotDimensions.length ) {
            asNomiBlocchi.push( 'PIVOT' ); // aaoColDimensionValues
        }
        
        asNomiBlocchi.push( 'MISURE' ); // aoMeasures
        
        if ( !bInvertPivot && aoPivotDimensions.length ) {
            asNomiBlocchi.push( 'PIVOT' ); // aaoColDimensionValues
        }
        */
        
        /*
            ● blocco PIVOT (opzionale) matrice di valori delle dimensioni pivot
            ● blocco MISURE
            ● blocco PIVOT (opzionale) matrice di valori delle dimensioni pivot
            ● blocco CALCS calcoli delle misure (#,%,Δ...)
        */
        
        const aaoHeadGrid = [];
        /*
        const getTotaleElementi = ( nBlocco ) => {
            let nTotaleElementi = 0;
            let nLimite = ( anTotaleElementiPerBlocco.length - nBlocco );
            nLimite = nLimite < 2 ? 1 : nLimite;
            
            for ( let nIndiceTotaleBlocco = 0; nIndiceTotaleBlocco < nLimite; nIndiceTotaleBlocco++ ) {
                nTotaleElementi += anTotaleElementiPerBlocco[ nIndiceTotaleBlocco ] || 0;
            }
            
            return nTotaleElementi;
        };
        */
        let nRowHeadGrid  = 0;
        let nColHeadGrid  = 0;
        
        
        // --- sezione per creare (in aaoHeadGrid) le righe dei blocchi da visualizzare ( misure, pivot, calc ) ---
        
        
        if ( isPivotEnabled && bInvertPivot ) {
            
            // ● --- BLOCCO PIVOT ---
            
            // per ogni RIGA della matrice di combinazione dei valori PIVOT (quindi per ogni dimensione pivot)
            for ( let nRigaPivot = 0; nRigaPivot < aaoColDimensionValues.length; nRigaPivot++ ) {
                const aoColValues     = aaoColDimensionValues[ nRigaPivot ];
                
                if ( !aaoHeadGrid[ nRowHeadGrid ] ) {
                      aaoHeadGrid[ nRowHeadGrid ] = [];
                }
                
                // per ogni combinazione dei valori PIVOT (compresi totali di riga)
                for ( let nPivotComb = 0;   nPivotComb < aoColValues.length;    nPivotComb++ ) {
                    
                    // per ogni MISURA
                    for ( let nMeasure = 0;     nMeasure < nMeasures;               nMeasure++ ) {
                        
                        // per ogni CALC
                        for ( let nCalc = 0;        nCalc < aoMeasureCalcs.length;              nCalc++ ) {
                            
                            // solo se sono attivi i totali di riga, 
                            // si tratta della prima colonna (i totali di riga),
                            // e per tutte le altre CALC (%, Δ, Δ%) diverse da #
                            if ( bRowTotal && ( nPivotComb === 0 ) && ( aoMeasureCalcs[nCalc].column !== '#' )) {
                                continue;
                            }
                            
                            // aggiungo la cella (e le sue copie) alla riga della griglia
                            aaoHeadGrid[ nRowHeadGrid ].push({
                                 nRowHeadGrid 
                                ,nColHeadGrid
                                ,oColValues     : aoColValues[ nPivotComb ]
                                ,oColNextValues : aoColValues[ nPivotComb + 1 ]
                                ,nColIndex      : nPivotComb
                                ,isFirstCalc    : nCalc === 0
                                ,sHeadType      : 'PIVOT'
                                ,sPivotColName  : asPivotColNames[ nRigaPivot ]
                            });
                            
                            nColHeadGrid++;
                            
                        }
                        
                    }
                    
                }
                
                nRowHeadGrid++;
                nColHeadGrid = 0;
                
            }
            
            // ● --- BLOCCO MISURE ---
            
            // per ogni combinazione dei valori PIVOT (compresi totali di riga)
            for ( let nPivotComb = 0; nPivotComb < nPivotCols;          nPivotComb++ ) {
                    
                // per ogni MISURA
                for ( let nMeasure = 0;     nMeasure < nMeasures;           nMeasure++ ) {
                    
                    // per ogni CALC
                    for ( let nCalc = 0;        nCalc < nMeasureCalcs;          nCalc++ ) {
                        
                        // solo se sono attivi i totali di riga, 
                        // si tratta della prima colonna (i totali di riga),
                        // e per tutte le altre CALC (%, Δ, Δ%) diverse da #
                        if ( bRowTotal && ( nPivotComb === 0 ) && ( aoMeasureCalcs[nCalc].column !== '#' ) ) {
                            continue;
                        }
                        
                        if ( !aaoHeadGrid[ nRowHeadGrid ] ) {
                              aaoHeadGrid[ nRowHeadGrid ] = [];
                        }
                        
                        // aggiungo la cella (e le sue copie) alla riga della griglia
                        aaoHeadGrid[ nRowHeadGrid ].push({
                             nRowHeadGrid
                            ,nColHeadGrid
                            ,oColValues     : aoMeasures[ nMeasure ]
                            ,oColNextValues : aoMeasures[ nMeasure + 1 ]
                            ,nColIndex      : nMeasure
                            ,isFirstCalc    : nCalc === 0
                            ,sHeadType      : 'MISURA'
                            ,isFirst        : ( nCalc === 0 )
                        });
                        
                        nColHeadGrid++;
                        
                    }
                    
                }
                
            }
            
            nRowHeadGrid++;
            nColHeadGrid = 0;
            
        }
        
        // ● --- BLOCCO MISURE ---
        if ( !isPivotEnabled ) {
            
            // per ogni MISURA
            for ( let nMeasure = 0;     nMeasure < nMeasures;       nMeasure++ ) {
                
                // per ogni CALC
                for ( let nCalc = 0;        nCalc < nMeasureCalcs;      nCalc++ ) {
                    
                    if ( !aaoHeadGrid[ nRowHeadGrid ] ) {
                          aaoHeadGrid[ nRowHeadGrid ] = [];
                    }
                    
                    // aggiungo la cella (e le sue copie) alla riga della griglia
                    aaoHeadGrid[ nRowHeadGrid ].push({
                         nRowHeadGrid
                        ,nColHeadGrid
                        ,oColValues     : aoMeasures[ nMeasure ]
                        ,oColNextValues : aoMeasures[ nMeasure + 1 ]
                        ,nColIndex      : nMeasure
                        ,isFirstCalc    : nCalc === 0
                        ,sHeadType      : 'MISURA'
                        ,isFirst        : ( nCalc === 0 )
                    });
                    
                    nColHeadGrid++;
                    
                }
                
            }
            
            nRowHeadGrid++;
            nColHeadGrid = 0;
            
        }
        
        if ( isPivotEnabled && !bInvertPivot ) {
            
            // ● --- BLOCCO MISURE ---
            
            // per ogni MISURA
            for ( let nMeasure = 0; nMeasure < asMeasuresColNames.length; nMeasure++ ) {
                
                // per ogni combinazione dei valori PIVOT (compresi totali di riga)
                for ( let nPivotComb = 0; nPivotComb < nPivotCols;      nPivotComb++ ) {
                    
                    // per ogni CALC
                    for ( let nCalc = 0;        nCalc < nMeasureCalcs;          nCalc++ ) {
                        
                        // solo se sono attivi i totali di riga, 
                        // si tratta della prima colonna (i totali di riga),
                        // e per tutte le altre CALC (%, Δ, Δ%) diverse da #
                        if ( bRowTotal && ( nPivotComb === 0 ) && ( aoMeasureCalcs[nCalc].column !== '#' ) ) {
                            continue;
                        }
                        
                        if ( !aaoHeadGrid[ nRowHeadGrid ] ) {
                              aaoHeadGrid[ nRowHeadGrid ] = [];
                        }
                        
                        // aggiungo la cella (e le sue copie) alla riga della griglia
                        aaoHeadGrid[ nRowHeadGrid ].push({
                            nRowHeadGrid
                            ,nColHeadGrid
                            ,oColValues     : aoMeasures[ nMeasure ]
                            ,oColNextValues : aoMeasures[ nMeasure + 1 ]
                            ,nColIndex      : nMeasure
                            ,isFirstCalc    : nCalc === 0
                            ,sHeadType      : 'MISURA'
                            ,isFirst        : ( nPivotComb === 0 ) && ( nCalc === 0 )
                        });
                        
                        nColHeadGrid++;
                        
                    }
                    
                }
                
            }
            
            nRowHeadGrid++;
            nColHeadGrid = 0;
            
            // ● --- BLOCCO PIVOT ---
            
            // per ogni RIGA della matrice di combinazione dei valori PIVOT (quindi per ogni dimensione pivot)
            for ( let nRigaPivot = 0; nRigaPivot < aaoColDimensionValues.length; nRigaPivot++ ) {
                const aoColValues     = aaoColDimensionValues[ nRigaPivot ];
                
                if ( !aaoHeadGrid[ nRowHeadGrid ] ) {
                      aaoHeadGrid[ nRowHeadGrid ] = [];
                }
                
                // per ogni MISURA
                for ( let nMeasure = 0; nMeasure < nMeasures; nMeasure++ ) {
                    
                    // per ogni combinazione dei valori PIVOT (compresi totali di riga)
                    for ( let nPivotComb = 0; nPivotComb < aoColValues.length; nPivotComb++ ) {
                        
                        // per ogni CALC
                        for ( let nCalc = 0; nCalc < aoMeasureCalcs.length; nCalc++ ) {
                            
                            // solo se sono attivi i totali di riga, 
                            // si tratta della prima colonna (i totali di riga),
                            // e per tutte le altre CALC (%, Δ, Δ%) diverse da #
                            if ( bRowTotal && ( nPivotComb === 0 ) && ( aoMeasureCalcs[nCalc].column !== '#' ) ) {
                                continue;
                            }
                            
                            // aggiungo la cella (e le sue copie) alla riga della griglia
                            aaoHeadGrid[ nRowHeadGrid ].push({
                                 nRowHeadGrid
                                ,nColHeadGrid 
                                ,oColValues     : aoColValues[ nPivotComb ]
                                ,oColNextValues : aoColValues[ nPivotComb + 1 ]
                                ,nColIndex      : nPivotComb
                                ,isFirstCalc    : nCalc === 0
                                ,sHeadType      : 'PIVOT'
                                ,sPivotColName  : asPivotColNames[ nRigaPivot ]
                            });
                            
                            nColHeadGrid++;
                            
                        }
                        
                    }
                    
                }
                
                nRowHeadGrid++;
                nColHeadGrid = 0;
                
            }
            
        }
        
        // ● --- BLOCCO CALCS ---
        
        const nMoltiplicatorePerCalcs = nMeasures * ( nPivotCols || 1 ) ;
        
        // per ogni elemento dei blocchi "MISURE" e "PIVOT"
        for ( let nCellToAdd = 0; nCellToAdd < nMoltiplicatorePerCalcs; nCellToAdd++ ) {
            
            // per ogni CALC
            for ( let nCalc = 0; nCalc < aoMeasureCalcs.length; nCalc++ ) {
                
                // solo se sono attivi i totali di riga, 
                // si tratta della colonna dei totali di riga
                // e per tutte le altre CALC (%, Δ, Δ%) diverse da #
                // non effettuiamo il render della colonna
                if (
                    bRowTotal 
                    && (
                        bInvertPivot 
                            ? ( nCellToAdd < nMeasures )
                            : ( ( nCellToAdd % nPivotCols ) === 0 )
                    )
                    && ( aoMeasureCalcs[nCalc].column !== '#' )
                ) {
                    continue;
                }
                
                if ( !aaoHeadGrid[ nRowHeadGrid ] ) {
                      aaoHeadGrid[ nRowHeadGrid ] = [];
                }
                
                // aggiungo la cella con la calc alla riga della griglia
                aaoHeadGrid[ nRowHeadGrid ].push({
                     nRowHeadGrid
                    ,nColHeadGrid
                    ,oColValues     : aoMeasureCalcs[ nCalc ]
                    ,oColNextValues : aoMeasureCalcs[ nCalc + 1 ]
                    ,nColIndex      : nCalc
                    ,isFirstCalc    : nCalc === 0
                    ,sHeadType      : 'CALC'
                });
                
                nColHeadGrid++;
                
            }
            
        }
        // quindi vengono ripetute le colonne delle calc (es. # e %) per ogni misura e per ogni combinazione di valori pivot
        
        bDebugTimers && console.info('nTimerElaborazioni', ( ( performance.now() - nTimerElaborazioni ) / 1000 ).toFixed(3) + 's' );
        const nTimerEsecuzione  = performance.now();
        
        // DOPO aver popolato aaoHeadGrid effettuo 2 operazioni in un unico ciclo: 
        
        // 1) Mi segno la gerarchia di appartenenza di ogni cella fino a quel punto
        //    in modo tale che di ogni cella si possa conoscere le sue coordinate blocco (pivot, misure o calc) 
        //    e in base al blocco le informazioni aggiuntive (in particolare per pivot)
        
        // 2) Riempio l'array aHeads che verrà usato per il render html
        
        nExcelRow = 1; // RESET delle righe ripartendo dalla prima
        
        // ADX: in Alto a Destra
        // per ogni riga della griglia di intestazione (IN ALTO A DESTRA)
        for ( let nRowHeadGrid = 0; nRowHeadGrid < aaoHeadGrid.length; nRowHeadGrid++ ) {
            
            nExcelCol = nExcelColADXinitial; // RESET della colonna da cui ripartire per ogni riga della parte destra ADX
            
            // creazione della gerarchia 
            const oColHierarchy = {};
            const aoHeadGrid    = aaoHeadGrid[ nRowHeadGrid ];
            
            if ( !aoHeadGrid ) {
                continue;
            }
            
            // per ogni colonna della griglia
            for ( let nColHeadGrid = 0; nColHeadGrid < aoHeadGrid.length; nColHeadGrid++ ) {
                
                let sPivotHierarchy = '', sFullHierarchy = '';
                
                // per ogni riga della griglia fino alla riga attuale compresa
                for ( let nRow = 0; nRow <= nRowHeadGrid; nRow++ ) {
                    
                    if ( !aaoHeadGrid?.[ nRow ]?.[ nColHeadGrid ] ) {
                        continue;
                    }
                    
                    const { sHeadType, sPivotColName, oColValues } = aaoHeadGrid[ nRow ][ nColHeadGrid ];
                    const isRowPivot    = ( ( sHeadType === 'PIVOT' ) && sPivotColName );
                    
                    // es. 'MISURA' o 'PIVOT-YEAR' o 'CALC' (tre casi)
                    const sHierarchyKey = sHeadType + ( isRowPivot ? ( '-' + sPivotColName ) : '' );
                    
                    if ( isRowPivot ) {
                        // es. '2023|||7' per due dimensioni pivot come YEAR e MONTH
                        sPivotHierarchy += ( !sPivotHierarchy ? '' : '|||' ) + oColValues?.column;
                    }
                    
                    // es. 'GROSS|||2023|||7|||%'
                    sFullHierarchy      += ( !sFullHierarchy  ? '' : '|||' ) + oColValues?.column;
                    
                    // popolo un oggetto le cui coppie chiave/valore contengono informazioni sulle righe precedenti
                    // serve per avere indicazioni sulla "gerarchia" di appartenenza di una cella nella griglia
                    oColHierarchy[ sHierarchyKey ] = oColValues?.column;
                    /* esempio di oColHierarchy:
                        {
                             'MISURA'      : 'GROSS'
                            ,'PIVOT-YEAR'  : 2023
                            ,'PIVOT-MONTH' : 7
                            ,'CALC'        : '%'
                        }
                    */
                    
                }
                
                const o           = aoHeadGrid[    nColHeadGrid     ] || {};
                const aoPrevRow   = aaoHeadGrid?.[ nRowHeadGrid - 1 ];
                
                // ATTENZIONE !!! condizione estremamente complicata! NON TOCCARE!
                o.isFirstPrevLv   = aoPrevRow && (
                    ( getPrevLevelHierarchy( aoPrevRow?.[ nColHeadGrid - 1 ]?.sColHierarchy ) !== getPrevLevelHierarchy( aoPrevRow?.[ nColHeadGrid ]?.sColHierarchy ) )
                    || aoPrevRow?.[ nColHeadGrid - 1 ]?.oColValues?.description !== aoPrevRow?.[ nColHeadGrid ]?.oColValues?.description
                );
                o.oColHierarchy   = { ...oColHierarchy }; // es. { 'MISURA': 'GROSS', 'PIVOT-YEAR': 2023, 'PIVOT-MONTH': 7 ,'CALC': '%' }
                o.sColHierarchy   = sFullHierarchy;       // es. 'GROSS|||2023|||7|||%'
                o.sPivotHierarchy = sPivotHierarchy;      // es. '2023|||7'
                
            }
            
            const
                
                formatValue    = ( val, filterDataType ) => (
                    
                    // se è falsy, stringa vuota
                    ( ( val !== 0 ) && !val   ) ? ''  :
                    
                    // se è il totale di riga, 'ROW TOTAL'
                    ( val  === sRowTotalLabel ) ? val :
                    
                    // altrimenti effettuo una conversione in base al tipo
                    ( Utils.convertDataType( val, filterDataType ) + '' )
                )
                
                // crea una cella <th> delle intestazioni di colonna IN ALTO A DESTRA (ADX)
                ,creaCella      = ({
                     nRowHeadGrid    // coordinata di aaoHeadGrid
                    ,nColHeadGrid    // coordinata di aaoHeadGrid
                 // ,oColPrevValues  // oggetto precedente
                    ,oColValues      // { "column": "#", "description": "#", "cssClass": "COLnumber" }
                    ,oColNextValues  // oggetto successivo
                    ,nColIndex       // posizione all'interno del proprio gruppo ('MISURA', 'PIVOT-XXX', 'CALC')
                    ,sHeadType       // 'MISURA', 'PIVOT-XXX', 'CALC'
                    ,sPivotColName   // colonna pivot 'REVENUE_MONTH'
                    ,isFirst         // serve per assegnare classe css 'first' per visualizzare il valore della cella
                    ,oColHierarchy   // gerarchia intera { "MISURA": "SPOT_LENGTH", "PIVOT-REVENUE_YEAR": 2022, "PIVOT-REVENUE_MONTH": 12, "CALC": "#" }
                 // ,sColHierarchy   // gerarchia intera 'SPOT_LENGTH|||2022|||12|||#'
                    ,sPivotHierarchy // gerarchia pivot  '2022|||12'
                }) => {
                    // console.info('----------------------------------------');
                    // console.info({
                    //      nRowHeadGrid
                    //     ,nColHeadGrid
                    //     ,oColPrevValues
                    //     ,oColValues
                    //     ,oColNextValues
                    //     ,nColIndex
                    //     ,sHeadType
                    //     ,sPivotColName
                    //     ,isFirst
                    //     ,oColHierarchy
                    //     ,sColHierarchy
                    //     ,sPivotHierarchy
                    // });
                    
                    let sFirsts = '';
                    // per ogni riga della griglia fino alla riga attuale compresa
                    for ( let nActualRow = 0; nActualRow <= nRowHeadGrid; nActualRow++ ) {
                        // stessa colonna righe precedenti
                        // creo una stringa nel formato '32' che indica quali bordi vanno visualizzati e quanto sono scuri (numero alto equivale a più scuro)
                        sFirsts += ( aaoHeadGrid?.[ nActualRow ]?.[ nColHeadGrid ]?.isFirstPrevLv ? ( aaoHeadGrid.length - nActualRow ) || '' : '' ) + '';
                    }
                    
                    const
                         { column, description = '', cssClass } = oColValues || {}
                        ,{ filterDataType}  = oDimensions[ sPivotColName ] || {}
                        ,sMeasureColName    = oColHierarchy?.['MISURA']    || ''
                        ,oMeasure           = oMeasures[ sMeasureColName ] || {}
                        
                        ,isRowTotal         = isPivotEnabled && ( sPivotHierarchy === '' ) && ( ( oMeasure?.summable ?? 'Y' ) === 'Y' )
                        ,isLastHeadGridRow  = ( nRowHeadGrid === ( nMaxHeadRows - 1 ) )
                        ,isDelta            = ( column === 'Δ' ) || ( column === 'Δ%' )
                        ,isFirstMeasureCalc = isLastHeadGridRow && ( nColIndex === 0 )
                        ,formattedDesc      = (
                                                  isRowTotal
                                                  && !['MISURA','CALC'].includes(sHeadType)
                                                  && ( ( bInvertPivot && ( nRowHeadGrid === 0 ) ) || ( !bInvertPivot && ( nRowHeadGrid === 1 ) ) )
                                              )
                                              ? sRowTotalLabel
                                              : formatValue( description, filterDataType )
                     // ,colDesc            = ( column === description ) ? '' : description
                        
                        ,className          = (
                            sHeadType
                            + ' ' + ( sPivotColName || '' )
                            + ' ' + ( cssClass ? cssClass : ( column || '' ) )
                         // + ' ' + ( sRowTotalLabel       ?? '' )
                            + ( isFirstMeasureCalc ? ' firstMeaCalc ' : '' )
                            + ( isFirst ? ' first ' : '' )
                            + ( sFirsts ? ( ' border-color' + sFirsts ) : '' )
                        )
                        
                        ,verticalSortIcon   = () => assignSortIcon( 
                            { sColumnName: sMeasureColName ,pSortingValue: isPivotEnabled ? sPivotHierarchy : '' }
                        )
                        ,oNextPivot         = oColNextValues
                        ,sNextPivotValue    = isLastHeadGridRow && oNextPivot?.description
                        ,oHierarchyModified = Object.keys(oColHierarchy || {}).reduce(
                            ( o, sKey ) => ({ ...o, ['data-'+sKey.toLowerCase()]: oColHierarchy[sKey] })
                            ,{}
                        )
                        ,headGridCell       = (
                            <th
                                key       ={ 'headIntColonna' + nRowHeadGrid + '|' + nColHeadGrid + '|' + (column||'') + '|' + (description??'') }
                                { ...oHierarchyModified }
                                className ={ className }
                                style     ={ oStyleFixedcolumnWidth(oMeasure?.columnWidth) }
                                title     ={
                                
                                    !isLastHeadGridRow ? formattedDesc :
                                    
                                    ( !sNextPivotValue || ( isLastHeadGridRow && !isDelta ) )
                                        ? ''
                                        : ( description
                                            + ' '  + formattedDesc
                                            + ', ' + formatValue( sNextPivotValue, oNextPivot?.filterDataType )
                                        )
                                }
                            >{ isLastHeadGridRow && !isRowTotal && ( column === '#' ) && verticalSortIcon() }{ formattedDesc }</th>
                        )
                    ;
                    // if ( isPivotEnabled && ( sPivotHierarchy === '' ) && ( oMeasure?.summable === 'Y' ) && ( nRowHeadGrid === 0 ) ) {
                    //     console.info('formattedDesc: "' + formattedDesc + '"' );
                    // } else {
                    //     console.table([{
                    //          isPivotEnabled: isPivotEnabled ? '' : false
                    //         ,"sPivotHierarchy === ''": ( sPivotHierarchy === '' ) ? '' : sPivotHierarchy
                    //         ,"oMeasure?.summable === 'Y'" : ( oMeasure?.summable === 'Y' ) ? '' : oMeasure?.summable
                    //         ,"( nRowHeadGrid === 0 )": ( nRowHeadGrid === 0 ) ? '' : nRowHeadGrid
                    //         ,formattedDesc
                    //     }]);
                    // }
                    // per debug // return {formattedDesc};
                    
                    if ( isForExcel ) {
                        fillSheetCell({
                             nRow:      nExcelRow
                            ,nCol:      nExcelCol
                            ,value:     formattedDesc
                            ,aoStyles:  [
                                 isLastHeadGridRow ? oStyles.rightBold : oStyles.leftBold
                                ,( ( isLastHeadGridRow || isFirst ) ? v=>v : applicaColoreTestoComeSfondo )( oStyles[ 'verde0' + ( ( nMaxHeadRows + 1 ) - nExcelRow ) ] )
                            ]
                        });
                        nExcelCol++;
                    }
                    
                    return headGridCell;
                    
                }
            ;
            
            let oPrevHeadCell = {};  // elemento precedente
            
            // per le celle IN ALTO A DESTRA (ADX)
            function renderCell({ aaoHeadGrid, nRowHeadGrid }) {
                
                const aCelle    = [];
                const aoHeadRow = aaoHeadGrid[ nRowHeadGrid ];
                
                for ( let nHeadGrid = 0; nHeadGrid < aoHeadRow.length; nHeadGrid++ ) {
                    const oHeadCell = aoHeadRow[ nHeadGrid ];
                    
                    // console.info('oPrevHeadCell',getPrevLevelHierarchy( oPrevHeadCell.sColHierarchy ));
                    // console.info('oHeadCell',getPrevLevelHierarchy( oHeadCell.sColHierarchy ));
                    
                    if ( nRowHeadGrid < ( aaoHeadGrid.length - 1 ) ) {
                        // es. sColHierarchy 2023|||2|||SPOT_LENGTH
                        // solo se l'intera gerarchia precedente (quindi tutti i valori tranne l'ultimo) è diversa dall'elemento precedente
                        // ATTENZIONE !!! condizione estremamente complicata! NON TOCCARE!
                        oHeadCell.isFirst = (
                               ( getPrevLevelHierarchy( oHeadCell.sColHierarchy ) !== getPrevLevelHierarchy( oPrevHeadCell.sColHierarchy ) ) 
                            || ( oHeadCell?.oColValues?.description !== oPrevHeadCell?.oColValues?.description )
                        );
                    }
                    
                    // per le celle IN ALTO A DESTRA (ADX)
                    aCelle.push( creaCella(oHeadCell) );
                    
                    if ( oHeadCell.isFirst === true ) {
                        oPrevHeadCell = oHeadCell; // Se è il primo elemento di una nuova sequenza, aggiorna oPrevHeadCell con l'attuale elemento
                    }
                    // console.info('sPrev',sPrev);
                }
                
                return aCelle;
            }
            
            // riempio l'array delle righe IN ALTO con le intestazioni di RIGA (ASX) e, di fianco, le intestazioni di COLONNA (ADX)
            aHeads.push(                             // IN ALTO A SINISTRA (ASX)        // IN ALTO A DESTRA (ADX)
                <tr key={ 'head' + nRowHeadGrid }>{  aaHeadIntRows[ nRowHeadGrid ]  }{  renderCell({ aaoHeadGrid, nRowHeadGrid })  }</tr>
            );
            
            if ( isForExcel ) {
                // alla fine di ogni push in aHeads
                nExcelRow++;    // Nuova riga
                nExcelCol = 1;  // RESET Alla colonna 1 (perché completata la parte destra si riparte da 1 con la parte sinistra)
            }
            
        }
        
        bDebugTimers && console.info('nTimerEsecuzione', ( ( performance.now() - nTimerEsecuzione ) / 1000 ).toFixed(3) + 's' );
        
    // ------------------------------------------------------------------------------------------------
        
        // per il corpo della tabella si cicla su tutti i record della query
        
        let bodyRighe               = [];
        
        const get_sKeyCombSub       = ( oRecord, nLevel, sPivotValue ) => {
            // es. oRecord:     { "PROG_ID": 4 ,"STATUS": "POTENTIAL" ,"TYPE": "STANDARD" ,"YEAR": 2022 ,"MONTH": 7 ,"LENGTH": 35443 }
            // es. nLevel:      2
            // es. sPivotValue: '2022'
            nLevel = ( ( nLevel >= 0 ) ? nLevel : 0 );
            // inizializzo il risutato finale mettendo in testa il valore pivot (se c'è)
            let sResult = ( sPivotValue || ( sPivotValue === 0 ) ) ? sPivotValue : '';
            // per ogni livello di subtotale fino all'attuale livello (quindi per ogni dimensione fino a questo livello di subtotale)
            for ( let nDim = 0; nDim < nLevel; nDim++ ) {
                // ottengo il valore contenuto nel record per quella dimensione
                const value = ( oRecord || {} )[ asDimSelectedColNames[nDim] ] || '';
                if ( value || ( value === 0 ) ) {
                    if ( sResult !== '' ) {
                        sResult += '|||';
                    }
                    // e lo concateno con i valori delle dimensioni (subtotali) precedenti
                    sResult += ( value + '' );
                }
            }
            // es. risultato finale: '2022|||POTENTIAL|||STANDARD' (se le dimensioni selezionate erano "STATUS" e "TYPE")
            return sResult;
        };
        
        // CSX: in Centro a Sinistra
        // crea una cella <th> delle intestazioni di riga NEL CORPO CENTRALE A SINISTRA
        const creaIntestazioniRiga  = ({ PROG_ID, oRecord, oRecordPrec, isSubtotalRow, nSubtotalLevel, sSubtot, isGrandRow, classSubTot }) => {
            
            // es. oRecord e oRecordPrec: 
            // { "PROG_ID": 4 ,"STATUS": "POTENTIAL" ,"TYPE": "STANDARD" ,"YEAR": 2022 ,"MONTH": 7 ,"LENGTH": 35443 }
            const aBodyRigaIntestazioni     = [];
            const sClassSubTot              = classSubTot.trim();
            
            for ( let nDimension = 0; nDimension < aoDimensions.length; nDimension++ ) {
                const { column, filterDataType, columnWidth, subtotal } = aoDimensions[ nDimension ];
                
                let value = (
                    isGrandRow ? ( ( nDimension === 0 ) ? sGrandTotalLabel : '' )
                               : oRecord[ column ]
                );
                
                const isBeforeSubtotCol = ( nDimension  <  nSubtotalLevel );
                const isSubtotCol       = ( nDimension === nSubtotalLevel );
                const isAfterSubtotCol  = ( nDimension  >  nSubtotalLevel );
                
                const bForDrillDown     = (   // condizione per attivare il drill down:
                    !isGrandRow &&        // non deve essere il Grand Total E
                    ( !bTabularMode || (  // non deve essere in modalità Tabular OPPURE 
                        // ( è in modalità Tabular E
                        ( !isSubtotalRow && // nel caso delle righe di dati
                            (
                                ( nDimension < aoDimensions.length ) && //   per tutte le dimensioni
                                ( PROG_ID === nRowSelected )            //   e la riga deve essere selezionata
                            )
                        ) || ( isSubtotalRow && // nel caso dei subtotali
                            ( nDimension < aoDimensions.length - 1 )
                        )
                    ))
                );

                let sDrillTooltip           = 'Enter in drill down on:\n';
                for ( let nPosition = 0; nPosition < aoDimensions.length; nPosition++ ) {
                    const oDimension = aoDimensions[nPosition];
                    if ( nPosition <= nDimension ) {
                        sDrillTooltip += oDimension.description + ': ' + (
                            Utils.convertDataType( oRecord[ oDimension.column ], oDimension.filterDataType )
                        ) + '\n'
                    }
                }
                
                const classForDrillDown     = ( isSubtotalRow && isAfterSubtotCol ) ? '' : ( bForDrillDown ? ' drillDown ' : '' );
                // qui il frontend si differenzia dall'excel
                value = Utils.convertDataType( value, ( isGrandRow && ( nDimension === 0 ) ) ? 'C' : filterDataType );
                
                // per visualizzare il valore della cella della colonna il cui subtotale è disabilitato (con i subtotali abilitati)

                const
                    // per sapere se il subtotale (di questa specifica colonna di intestazione) è disabilitato
                     isSubtotalEnabled      = ( subtotal === 'Y' )
                    // il suo valore è diverso da quello della riga precedente (stessa colonna)
                    ,isRecordValueChanged   = ( oRecord[ column ] !== oRecordPrec?.[ column ] )
                    ,isAlreadySubtotal      = get_as1stSubTot()?.includes( column )
                    ,aoPrevDimensions       = aoDimensions.slice(0,nDimension)
                    
                    ,arePrevSubtotsValuesChanged = (({ aoPrevDimensions, oRecord, oRecordPrec }) => {
                        
                        if ( !oRecordPrec ) { return false; }
                        
                        const asDims = aoPrevDimensions.map( o => o.column ); // [ 'REVENUE_YEAR', 'DELIVERY_FAMILY' ]
                        
                        for ( let nDim = 0; nDim < asDims.length; ++nDim ) {
                            const sDim = asDims[nDim];
                            if ( oRecord[sDim] !== oRecordPrec[sDim]) {
                                return true;
                            } 
                        }
                        
                        return false;
                        
                    })({ aoPrevDimensions, oRecord, oRecordPrec })
                    
                    ,isOnePrevDimSubtot     = aoPrevDimensions.some( oDim => oDim.subtotal === 'Y' )
                ;
                
                let isToShow = false, sSubtotAddClass = '';
                /* Criteri di uso di isToShow per mostrare il valore:
                   Se i subtotali sono attivi:
                     a. Per righe subtotale:
                       - Celle prima della colonna subtotale:
                         • viene aggiunta classe 'subtotprevious'
                         • Mostra se: subtotale non abilitato, non è già subtotale ibrido, e
                           - C'è una dimensione subtotale precedente con valori cambiati, o
                           - Il valore del record è cambiato, o
                           - I subtotali precedenti sono cambiati
                       - Celle subtotale: sempre mostrate
                     b. Per righe non subtotale (record):
                       - Mostra se: i subtotali precedenti sono cambiati o il valore del record è cambiato,
                         e non è già un subtotale ibrido
                */
                
                bDebug && console.info('Inizio', JSON.stringify({ PROG_ID, isToShow, nDimension, column, value }));
                
                if ( sSubTotals ) { // subtotali attivi
                    
                    if ( isSubtotalRow ) {  // riga subtotale
                        bDebug && console.info(`-1a. Riga subtotale (${PROG_ID})`);
                        
                        if ( isBeforeSubtotCol ) {  // cella PRIMA della colonna subtotale
                            bDebug && console.info(`--2a. Cella PRIMA della colonna subtotale`);
                            sSubtotAddClass = 'subtotprevious';
                            
                            if ( !isSubtotalEnabled && !isAlreadySubtotal ) {
                                bDebug && console.info(`---3a. Subtotale non abilitato e non è già subtotale ibrido`);
                                
                                if ( isOnePrevDimSubtot && arePrevSubtotsValuesChanged ) {
                                    bDebug && console.info(`----4. c'è una dimensione di subtotale precedente e i suoi valori sono cambiati`);
                                    isToShow = true;
                                } else if ( isRecordValueChanged || arePrevSubtotsValuesChanged ) {
                                    bDebug && console.info(`----5x. ${ !isOnePrevDimSubtot ? 'non' : '' } c'è una dimensione di subtotale precedente`);
                                    bDebug && isRecordValueChanged        && console.info(`----5a. Valore cambiato`);
                                    bDebug && arePrevSubtotsValuesChanged && console.info(`----5b. subtotali precedenti cambiati`);
                                    isToShow = true;
                                }
                                
                            }
                            
                        } else if ( isSubtotCol ) { // CELLA SUBTOTALE
                            // va sempre visualizzata perché la riga del subtotale viene mostrata solo se è abilitato il subtotale di quella dimensione
                            isToShow = true;
                        }
                        
                    } else { // !isSubtotalRow    // riga NON subtotale (record)
                        bDebug && console.info(`-1b. Riga NON subtotale (record)`, JSON.stringify({ isSubtotalRow, nDimension, column, value }));
                        if ( ( arePrevSubtotsValuesChanged || isRecordValueChanged ) && !isAlreadySubtotal ) {
                            bDebug && arePrevSubtotsValuesChanged && console.info(`--9a. subtotali precedenti cambiati e non è già subtotale ibrido`);
                            bDebug && isRecordValueChanged        && console.info(`--9b. Valore cambiato e non è già subtotale ibrido`);
                            isToShow = true;
                        }
                    }
                }
                
                bDebug && console.info(`Fine: isToShow = ${isToShow}`);
                
                // bDebug && isSubtotalRow && Utils.logObject( '- col', { as1stSubTot, nSubtotalLevel, nDimension, sCellValue: oRecord[ column ] } );
                if ( isForExcel ) {
                    fillSheetCell({
                         nRow:           nExcelRow
                        ,nCol:           nExcelCol
                        ,value:          ( isGrandRow && ( nDimension === 0 ) ) ? 'GRAND TOTAL' : ( !isSubtotalRow ? value : ( isToShow ? value : '' ) )
                        ,filterDataType: filterDataType
                        ,aoStyles:       [
                            
                            // primo stile
                            ( isGrandRow     ? oStyles.leftBold   : oStyles.left )
                            
                            // secondo stile
                            , isGrandRow     ? oStyles.grandTotal :
                            (
                              !isSubtotalRow ? null :
                              isToShow       ? ( oStyles[ sClassSubTot ] || {} ) :
                              applicaColoreTestoComeSfondo( ( oStyles[ sClassSubTot ] || {} ) )
                            )
                        ]
                    });
                    nExcelCol++;
                }
                
                aBodyRigaIntestazioni.push(
                    <th
                        key             ={ 'bodyIntRiga' + nDimension + column + nSubtotalLevel + sSubtot }
                        className       ={
                            'dimension ' + column + classForDrillDown + ( ' nSubtotalLevel'+nSubtotalLevel+' ' )
                            // se è un subtotale oppure i subtotali sono abilitati ma per questa colonna sono disabilitati
                            + ( isSubtotalRow ? classSubTot   : '' )
                            + ( isToShow      ? ' showValue ' : '' )
                            + ' ' + sSubtotAddClass + ' '
                        }
                        data-column-name={ column }
                        onClick         ={ event => ( isSubtotalRow && isAfterSubtotCol ) ? undefined : ( bForDrillDown ? onClickDrillIcon( event, oRecord ) : undefined ) }
                        style           ={ oStyleFixedcolumnWidth(columnWidth) }
                        title           ={ ( isSubtotalRow && isAfterSubtotCol ) ? null : ( bForDrillDown ? sDrillTooltip : value ) }
                    >{ ( isSubtotalRow && isAfterSubtotCol ) ? null : value }</th>
                );
                
                if ( isToShow ) {
                    // serve per segnarsi che per questa colonna è già stato gestito un subtotale
                    const a = get_as1stSubTot();
                    a[ nDimension ] = column;
                    set_as1stSubTot([ ...a ].map( v => v || '' ) );
                    // Utils.logObject( 'get_as1stSubTot',get_as1stSubTot(), '', 30);
                }
                
            }
            
            return aBodyRigaIntestazioni;
            
        }
        
        // serve per ottenere le posizioni equidistanti tra di loro e tra gli estremi preimpostati compresi ( 1 e 26 )
        // degli n subtotali che devono essere rappresentati
        // Esempio: per un subtotale restituirà 13, per due subtotali restituirà 9 e 17
        const calcSubtotalStyle     = (nActualSubtotalLevel) => (
            Math.round( ( nActualSubtotalLevel + 1 ) * 26 / ( asDimSelectedColNames.length + 1 ) )
        );
        
        // crea le celle <td> con i dati nel CORPO CENTRALE A DESTRA
        const makeRow               = ({
            oRecord, oRecordPrec, oRow, asPrimaryKeys, isSubtotalRow = false, nSubtotal: nSubtotalLevel = 0, sSubtot, isGrandRow
        }) => {
            
            // es. oRecord: 
            // { "PROG_ID": 4 ,"STATUS": "POTENTIAL" ,"TYPE": "STANDARD"   ,"YEAR": 2022 ,"MONTH": 7 ,"LENGTH": 35443 }
            
            // es. oRow (pivot):
            // {
            //   "PROG_ID": 4 ,"STATUS": "POTENTIAL" ,"TYPE": "STANDARD"   ,"2022|||7": 
            //      { "PROG_ID": 4 ,"STATUS": "POTENTIAL" ,"TYPE": "STANDARD" ,"YEAR": 2022 ,"MONTH": 7 ,"LENGTH": 35443 }
            // }
            
            sSubtot                    = !isSubtotalRow ? '' : ( '_' + ( nSubtotalLevel + 1 ) );
            
            const
                 PROG_ID               = oRecord && oRecord['PROG_ID'] // es. PROG_ID = 1, 2, 3...
                ,isForPivot            = !!Object.keys( oRow || {} ).length
                ,get_oSubtotal         = ({ nLevel, sPivotValue }) => {
                    return oSubtotals[ get_sKeyCombSub(isPivotEnabled ? oRow : oRecord, nLevel, sPivotValue) ] || {}
                }
                ,bodyValori            = []
                
                ,classSubTot           = ( ' subtotal' + calcSubtotalStyle( nSubtotalLevel ) + ' ' )
                
            // CSX: in Centro a Sinistra
                
                // crea celle <th> per le intestazioni di riga (NEL CORPO CENTRALE A SINISTRA). ciclo sulle dimensioni (es. CHANNEL, ADVERTISER... )
                ,bodyIntRiga           = creaIntestazioniRiga({
                    PROG_ID, oRecord, oRecordPrec, isSubtotalRow, nSubtotalLevel, sSubtot, isGrandRow, classSubTot
                }) || []
                
                ,aoHeadCols            = aaoHeadGrid[ aaoHeadGrid.length - 1 ] || []
            ;
            
            let measureStart = '';
            
            // measureStart = ' measureStart'; // TODO posizionare questa riga all'effettivo inizio della misura
            
            // CDX: in Centro a Destra
            
            // ciclo per creare celle <td> del corpo della tabella (NEL CORPO CENTRALE A DESTRA)
            
            // per ogni colonna dell'ultima riga della griglia di intestazioni
            // in cui ogni elemento contiene le coordinate dei suoi elementi precedenti a parità di colonna 
            for ( let nCol = 0; nCol < aoHeadCols.length; nCol++ ) {
                
                let colDimensionStart       = ' colDimensionStart';
                
                let sFirsts = '';
                for ( let nActualRow = 0; nActualRow < aaoHeadGrid.length; nActualRow++ ) {
                    // stessa colonna righe precedenti
                    // creo una stringa nel formato '32' che indica quali bordi vanno visualizzati e quanto sono scuri (numero alto equivale a più scuro)
                    sFirsts += ( aaoHeadGrid?.[ nActualRow ]?.[ nCol ]?.isFirstPrevLv ? ( aaoHeadGrid.length - nActualRow ) : '' ) + '';
                }
                
                /* preso da sopra
                let sFirsts = '';
                // per ogni riga della griglia fino alla riga attuale compresa
                for ( let nActualRow = 0; nActualRow <= nRowHeadGrid; nActualRow++ ) {
                    // stessa colonna righe precedenti
                    // creo una stringa nel formato '32' che indica quali bordi vanno visualizzati e quanto sono scuri (numero alto equivale a più scuro)
                    sFirsts += ( aaoHeadGrid[ nActualRow ][ nColHeadGrid ]?.isFirstPrevLv ? ( aaoHeadGrid.length - nActualRow ) || '' : '' ) + '';
                }
                */
                const
                     { /* sColHierarchy, */ sPivotHierarchy, oColHierarchy, oColValues, /* isFirstCalc, isFirstMeasure */ } = aoHeadCols[ nCol ]
                    ,{ column, description /*, exclude, filterDataType, filterType, pSortingValue, tooltip */ } = oColValues
                    
                    ,sPivotHierarchyNext    = ( aoHeadCols[ nCol + nMeasureCalcs ] || {} ).sPivotHierarchy || ''
                    // attenzione: nelle due righe successive il controllo != null è la soluzione corretta (non un semplice truthy)
                    // perchè adesso ci sono delle chiavi '' (stringa vuota) che vengono usate per i totali di riga
                    ,oMeasuresRecord        = ( isPivotEnabled && ( sPivotHierarchy     != null ) ? oRow?.[ sPivotHierarchy     ] : oRecord ) || {} // potrebbe non esistere la combinazione
                    ,oMeasuresRecordNext    = ( isPivotEnabled && ( sPivotHierarchyNext != null ) ? oRow?.[ sPivotHierarchyNext ] : {}      ) || {} // potrebbe non esistere la combinazione
                    ,sMeasureColName        = oColHierarchy?.['MISURA'] || ''
                    ,{ decimals, summable } = oMeasures[ sMeasureColName ] || {}
                    
                    ,sNumberStyle           = 'number' + ( ( ( +decimals > -1 ) && ( +decimals < 3 ) ) ? decimals : 0 ) // valori accettati: 0 1 2
                    ,sFirstMeasureCalc      = ( nCol === 0 ) ? ' firstMeaCalc ' : ''
                    ,isRowTotal             = isPivotEnabled && ( nColHeadGrid === 0 ) && ( sPivotHierarchy === '' )
                    ,isLastElement          = nCol === ( aoHeadCols.length - 1 )
                 // ,nGrandRowValue         = isGrandRow      && ( ( isForPivot ? oRow?.[ sPivotHierarchy ] : oGrandTotal ) || {} )[ sMeasureColName ]
                    ,nSubtotalValue         = isSubtotalRow   && (
                        isPivotEnabled 
                        ? get_oSubtotal({ nLevel: nSubtotalLevel + 1, sPivotValue: sPivotHierarchy })[ sMeasureColName ]
                        : get_oSubtotal({ nLevel: nSubtotalLevel + 1 })[ sMeasureColName ]
                    )
                    ,nSubtotalValueNext     = isSubtotalRow   && (
                        isPivotEnabled
                        ? get_oSubtotal({ nLevel: nSubtotalLevel + 1, sPivotValue: sPivotHierarchyNext })[ sMeasureColName ]
                        : get_oSubtotal({ nLevel: nSubtotalLevel + 1 })[ sMeasureColName ]
                    )
                    
                    ,value                  = (
                          isSubtotalRow ? nSubtotalValue
                        :                 oMeasuresRecord?.[ sMeasureColName ]
                    ) || 0 //  potrebbe non esistere il valore
                    
                    ,valueNext              = ( isForPivot && (
                          isSubtotalRow ? nSubtotalValueNext
                        :                 oMeasuresRecordNext?.[ sMeasureColName ]
                    ) ) || 0 // potrebbe non esistere il valore
                    
                    // eslint-disable-next-line no-loop-func
                    ,getClassName           = (zeroOrNegVal) => (
                        'bodyValori measure ' + sMeasureColName + ' ' + nCol
                        + ( isSubtotalRow ? classSubTot : '' ) + ' ' + description + ' '
                        + ( oMeasureCalcs[column].cssClass || '' )
                        + zeroOrNegVal + measureStart + colDimensionStart + sFirstMeasureCalc
                        + ( sFirsts ? ( ' border-color' + sFirsts ) : '' )
                    )
                ;
                
                // console.info('--------------');
                // console.info('oRow (' + typeof oRow + ')', JSON.stringify(oRow || {}).slice(0,150));
                // console.info(JSON.stringify({ nGrandRowValue, isForPivot, sMeasureColName, sPivotHierarchy );
                // console.table([{ sPivotComb, column, description, decimals, summable  }]);
                // console.info('oColHierarchy',oColHierarchy);
                // console.info('oColValues',oColValues);
                // console.info('oRecord',oRecord);
                // console.info('oRow',oRow);
                // console.info('sMeasureColName',sMeasureColName);
                // console.info('sColHierarchy',sColHierarchy);
                // console.info('sColHierarchy','"'+getPrevLevelHierarchy(sColHierarchy)+'"');
                // console.info('oRecord[ '+getPrevLevelHierarchy(sColHierarchy)+' ]', oRecord && oRecord[ getPrevLevelHierarchy(sColHierarchy) ]);
                // console.info('oRow[ '+getPrevLevelHierarchy(sColHierarchy)+' ]', oRow && oRow[ getPrevLevelHierarchy(sColHierarchy) ]);
                
                if ( ( nCol > 0 ) ) { colDimensionStart = ''; measureStart = ''; }
                
                // #
                if (
                    ( column === '#' )
                    && (
                        // se è la colonna dei totali di riga e la misura è sommabile
                        ( ( summable === 'Y' ) && ( description === sRowTotalLabel ) )
                        // oppure se non è la colonna dei totali di riga
                        || ( description !== sRowTotalLabel )
                    )
                ) {

                    const
                         zeroOrNegVal   = zeroOrNeg(value)
                        ,className      = getClassName(zeroOrNegVal)
                        ,finalValue     = formatNumSmallDec( value, decimals )
                        ,style          = ( !isHeatEnabled || isGrandRow ) ? {} : { background: getBackgroundColor(value) }
                    ;
                    bodyValori.push( <td key={ className } className={ className } style={ style } >{ finalValue }</td> );
                    if ( isForExcel ) {
                        fillSheetCell({ 
                            nRow: nExcelRow ,nCol: nExcelCol ,value: value ,filterDataType: 'N', aoStyles: [
                                 oStyles[ sNumberStyle + ( isGrandRow ? 'GrandTotal' : '' ) ]
                                ,isSubtotalRow ? oStyles[ classSubTot.trim() ] : null
                            ]
                        });
                        nExcelCol++;
                    }

                // %
                } else 
                    if ( ( ['%','%C','%G'].includes(column)  ) && !isRowTotal ) {

                    const
                         nLevel         = sSubTotals ? ( aoDimensions.length - 1 ) : nSubtotalLevel
                     /* ,subTot         = (
                               isSubtotalRow ?      get_oSubtotal( { nLevel: nSubtotalLevel ,sPivotValue: sPivotHierarchy } )[ sMeasureColName ]
                             : ( oMeasuresRecord && get_oSubtotal( { nLevel                 ,sPivotValue: sPivotHierarchy } )[ sMeasureColName ] )
                         ) || 0 // potrebbe non esistere il valore
                     */ ,subTot         = (
                            ( column === '%G' ) ? ( oGrandTotal[ sMeasureColName ] ) :
                            ( column === '%C' ) ? ( get_oSubtotal( { nLevel: 0 ,sPivotValue: sPivotHierarchy } )[ sMeasureColName ] ) :
                            ( column === '%'  ) ? (
                                (
                                    isSubtotalRow ?      get_oSubtotal( { nLevel: nSubtotalLevel ,sPivotValue: sPivotHierarchy } )[ sMeasureColName ]
                                  : ( oMeasuresRecord && get_oSubtotal( { nLevel                 ,sPivotValue: sPivotHierarchy } )[ sMeasureColName ] )
                                ) || 0
                            ) : 0
                            
                        )
                        ,perc           = ( !isForPivot && isGrandRow ) ? 100 : ( subTot ? ( value * 100 / subTot ) : 0 )
                        ,zeroOrNegVal   = zeroOrNeg( perc )
                        ,className      = getClassName(zeroOrNegVal)
                        ,finalValue     = formatNumSmallDec( perc )
                        ,title          = formatNum( value,decimals ) + ' out of ' + formatNum(subTot,decimals)
                        ,style          = {}
                    ;
                    bodyValori.push(
                        <td key={ className } title={ title || '' } className={ className } style={ style }>{ finalValue }</td>
                    );
                    if ( isForExcel ) {
                        fillSheetCell({ 
                            nRow: nExcelRow ,nCol: nExcelCol ,value: perc ,filterDataType: 'N', aoStyles: [
                                 oStyles[ 'number2' + ( isGrandRow ? 'GrandTotal' : '' ) ]
                                ,isSubtotalRow ? oStyles[ classSubTot.trim() ] : null
                            ]
                        });
                        nExcelCol++;
                    }

                // Δ
                } else 
                    if ( ( column === 'Δ'  ) && !isRowTotal ) {

                    const
                         diff           = value - valueNext
                        ,zeroOrNegVal   = zeroOrNeg(diff)
                        ,className      = getClassName(zeroOrNegVal)
                        ,finalValue     = <>{( !zeroOrNegVal ? '+' : '' )}{ formatNumSmallDec( diff,  decimals, '=' )}</>
                        ,title          = formatNum( value, decimals ) + ' - ' + formatNum(valueNext,decimals)
                    ;
                    bodyValori.push(
                        <td
                            key         ={ className }
                            title       ={ isLastElement ? 'not calculable' : ( title || '' ) }
                            className   ={ className }
                        >{ isLastElement ? noValue : finalValue }</td>
                    );
                    if ( isForExcel ) {
                        fillSheetCell({
                            nRow: nExcelRow ,nCol: nExcelCol ,value: diff ,filterDataType: 'N', aoStyles: [
                                 oStyles[ sNumberStyle + ( isGrandRow ? 'GrandTotal' : '' ) ]
                                ,isSubtotalRow ? oStyles[ classSubTot.trim() ] : null
                            ]
                        });
                        nExcelCol++;
                    }

                // Δ%
                } else 
                    if ( ( column === 'Δ%' ) && !isRowTotal ) {

                    const
                         diffPerc       = ( ( value === valueNext ) || ( valueNext === 0 ) ) ? 0 : ( ( value * 100 / valueNext ) - 100 )
                        ,zeroOrNegVal   = zeroOrNeg(diffPerc)
                        ,className      = getClassName(zeroOrNegVal)
                        ,finalValue     = <>{( !zeroOrNegVal ? '+' : '' )}{ formatNumSmallDec( diffPerc, 2,'=' )}</>
                        ,title          = formatNum(value,decimals) + ' out of ' + formatNum(valueNext,decimals)
                    ;
                    bodyValori.push(
                        <td
                            key         ={ className }
                            title       ={ isLastElement ? 'not calculable' : ( title || '' ) }
                            className   ={ className }
                        >{ isLastElement ? noValue : finalValue }</td>
                    );
                    if ( isForExcel ) {
                        fillSheetCell({
                            nRow: nExcelRow ,nCol: nExcelCol ,value: diffPerc ,filterDataType: 'N', aoStyles: [
                                 oStyles[ 'number2' + ( isGrandRow ? 'GrandTotal' : '' ) ]
                                ,isSubtotalRow ? oStyles[ classSubTot.trim() ] : null
                            ]
                        });
                        nExcelCol++;
                    }

                }
                
            }
            
            const sSubTotClass          = !isSubtotalRow ? '' : (
                ' subtotal '
                + aoDimensions[nSubtotalLevel]?.column
                + ( ( aoDimensions[nSubtotalLevel]?.subtotal === 'Y' ) ? '' : ' hide ' ) // qui vengono gestiti i subtotali selettivi
            );
            
            return (
                <tr
                    key       ={ 'bodyRighe ' + ( isGrandRow ? ' grandTotal ' : '' ) + 'PI' + PROG_ID + ' PK' + asPrimaryKeys.join(',') + ' SL' + nSubtotalLevel + ' ST' + sSubtot + ' CS' + classSubTot }
                    className ={
                        isGrandRow ? ' grandTotal ' : 
                        ( ( !bTabularMode ? '' : ( ( nRowSelected === PROG_ID ) && !isSubtotalRow ? ' rowSelected ' : '' ) ) + sSubTotClass )
                    }
                    onClick   ={
                        () => {
                            if ( !isGrandRow && !isSubtotalRow && bTabularMode ) {
                                setnRowSelected( ( nRowSelected === PROG_ID ) ? '' : PROG_ID );
                            }
                        }
                    }
                >{ [ bodyIntRiga, bodyValori ] }</tr>
            );
        }
        
        const checkForSubtotals     = !!sSubTotals && ( ( aoQueryResultsFull.length -  nIndexPositiveRecords ) > 1 ) && ( aoDimensions.length > 1 );
        
        const asColsDaControllare   = []; // es. [ 'CHANNEL_DESC', 'ADVERTISER_NAME' ] 
        // per ogni dimensione meno l'ultima
        for ( let nDim = 0; nDim < aoDimensions.length - 1;  nDim++ ) {
            asColsDaControllare.push( aoDimensions[nDim].column );
        }
        
        const maxSubTotals          = asColsDaControllare.length;
        const nStart                = ( sSubTotals === 'bottom' ) ? maxSubTotals : 0;
        const nEnd                  = ( sSubTotals === 'bottom' ) ? 0            : maxSubTotals;
        const nMult                 = ( sSubTotals === 'bottom' ) ? -1           : 1;
        
        // questo array serve per segnarsi di quali colonne è già stato visualizzato il subtotale ibrido
        let as1stSubTot             = [];
        const get_as1stSubTot       = () => { return as1stSubTot; };
        const set_as1stSubTot       = ( as ) => { as1stSubTot = as; };
        
        let aoRowsPivoted           = [ oSubtotals ]; // il primo record è sempre quello del GRAND TOTAL (oSubtotals nel caso pivot)
        let oQueryRecordTOT         = {};
        
        if ( isPivotEnabled ) {
            
            // creo un nuovo recordset in cui aggiungo colonne fittizie che sono la combinazione di pivot e misure
            
            // per ogni RECORD (con PROG_ID positivo)
            for ( let nQueryRow =  nIndexPositiveRecords; nQueryRow < aoQueryResultsFull.length;  nQueryRow++ ) {
                const oQueryRecord = aoQueryResultsFull[ nQueryRow ];
                // es. oQueryRecord: { "PROG_ID": 4 ,"STATUS": "POTENTIAL" ,"TYPE": "STANDARD" ,"YEAR": 2022 ,"MONTH": 7 ,"LENGTH": 35443 }
                
                if ( oQueryRecord.PROG_ID !== oQueryRecordTOT.PROG_ID ) {
                    oQueryRecordTOT = {};
                }
                
                // in caso di paginazione, sottraggo al PROG_ID il numero di record totali di tutte le pagine precedenti
                const progID = oQueryRecord['PROG_ID'] - nRecordsBefore;
                
                // record con campi fittizi che rappresentano la combinazione di valori delle dimensioni pivot
                // es. campo '2023|||7' conterrà un oggetto che è il record originale
                
                // inserisco subito il recordPivoted nel suo array usando il PROG_ID come indice
                // e poi finisco di riempirlo per riferimento
                if ( !aoRowsPivoted[ progID ] ) {
                      aoRowsPivoted[ progID ] = {};
                }
                
                // inizializzo l'oggetto con i soli campi di intestazione di riga (le dimensioni non pivot)
                for ( let nCampo = 0; nCampo < asCampiRecordPerIntest.length; nCampo++) {
                    const sCampo = asCampiRecordPerIntest[ nCampo ];
                    aoRowsPivoted[ progID ][ sCampo ] = oQueryRecord[ sCampo ];
                }
                
                // nel for sottostante creo sPivotComb, la combinazione di valori delle dimensioni pivot ('2023|||7')
                let sPivotComb = '';
                let sTempRowTotalLabel = '';
                const asPivotColNamesLength = asPivotColNames.length;
                
                // per ogni dimensione PIVOT
                for ( let nPivot = 0; nPivot < asPivotColNamesLength; nPivot++ ) {
                    const sPivotColName = asPivotColNames[ nPivot ]; // es. 'YEAR'
                    
                    let sPivotValue     = oQueryRecord[ sPivotColName ] ; // Es. '2023'
                    sPivotValue         = ( ( sPivotValue === 0 ) ? 0 : ( sPivotValue || '' ) ) + '';
                    
                    sPivotComb          += ( sPivotComb ? '|||' : '' ) + sPivotValue; // es. '2023|||7'
                    
                }
                
                // se sono abilitati i totali di riga, aggiungo in aoRowsPivoted una colonna in più di dati
                if ( bRowTotal ) {
                    
                    if ( !aoRowsPivoted[ progID ][ sTempRowTotalLabel ] ) {
                          aoRowsPivoted[ progID ][ sTempRowTotalLabel ] = {};
                    }
                    
                    // per ogni MISURA
                    for ( let nKey = 0; nKey < asMeasuresColNames.length; nKey++ ) {
                        const sMeasureCol = asMeasuresColNames[nKey];
                        // copio in un altro oggetto (oQueryRecordTOT) le chiavi (misure) e sommo i relativi valori (per il totale di riga)
                        oQueryRecordTOT[ sMeasureCol ] = ( oQueryRecordTOT[ sMeasureCol ] || 0 ) + oQueryRecord[ sMeasureCol ];
                    }
                    
                    oQueryRecordTOT.PROG_ID = oQueryRecord.PROG_ID;
                    
                    // es. aoRowsPivoted[ 1 ] [ '' ] = { "PROG_ID": 4 ,"STATUS": ... ,"YEAR": 2022 ... ,"LENGTH": 35443 }
                    aoRowsPivoted[ progID ][ sTempRowTotalLabel ] = { ...oQueryRecordTOT };
                    
                }
                
                // aggiungo al record il campo pivot fittizio (es. '2023|||7' valori rispettivi di REVENUE_YEAR e REVENUE_MONTH)
                // e gli associo il record originale, che contiene tutte le misure (e i rispettivi valori)
                // es. aoRowsPivoted[ 1 ] [ '2023|||7' ] = { "PROG_ID": 4 ,"STATUS": "POTENTIAL" ,"TYPE": "STANDARD" ,"YEAR": 2022 ,"MONTH": 7 ,"LENGTH": 35443 }
                aoRowsPivoted[ progID ][ sPivotComb ] = oQueryRecord; // conviene l'intero record (e non solo misure)
                
            }
            
            /* esempio dati pivottati (aoRowsPivoted):
                [
                    // la posizione all'interno dell'array corrisponde al PROG_ID (riga)
                    ...
                    ,{
                        "PROG_ID"               : 2,
                        "ADVERTISER_STATUS"     : "LOCKED",
                        "CONTRACT_TYPE"         : "BASKET",
        
                        // questa è la chiave che corrisponde al totale di riga
                        "": {
                            "PROG_ID"           : 2,
                            "SPOT_LENGTH"       : 18395,
                            "GROSS"             : 162760
                        },
                        
                        "2023|||2": {
                        
                            "PROG_ID"           : 2,            // riga
                            
                            "ADVERTISER_STATUS" : "-",          // dimensioni selezionate
                            "CONTRACT_TYPE"     : "STANDARD",   // dimensioni selezionate
                            
                            "REVENUE_YEAR"      : 2023,         // dimensioni pivot
                            "REVENUE_MONTH"     : 2,            // dimensioni pivot
                            
                            "SPOT_LENGTH"       : 12365,        // misure
                            "GROSS"             : 76987         // misure
                            
                        },
    
                        "2023|||7": {
                            "PROG_ID"           : 2,
                            "ADVERTISER_STATUS" : "-",
                            "CONTRACT_TYPE"     : "STANDARD",
                            "REVENUE_YEAR"      : 2023,
                            "REVENUE_MONTH"     : 7,
                            "SPOT_LENGTH"       : 6030,
                            "GROSS"             : 85773
                        }

                    }
                ]
            */
            
            // console.info('-----------------');
            // console.info('aoRowsPivoted');
            // console.info(Utils.logAAO([ aoRowsPivoted ]));
            
        }
        
        const asPrimaryKeys         = bInvertPivot ? asPivotColNames : ['PROG_ID'];
        
        let oRecordPrec             = null;
        
        let aoFinalRows             = isPivotEnabled ? aoRowsPivoted : aoQueryResultsFull;
        
        // console.info('-----------------');
        // console.info('aoFinalRows');
        // console.table(aoFinalRows);
        
        // per le righe prima si cicla sulla combinazione di dimensioni, cioè PROG_ID, nella struttura oRows
        for ( let nRow = 0; nRow < aoFinalRows.length; nRow++ ) {
            
            nExcelCol = 1;  // Riparto dalla prima colonna all'inizio di ogni nuova riga
            
            if ( !isPivotEnabled && ( nRow > 0 ) && ( nRow < nIndexPositiveRecords ) ) {
                continue;
            }
            const oRecord    = aoFinalRows[ nRow ];
            
            // es. oRecord (non pivot): 
            // { "PROG_ID": 4 ,"STATUS": "POTENTIAL" ,"TYPE": "STANDARD"   ,"YEAR": 2022 ,"MONTH": 7 ,"LENGTH": 35443 }
            
            // es. oRecord (pivot):
            // {
            //   "PROG_ID": 4 ,"STATUS": "POTENTIAL" ,"TYPE": "STANDARD"   ,"2022|||7": 
            //      { "PROG_ID": 4 ,"STATUS": "POTENTIAL" ,"TYPE": "STANDARD" ,"YEAR": 2022 ,"MONTH": 7 ,"LENGTH": 35443 }
            // }
            
            const isGrandRow = ( nRow === 0 );
            
            set_as1stSubTot([]); // reset ad ogni record
            
            const oRow       = aoRowsPivoted[ isGrandRow ? 0 : ( +oRecord.PROG_ID - nRecordsBefore ) ];
            
            // --- DATI ---
            if ( ( sSubTotals !== 'top' ) && ( !isGrandRow || ( isGrandRow && bGrandTotal ) ) ) { // se non sono attivi i subtotali (o se devono essere visualizzati in basso)
                bodyRighe.push( makeRow({
                    oRecord, oRecordPrec, oRow, asPrimaryKeys, isGrandRow
                }) );
                nExcelRow++;    // Nuova riga
            }
            
            // --- SUBTOTALI ---
            if ( !isGrandRow && checkForSubtotals ) { // se sono attivi i subtotali
                
                // 0) ciclo per i subtotali
                //    devo generare una riga per il valore del record e tante righe per i subtotali quante sono le dimensioni, meno una (l'ultima)
                for ( let nSubtotal = nStart; nSubtotal < nEnd; nSubtotal = nSubtotal + nMult ) {
                    
                    // controllo se i valori di tutte le dimensioni (tranne l'ultima) sono uguali a quelli del record precedente
                    let areDimensionsDifferent = false;
                    const asCols = asColsDaControllare.slice( 0, ( nSubtotal + 1 ) * nMult );
                    for ( const sColDaControllare of asCols ) {
                        if ( oRecord[sColDaControllare] !== oRecordPrec?.[sColDaControllare] ) {
                            areDimensionsDifferent = true;
                            break;
                        }
                    }
                    
                    // vuol dire che l'attuale record ha un subtotale diverso dal precedente
                    if ( ( aoDimensions[ nSubtotal ].subtotal === 'Y' ) && ( !oRecordPrec || areDimensionsDifferent ) ) {
                        
                        // bDebug && Utils.logObject( 'row ----', {
                        //     as1stSubTot, nSubtotal, sSubDesc: asColsDaControllare[nSubtotal], sCellValue: oRecord[ asColsDaControllare[nSubtotal] ]
                        // } );
                        
                        bodyRighe.push( makeRow({
                            oRecord, oRecordPrec, oRow, asPrimaryKeys, isSubtotalRow: true, nSubtotal
                        }) );
                        nExcelCol = 1; // reset per i subtotali
                        nExcelRow++;    // Nuova riga
                        
                    }
                    
                }
                
            }
            
            // --- DATI ---
            if ( ( sSubTotals === 'top' ) && ( !isGrandRow || ( isGrandRow && bGrandTotal ) ) ) {  // se sono attivi i subtotali (e devono essere visualizzati in alto)
                bodyRighe.push( makeRow({
                    oRecord, oRecordPrec, oRow, asPrimaryKeys, isGrandRow
                }) );
                nExcelRow++;    // Nuova riga
            }
            
            oRecordPrec     = { ...oRecord }; // per record si intende un record con PROG_ID diverso
            
        }
        
        bDebugTimers && console.info('nTimerCreateTableElement', ( ( performance.now() - nTimerCreateTableElement ) / 1000 ).toFixed(3) + 's' );
        
        // ----- EXCEL - SHEET FILTERS - INIZIO ----- //
        
        if ( isForExcel ) {
            
            // Aggiorna il range del foglio
            let range = XLSX.utils.decode_range(wsCube['!ref'] || 'A1');
            range.e.r = Math.max(range.e.r, nLastRowUsed - 1);
            range.e.c = Math.max(range.e.c, nLastColUsed - 1);
            wsCube['!ref'] = XLSX.utils.encode_range(range);
            
            
            // il contenuto di questo foglio è in aoFiltersRowsOptions
            
            nLastRowUsed = 0;
            nLastColUsed = 0;
            
            nCol = 1;
            nRow = 1;
            const sFirstRow = 'File created on ' + moment().format('YYYY/MM/DD HH:mm:ss')
                + ' (last rebuild of cube ' + sCubeDesc + ' : '
                + moment( sCubeDateTime, 'YYYYMMDDHHmmss' ).format('YYYY/MM/DD HH:mm:ss') + ')';
            fillSheetCell({
                ws: wsFilters, nRow, nCol, value: sFirstRow
            });
            
            nCol = 0;
            nRow += 1;
            
            // intestazioni
            [ 'Option', 'Value', 'Selected', 'Subtotal', 'Sorting', 'Drill down', 'Include', 'Filters' ]
                .forEach( sKey => {
                        nCol += 1;
                        fillSheetCell({
                            ws: wsFilters, nRow, nCol, value: sKey, aoStyles: [ oStyles.textBold ], bForceString: true
                        });
                    }
                );
            
            // const aoAll = [ ...aoAllDimensions, ...aoAllMeasures ];
            
            // Impostazione larghezze colonne
            wsFilters['!cols'] = [
                { wch: 25 }, // Option
                { wch: 25 }, // Value
                { wch: 10 }, // Selected
                { wch: 10 }, // Subtotal
                { wch: 10 }, // Sorting
                { wch: 10 }, // Include
            ];
            
            // RIMOSSO TEMPORANEAMENTE wsFilters.row(2).freeze(2);
            
            // contenuto
            aoFiltersRowsOptions.forEach( ( oFiltersRowsOptions ) => {
                
                nCol = 0;
                nRow += 1;
                //           'Option', 'Value', 'Selected', 'Subtotal', 'Sorting', 'Drill down', 'Include', 'Filters'
                [  'column', 'label',  'value', 'selected', 'subtotal', 'sort',    'drill',      'exclude', 'filters', 'asCurrentFilters' ]
                    .forEach( sKey => {
                            let value = oFiltersRowsOptions[sKey];
                            value     = ( value === 0 ) ? 0 : ( value || '' );
                            if ( ![ 'column', 'asCurrentFilters' ].includes(sKey) ) {
                                nCol += 1;
                                fillSheetCell({
                                    ws: wsFilters, nRow, nCol, value, bForceString: true
                                });
                            }
                        }
                    );
                
            });
            
            // Aggiorna il range del foglio
            range = XLSX.utils.decode_range(wsFilters['!ref'] || 'A1');
            range.e.r = Math.max(range.e.r, nLastRowUsed - 1);
            range.e.c = Math.max(range.e.c, nLastColUsed - 1);
            wsFilters['!ref'] = XLSX.utils.encode_range(range);
            
            
            /* --------- FOGLIO PER PROVE --------
            const wsA1 = XLSX.utils.aoa_to_sheet([['']]);
            const cellA1 = {
                v: 'Testo in grassetto', // valore
                t: 's', // tipo: stringa
                s: {
                    font: {
                        bold: true
                    }
                }
            };
            wsA1['A1'] = cellA1;
            wsA1['!ref'] = 'A1:A1';
            XLSX.utils.book_append_sheet(wb, wsA1, 'FoglioA1');
            // --------- FOGLIO PER PROVE -------- */
            
            bDebug && console.info( 'excel file creation ended' );
            
            return XLSX.write( wb, { type: 'buffer', bookType: 'xlsx' } );
            
        }
        
        // ----- EXCEL - SHEET FILTERS - FINE   ----- //
        
        return <table id="cubeTable" className={
                                ' cubeTable'               +
            ( bColsLocked     ? ' colsLocked'       : '' ) +
            ( bTabularMode    ? ' tabularMode'      : '' ) +
            ( sSubTotals      ? ' subtotalsEnabled' : '' ) +
            ( isPivotEnabled  ? ' pivotEnabled'     : '' ) +
            ( bShowAllValues  ? ' showAllValues'    : '' )
        }>
            <thead>{ aHeads    }</thead>
            <tbody>{ bodyRighe }</tbody>
        </table>;
    }
    
    // callback necessaria
    const callbackTableElement  = useCallback( createTableElement, [
        aoQueryResultsFull, aoAllDimensions, aoAllMeasures, anMeasureColumns,
        bTabularMode, bInvertPivot, bRowTotal, bColsLocked,
        sSubTotals, bShowAllValues,
        nRowSelected, drillDown, isHeatEnabled, nRecordsBefore, refreshCubeBuilderState
    ] );
    
    // Esponiamo createTableElement direttamente per essere richiamabile da CubeGrid
    useEffect(() => {
        if (ref) {
            ref.current = { createTableElement: callbackTableElement };
        }
    }, [ ref, callbackTableElement ]);
    
    useEffect(() => {
        setTableElement( createTableElement() );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ nRowSelected, bShowAllValues, bInvertPivot, bRowTotal, anMeasureColumns, aoAllMeasures, bColsLocked ]);
    /* verrà aggiornata la tabella:
         ad ogni cambio di nRowSelected (quindi ad ogni click su riga) (incluso al primo caricamento del componente)
         ad ogni cambio di bShowAllValues
         ad ogni cambio di anMeasureColumns
         inutile aggiungere altre condizioni di caricamento in quanto sono già state messe nel componente padre (CubeGrid):
         cioè bShowTable && aoQueryResults && aoQueryResults[0]
         (quindi in caso di tabular, pivot e subtotals cambiano i risultati della query e di conseguenza la tabella si aggiorna 
    */
    
    useEffect(() => {
        if ( bColsLocked ) {
            set_anColWidths( colsRefs.current.map( ref => ref?.offsetWidth || 0 ) );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ tableElement ]);
    
    useEffect(() => {
        if ( bColsLocked ) {
            for ( let index = 0; index < anColWidths.length; index++ ){
                document.documentElement.style.setProperty(
                    `--Col${((index+1)+'').padStart(2,'00')}`, `${ anColWidths[index] || 0 }px`
                );
                Utils.addCSSRulesToDOM( Utils.generateLeftRulesForSticky( anColWidths.length ), 'stickyRules' )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ anColWidths ]);
    
    return tableElement;
    
});
