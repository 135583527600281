import Utils from '../../../common/CommonUtilities';

export default class CubeCommons {

    static getAoFiltersRowsOptions ({ oLayout, aoDimensions, aoMeasures ,withFLAG_SELECTED }) {

        const mapLabel          = ( sLabel ) => (
            {
                 bShowAllValues  : 'Show all values'
                ,bTabularMode    : 'Tabular mode'
                ,bInvertPivot    : 'Pivot headers inverted'
                ,bRowTotal       : 'Row Totals'
                ,bColsLocked     : 'Dimensions pinned'
                ,sSubTotals      : 'Subtotals'
                ,aoDimensions    : 'Dimension'
                ,aoMeasures      : 'Measure'
                ,selected        : 'Selected'
                ,pivoted         : 'Pivoted'
                ,sortDirection   : 'Sorting direction'
                ,drillDownValue  : 'Drill down'
                ,exclude         : 'Exclude'
            }[sLabel] || sLabel
        );

        const mapValue          = value => {
            const type = typeof value;
            return ( [ 'string', 'number' ].includes(type) ? value
                     : ( ( type === 'boolean' )            ? ( value ? 'Enabled' : 'Disabled' ) : '' ) );
        };

        const mappingLabelValue = ( sKey, value, selected, sort, drill, exclude, filters = [], asCurrentFilters, subtotal, column ) => {
            let o = {
                 column
                ,label: mapLabel(sKey)
                ,value: mapValue(value)
            };
            const sFilterDataType = ( aoDimensions.find( oDim => oDim.column === column ) || {} ).filterDataType;
            if ( sFilterDataType === 'M' ) {
                drill = Utils.mapMonths(drill);
            }
            if ( sFilterDataType === 'S' ) { drill = 'H' + drill; }
            if ( sFilterDataType === 'T' ) { drill = 'Q' + drill; }
            if ( selected || filters.length > 0 ) {
                o = {
                     ...o
                    ,selected: selected ? 'Selected' : ''
                    ,sort:     ( sort === 'DESC' ) ? 'Descending' : ( ( sort === 'ASC' ) ? 'Ascending' : '' )
                    ,drill:    ( !oLayout.oStructure.bTabularMode && [ 'string', 'number' ].includes( typeof drill ) ) ? drill : '' // numero o stringa
                    ,exclude:  ( filters.length === 0 ) ? '' : ( exclude === true ? 'Exclude' : 'Include' )
                    ,subtotal: ( subtotal === 'Y' ) ? 'Subtotal' : ''
                    ,filters
                    ,asCurrentFilters
                }
            }
            return o;
        };

        const getDescription    = ( isDimension, column ) => ( (
            isDimension
                ? aoDimensions.find( oDim => oDim.column === column )
                : aoMeasures.find(   oMea => oMea.column === column )
        ) || {} ).description || '';

        const convertDataType   = ( value, column ) => {
            return Utils.convertDataType( value,  ( aoDimensions.find( oDim => oDim.column === column ) || {} ).filterDataType )
        };

        const { oStructure } = oLayout;
        if ( !oStructure ) { return []; }
        const aoFiltersRows  = [];
        const asCalculations = [ '# (Value)', '% (Percentage)',  'Δ (Delta)',  'Δ% (Delta percentage)' ];
        const isOneMeasureOrdered = oStructure.aoMeasures && oStructure.aoMeasures.find( oMeasure => oMeasure.sortDirection );

        Object.keys(oStructure).forEach( sPropertyKey => {

            const isDimension    = ( sPropertyKey === 'aoDimensions' );
            const vPropertyValue = oStructure[sPropertyKey];
            // selected ,pivoted ,asCurrentFilters ,sortDirection ,drillDownValue ,pSortingValue

            if ( sPropertyKey === 'sSubTotals' ) {

                aoFiltersRows.push( mappingLabelValue( sPropertyKey, !!vPropertyValue ) );

            } else if ( sPropertyKey === 'anMeasureColumns' ) {

                vPropertyValue.forEach( ( nMeasureColumn ) => {
                    aoFiltersRows.push(mappingLabelValue( 'Measure Calculation', asCalculations[+nMeasureColumn]));
                });

            } else if ( [ 'aoDimensions', 'aoMeasures' ].includes(sPropertyKey) ) {
                
                vPropertyValue.forEach( ( oDimensionOrMeasure ) => {
                    
                    const { column, selected, subtotal, pivoted, pSortingValue, sortDirection, drillDownValue, exclude, asCurrentFilters = [] } = oDimensionOrMeasure;
                    
                    const filterType  = (
                           aoDimensions.find( o => o.column === oDimensionOrMeasure.column )
                        || aoMeasures.find(   o => o.column === oDimensionOrMeasure.column )
                        || {}
                    ).filterType || '';
                    const description = getDescription( isDimension, column );
                    
                    // Determina la direzione di ordinamento per la dimensione o misura corrente
                    const sort = 
                        ( isDimension && selected )
                            // se è una dimensione ed è selezionata
                            ? (
                                ( isOneMeasureOrdered && !pivoted )
                                ? '' // Se una misura è ordinata e la dimensione non è pivotata, non si applica alcun ordinamento
                                : ( !sortDirection ? 'ASC' : sortDirection ) // Altrimenti, ordinamento ascendente come default o la sortDirection
                            )
                            // Se è una misura o una dimensione non selezionata
                            : ( pSortingValue
                                ? '' // Per le misure con un valore di ordinamento pivot, non si applica un ordinamento diretto
                                : sortDirection // Per le misure senza ordinamento pivot, si usa la sortDirection
                            )
                    ;
                    
                    if ( !pivoted && ( selected || asCurrentFilters.length > 0 ) ) {
                        const oFilterRowElement = mappingLabelValue(
                             sPropertyKey
                            ,description
                            ,selected
                            ,sort
                            ,drillDownValue
                            ,exclude
                            ,(
                                ( filterType === 'R' )
                                ? (
                                    ( Utils.isTruthyOrZero( asCurrentFilters[0] ) ? ( 'From: ' + convertDataType( asCurrentFilters[0], column ) ) : '' ) +
                                    ( Utils.isTruthyOrZero( asCurrentFilters[0] ) && Utils.isTruthyOrZero( asCurrentFilters[1] ) ? ' ' : '' ) +
                                    ( Utils.isTruthyOrZero( asCurrentFilters[1] ) ? ( 'To: '   + convertDataType( asCurrentFilters[1], column ) ) : '' )
                                )
                                : asCurrentFilters.map( s => convertDataType( s, column ) ).join(' , ')
                            )
                            ,asCurrentFilters
                            ,subtotal
                            ,column
                        );
                        if ( withFLAG_SELECTED && asCurrentFilters.length ) { oFilterRowElement.FLAG_SELECTED = 'N'; }
                        aoFiltersRows.push( oFilterRowElement );
                    }
                    
                    if ( pivoted ) {
                        const description       = getDescription( isDimension, column );
                        const oFilterRowElement = mappingLabelValue(
                            'Dimension Pivoted'
                            ,description
                            ,selected || pivoted
                            ,( !sortDirection ? 'ASC' : sortDirection )
                            ,drillDownValue
                            ,exclude
                            ,asCurrentFilters.map( s => convertDataType( s, column ) ).join(' , ')
                            ,asCurrentFilters
                            ,subtotal
                            ,column
                        );
                        if ( withFLAG_SELECTED && asCurrentFilters.length ) { oFilterRowElement.FLAG_SELECTED = 'N'; }
                        aoFiltersRows.push( oFilterRowElement );
                    }
                    
                    if ( pSortingValue ) {
                        
                        const aoPivots          = aoDimensions.filter( o => o.pivoted );
                        const asSortingValues   = ( ( typeof pSortingValue === 'string' ) ? pSortingValue : ''  ).split('|||').filter(v=>v);
                        let sSortValues         = [];
                        let sSortString         = '';
                        
                        for ( let i = 0; i < aoPivots.length; i++ ) {
                            sSortValues[i]      = convertDataType( asSortingValues[i], aoPivots[i]?.column );
                            sSortString         += ( ( i !== 0 ) ? '\n' : '' ) + aoPivots[i]?.description + ':\n  ' + sSortValues[i];
                        }
                        
                        const oFilterRowElement = mappingLabelValue(
                            'Measure Sorting',
                            'Measure:\n  ' + description + '\n' + sSortString,
                            true,
                            sortDirection
                        );
                        
                        if ( withFLAG_SELECTED && asCurrentFilters.length ) { oFilterRowElement.FLAG_SELECTED = 'N'; }
                        aoFiltersRows.push( oFilterRowElement );
                        
                    }
                    
                });

            } else {

                aoFiltersRows.push( mappingLabelValue( sPropertyKey, vPropertyValue ) );

            }

        });
        
        return Utils.sortBy( aoFiltersRows, 'label');

    };

}
