import React, { useState, useRef, useEffect, useMemo } from 'react';
import                  './Login.css';
import {
     Redirect
    ,useLocation
}                       from 'react-router-dom';
import {
     MuiThemeProvider
    ,createTheme
}                       from '@material-ui/core/styles';
import FormControl      from '@material-ui/core/FormControl';
import TextField        from '@material-ui/core/TextField';
import Input            from '@material-ui/core/Input';
import InputAdornment   from '@material-ui/core/InputAdornment';
import IconButton       from '@material-ui/core/IconButton';
import Button           from '@material-ui/core/Button';
import Snackbar         from '@material-ui/core/Snackbar';

import MuiAlert         from '@material-ui/lab/Alert';

import GppGoodIcon      from '@material-ui/icons/Beenhere';
import Visibility       from '@material-ui/icons/Visibility';
import VisibilityOff    from '@material-ui/icons/VisibilityOff';
import AccountCircle    from '@material-ui/icons/AccountCircle';
import VpnKey           from '@material-ui/icons/VpnKey';
import moment           from 'moment';

import { useAuth }      from '../../context/auth';
import FetchService     from '../../common/FetchService';
import config           from '../../config';
import appinfo          from '../../appinfo.js';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

function Login() {
    const token = ( useQuery().get('token') || '' );
    
    const
         [ showPassword    , setShowPassword   ] = useState(false)
        ,[ isLoggedIn      , setLoggedIn       ] = useState(false)
        
        ,[ appID           , _setAppID         ] = useState('')
        ,appIDRef = useRef(appID)
        ,setAppID = sNewAppId => {
            appIDRef.current = config.ENVIRONMENTS[ sNewAppId ] ? sNewAppId : ( process.env.REACT_APP_ENV || 'no_valid_appid' )
            _setAppID(sNewAppId);
        }
        
        ,[ username        , _setUsername      ] = useState('')
        ,usernameRef = useRef(username)
        ,setUsername = data => {
            usernameRef.current = data.trim();
            _setUsername(data.trim());
        }
        
        ,[ password        , _setPassword      ] = useState('')
        ,passwordRef = useRef(password)
        ,setPassword = data => {
            passwordRef.current = data;
            _setPassword(data);
        }
        
        ,{ setAuthTokens } = useAuth()
        ,[ sErrorMessage   , set_sErrorMessage ] = useState('')
        ,[ customer        , setCustomer       ] = useState('')
        ,[ edition         , setEdition        ] = useState('')
    ;
    
    useEffect(() => {
        
        function onKeyUp(e) {
            if(e.key === 'Enter'){
                postLogin();
            }
        }
        window.addEventListener('keyup', onKeyUp);
        
        console.info(appinfo.NAME + ' version: ' + appinfo.VERSION
            + ' | Environment: ' + ( process.env.REACT_APP_ENV || 'undefined' )
            + ( localStorage.getItem('appid') ? ( ' | AppID: ' + localStorage.getItem('appid') ) : '' )
        );
        
        if ( token ) {
            postLogin(token);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const theme = createTheme({
        overrides: { MuiTooltip: { tooltip: { fontSize: '0.9em' } } },
        palette: {
            primary: {
                main: '#509556',
            },
            secondary: {
                main: 'rgb(33, 33, 33)'
            }
        }
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        set_sErrorMessage('');
    };

    async function postLogin(token) {

        const
             username = usernameRef.current
            ,password = passwordRef.current
            ,appid    =    appIDRef.current
        ;

        if ( !token && ( !username || !password ) ) {
            set_sErrorMessage('Invalid username or password');
            return;
        }
        
        try {
            
            const aoResults = await FetchService.asyncGet({
                 url:                 '/get-general-options'
                ,displayErrorHandler: set_sErrorMessage
            });
            
            if ( aoResults ) {
                
                const oGeneralOptions = ( aoResults && aoResults[0] ) || {};
                const sClienteDesc    = oGeneralOptions['CLIENTE_DESC'].trim() || {
                     1:  'Cairo'
                    ,7:  'Discovery'
                    ,9:  'Condé Nast'
                    ,10: 'Sole 24 Ore'
                    ,12: 'IGP'
                }[ oGeneralOptions['CLIENTE_COD'] ];
                localStorage.setItem( 'CLIENTE_COD'             ,oGeneralOptions['CLIENTE_COD']             || ''    );
                localStorage.setItem( 'CLIENTE_DESC'            ,sClienteDesc                               || ''    );
                localStorage.setItem( 'PASSWORD_MIN_LENGTH'     ,oGeneralOptions['PASSWORD_MIN_LENGTH']     || 0     );
                localStorage.setItem( 'FLAG_UPDATE_USERS'       ,oGeneralOptions['FLAG_UPDATE_USERS']       || 'N'   );
                localStorage.setItem( 'FLAG_PORTFOLIO_REBUILD'  ,oGeneralOptions['FLAG_PORTFOLIO_REBUILD']  || 'N'   );
                localStorage.setItem( 'DATABASE_SERVER'         ,oGeneralOptions['DATABASE_SERVER']         || 'NONE');
                localStorage.setItem( 'LAMBDA_ENABLED'          ,oGeneralOptions['LAMBDA_ENABLED']          || 'N'   );
                localStorage.setItem( 'MAX_NUM_PIVOT'           ,oGeneralOptions['MAX_NUM_PIVOT']           || 2     );
                
                console.info('Customer: (' + oGeneralOptions['CLIENTE_COD'] + ') ' + sClienteDesc );
                setCustomer('(' + localStorage.getItem('CLIENTE_COD') + ') ' + localStorage.getItem('CLIENTE_DESC') );
                setEdition(oGeneralOptions['FLAG_EDITION']);
                
            }
        
            localStorage.setItem('appid', appid);
            const data = await FetchService.asyncPost({
                 url:    '/login'
                ,params: { appid, username, password, token }
                ,displayErrorHandler: set_sErrorMessage
            });
            
            if ( data && data.success ) {
                localStorage.setItem( 'originalKUser', data.kUser );
                setAuthTokens(data);
                setLoggedIn(true);
            } else {
                setLoggedIn(false);
            }
            
        } catch (e) {
            console.error(e);
        }

    }

    if (isLoggedIn) {
        return <Redirect to="/dashboard" />;
    }else {

        const env = ( localStorage.getItem('appid') || process.env.REACT_APP_ENV || 'undefined' );
        const DATABASE_SERVER = localStorage.getItem('DATABASE_SERVER') || '';
        const [ ver, year, month, day ] = ( appinfo.VERSION || '' ).split('-').filter(v=>v);

        return (
            <div className="login">

                { sErrorMessage &&
                    <Snackbar
                        open                ={!!sErrorMessage}
                        autoHideDuration    ={6000}
                        onClose             ={handleClose}
                        anchorOrigin        ={{
                             vertical:   'top'
                            ,horizontal: 'right'
                        }}
                    >
                        <Alert onClose={handleClose} severity="error">
                            { sErrorMessage || <span>Invalid credentials or user not enabled</span> }
                        </Alert>
                    </Snackbar>
                }
    
                { !token &&
                <div className="login-outer-container">
                    <MuiThemeProvider theme={theme}>
    
                        <div className="background-with-logo"></div>
                        <div className="background-with-logo-top-dx"></div>
                        <div className="background-with-logo-top-dx-bis"></div>
                        <div className="background-with-logo-bot-dx"></div>
                        <div className="background-with-logo-bot-dx-bis"></div>
    
                        <div id="login-form" className="login-container">
    
                            <div className="side left login-content">
        
                                <div className="header-product-name top-bottom-section">
                                    <img
                                        draggable   ="false"
                                        src         ="/roobeek_by_systrategy_logo.svg"
                                        className   ="roobeek-logo"
                                        alt         ="Roobeek®"
                                    />
                                </div>
    
                                <div className="user-psw-container">
                                    
                                    { // indicare qui gli ambienti in cui usare appid
                                        ( process.env.REACT_APP_ENV || '' ).toLowerCase().includes('none') &&
                                        <div className="form-group mb-3">
                                            <FormControl className="login-credentials-wrapper">
                                                <TextField
                                                    name="appid"
                                                    value={appID}
                                                    placeholder="App ID"
                                                    onChange={e => {
                                                        setAppID(e.target.value);
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <GppGoodIcon color="secondary"/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    style={{width: '100%'}}
                                                />
                                            </FormControl>
                                        </div>
                                    }
        
                                    <div className="form-group mb-3">
                                        <FormControl className="login-credentials-wrapper">
                                            <TextField
                                                name="email"
                                                value={username}
                                                placeholder="Username"
                                                onChange={e => {
                                                    setUsername(e.target.value);
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircle color="secondary"/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                style={{width: '100%'}}
                                            />
                                        </FormControl>
                                    </div>
        
                                    <div className="form-group mb-3">
                                        <FormControl className="login-credentials-wrapper">
                                            <Input
                                                name="password"
                                                id="password"
                                                placeholder="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                onChange={e => {
                                                    setPassword(e.target.value);
                                                }}
                                                spellCheck={false}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <VpnKey color="secondary"/>
                                                    </InputAdornment>
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={e => {
                                                                setShowPassword(!showPassword);
                                                            }}
                                                        >
                                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>
        
                                    { /* <div className="forgot-credentials-link"><a href="javascript:void(0);">Forgot Credentials?</a></div> */ }
        
                                    <div className="mb-3 d-flex align-items-center justify-content-between">
                                        <Button type = "default"
                                                color="primary"
                                                className="text-uppercase login-btn"
                                                onClick={(event) => postLogin()}
                                        >
                                            LOGIN
                                        </Button>
                                    </div>
    
                                </div>
    
                                <div className="footer-info top-bottom-section">
                                    <a href="https://www.systrategy.it" target="_blank" rel="noreferrer">
                                        <img
                                            src         ="/systrategy_logo.svg"
                                            alt         ="Systrategy"
                                            draggable   ="false"
                                            className   ="systrategy-logo"
                                        />
                                    </a>
                                </div>
                                
                            </div>
    
                            <div className={ 'side right only-logo ' + env }>
                                
                                <div className="info-top info-top-bottom">
                                    <h1   className={ 'environment ' + env }>{ env.toUpperCase() }</h1>
                                    <span className={ 'environment ' + env + ' cliente-desc' }>{ customer || '' }</span>
                                </div>
                                
                                <img draggable="false" src="/roobeek_marchio.svg" alt="Roobeek®"/>
                                
                                <div className="info-bottom info-top-bottom">
                                    
                                    <div className={ 'environment ' + env }>
                                        <span className="version-number">{ ver + ' - ' + ( moment(day + '-' + month, 'DD-MM').format('D MMM')  ) }</span>
                                        <span className="database-info">{ ( DATABASE_SERVER ).split('/')[0] }</span>
                                    </div>
                                    
                                    <div className="edition">standard edition</div>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                    </MuiThemeProvider>
                </div>
                }
            </div>
        );
    }
}

export default Login;








