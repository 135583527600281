import React, {useEffect} from 'react';
import                  './SideMenu.css';
import clsx             from 'clsx';
import { makeStyles }   from '@material-ui/core/styles';
import Drawer           from '@material-ui/core/Drawer';
import Box              from '@material-ui/core/Box';
import List             from '@material-ui/core/List';
import Divider          from '@material-ui/core/Divider';
import IconButton       from '@material-ui/core/IconButton';
import MenuIcon         from '@material-ui/icons/Menu';
import ChevronLeftIcon  from '@material-ui/icons/ChevronLeft';
import Tooltip          from '@material-ui/core/Tooltip';
import ListItem         from '@material-ui/core/ListItem';
import ListItemIcon     from '@material-ui/core/ListItemIcon';
import ListItemText     from '@material-ui/core/ListItemText';
import DashboardIcon    from '@material-ui/icons/Dashboard';
import { Scrollbars }   from 'react-custom-scrollbars';

export default function SideMenu( props ) {

    const {
        menuSelected = '',
        menuItemClicked,
        drawerOpen,
        setDrawerOpen,
        drawerWidth,
        cubes,
        sTabSelected
    } = props.options;

    const useStyles = makeStyles((theme) => ({
        toolbar: {
            paddingRight: 24, // keep right padding when drawer closed
            backgroundColor: '#519657'
        },
        toolbarIcon: {
            backgroundColor: '#f5f5f6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        menuButton: {
        },
        menuButtonHidden: {
            display: 'none',
        },
        title: {
            flexGrow: 1,
            textAlign: 'left'
        },
        drawerPaper: {
            backgroundColor: '#f5f5f6',
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            overflow: 'hidden',
            /* transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),*/
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            /* transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),*/
            width: 72,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        },
        appBarSpacer: theme.mixins.toolbar,
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        }
    }));

    const
        classes = useStyles(),
        [ open,  setOpen ] = React.useState(drawerOpen),
        [ tab,   setTab  ] = React.useState(sTabSelected)
    ;

    useEffect(() => {
        setDrawerOpen(open);
        setTab(sTabSelected);
    }, [ setDrawerOpen, open, setTab, sTabSelected ]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const openLink = () => {
        window.open("https://www.systrategy.it/", "_blank");
    }

    return (

        <Drawer
            variant = "permanent"
            classes = {{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}
            open    = {open}
        >

            <div className={classes.toolbarIcon}>

                <div className="logo">
                    <img src="/roobeek_by_systrategy_logo.svg" className="cubo"  alt="Roobeek®" />
                </div>


                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon style={{color: '#606060', fontSize: 30}}/>
                </IconButton>

                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={ 'openDrawerIcon ' + clsx(classes.menuButton, open && classes.menuButtonHidden)}
                >
                    <MenuIcon/>
                </IconButton>

            </div>

            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200} >

                <List style={{marginTop: 10, paddingBottom: 0}}>
                    <div className={
                        'menu-link '
                        + ( menuSelected === 'dashboard' ? ' selected ' : '' )
                        + ( tab  === 'menu' ? 'show' : 'hide' )
                    } onClick={()=>menuItemClicked('dashboard')}>
                        <ListItem button>
                            <Tooltip title="Dashboard">
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Dashboard" className="label" />
                        </ListItem>
                    </div>
                </List>

                <Divider className={ tab === 'menu' ? 'show' : 'hide' } style={{backgroundColor: '#519657'}}/>

                <List style={{paddingBottom: 0}}>
                    {
                        ( Array.isArray(cubes) ? cubes : [] ).map( ({CUBE_DESC}) => (
                            <div className={
                                'menu-link '
                                + ( menuSelected === `cube-${CUBE_DESC.toLowerCase()}` ? ' selected ' : '' )
                                + ( tab === 'menu' ? 'show' : 'hide' )
                            }
                                 onClick={()=>menuItemClicked( CUBE_DESC )} key={CUBE_DESC}
                            >
                                <ListItem button>
                                    <Tooltip title={CUBE_DESC || ''}>
                                        <ListItemIcon>
                                            <img draggable="false" src="/green_cube.svg" className="cube"  alt="cube" />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary={CUBE_DESC} className="label" />
                                </ListItem>
                            </div>
                        ))
                    }
                </List>

                {/*
                    <List className={'menu-filters ' + (open ? 'drawerOpened' : 'drawerClosed')}
                          style={{marginTop: 10, paddingBottom: 0}}>
                        <div className="menu-link filters">
                            <ListItem button>
                                <div className='menu filters-container'></div>
                            </ListItem>
                        </div>
                    </List>
                */}

            </Scrollbars>


            { ( sTabSelected === 'menu' ) &&
                <List  className="bottom-logo">
                    <ListItem button>
                        <Box className="powered-by label">
                            <img src="/systrategy_logo.svg" className="logo" alt="Systrategy" onClick={openLink} />
                        </Box>
                    </ListItem>
                </List>
            }

        </Drawer>
    );
}
