import moment from "moment";
let timeoutId = null; // questa variabile deve essere globale al caricamento di questo modulo

export default class CommonUtilities {

    static numberFormat( num, decimals, dec_point, thousands_sep ) {
        dec_point = typeof dec_point !== 'undefined' ? dec_point : '.';
        thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : ',';

        let parts = num.toFixed(decimals).split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

        return parts.join(dec_point);
    };

    // esempio formatNumberWithOptions( '1234.5', { nOuputDecimals: 2 }); // restituisce '1.234,50'
    static formatNumberWithOptions( num, oOptions ) {

        let {
            sOuputThousandsSeparator, sOuputDecimalSeparator, nOuputDecimals, sInputDecimalSeparator, sInputThousandsSeparator
            ,sZero ,sBetweenZeroAndOne ,isToRound = false ,withSmallDecimals = false ,bDebug = false
        } = oOptions;
        
        withSmallDecimals = false; // TODO forzatura in attesa di ulteriori test
        
        // default dei parametri
        sInputDecimalSeparator   = typeof sInputDecimalSeparator   === 'string' ? sInputDecimalSeparator   : '.';
        sInputThousandsSeparator = typeof sInputThousandsSeparator === 'string' ? sInputThousandsSeparator : '';
        sOuputDecimalSeparator   = typeof sOuputDecimalSeparator   === 'string' ? sOuputDecimalSeparator   : ',';
        sOuputThousandsSeparator = typeof sOuputThousandsSeparator === 'string' ? sOuputThousandsSeparator : '.'; // ˙ (dot above)
        nOuputDecimals           = ( ( typeof nOuputDecimals === 'number')      // numero
                                     && !Number.isNaN(nOuputDecimals)           // non NaN
                                     && ( nOuputDecimals >= 0 )                 // maggiore o uguale a zero
                                   ) ? nOuputDecimals : '';

        // verifico validità numero e lo converto in stringa
        let nNum = Number(num);
        let sNum = '';
        if ( Number.isNaN(nNum) ) {
            bDebug && console.error( 'numero non valido: ', num );
            return '';
        } else {
            if ( sZero && ( nNum === 0 ) ) { return sZero; }
            if ( isToRound               ) { nNum = nNum.toFixed(nOuputDecimals); }
            sNum = nNum.toString();
        }

        // divido il numero in parte intera e decimale in base al carattere di separatore decimali di input indicato (o il punto di default)
        let [ sIntPart = '', sDecPart = '' ] = sNum.split(sInputDecimalSeparator);

        if ( sInputThousandsSeparator ) {
            sIntPart = sIntPart.split(sInputThousandsSeparator).join('');
        }

        // aggiunto il separatore di migliaia richiesto
        if ( sIntPart.length > 3 ) { sIntPart = sIntPart.replace( /\B(?=(\d{3})+(?!\d))/g, sOuputThousandsSeparator ); }

        // aggiungo zeri in coda se necessario, altrimenti tronco fino al massimo numero di decimali richiesto
        if ( nOuputDecimals !== '' ) {
            sDecPart = sDecPart.padEnd( nOuputDecimals, '0' ).substring( 0, nOuputDecimals );
        }

        const bConditionBetween = ( sBetweenZeroAndOne != null ) && ( num > -1 ) && ( num !== 0 ) && ( num < 1 ) && ( +sIntPart === 0 ) && ( +sDecPart === 0 );

        // ricompongo il numero aggiungendo il separatore di decimali richiesto se necessario
        return !withSmallDecimals ? (
            ( bConditionBetween ? sBetweenZeroAndOne : '' ) 
            + sIntPart 
            + ( !sDecPart ? '' : ( sOuputDecimalSeparator + sDecPart ) )
        ) : (<>
            <>{
                ( bConditionBetween ? sBetweenZeroAndOne : '' )
                + sIntPart
            }</><span className="smalldec">{
                ( !sDecPart ? '' : ( sOuputDecimalSeparator + sDecPart ) )
            }</span>
        </>);
        
    }


    static getFormatDate( inputDate ) {
        // torna una stringa in formato dd/mm/yyyy a partire da una stringa formato "YYYYMMDD..." altrimenti vuota

        let retVal = "";
        
        if ( ( typeof inputDate === 'string' ) && inputDate && ( inputDate.length >= 8 ) && ( +inputDate > 0 ) ) {
            try {
                const mDate = moment( inputDate.substring(0, 8), 'YYYYMMDD' );
                retVal = mDate.isValid() ? mDate.format( 'DD/MM/YYYY' ) : '';
            } catch (err) {
                console.error( 'utils - getFormatDate: ' + err.message );
            }
        }

        return retVal;
    }

    static getFormatDateTime(inputDate = '') {
        //torna una stringa in formato dd/mm/yyyy hh24:mi:ss a partire da una stringa formato "YYYYMMDDHH24MISS"

        let retVal = "";

        if ( inputDate ) {
            try {
                const yyyy = inputDate.substring(0, 4);
                const mm = inputDate.substring(4, 6);
                const dd = inputDate.substring(6, 8);

                let hh= "";
                let mi= "";
                let ss= "";
                hh = inputDate.substring(8, 10);
                mi = inputDate.substring(10, 12);
                ss = inputDate.substring(12, 14);

                retVal = `${dd}/${mm}/${yyyy} ${hh}:${mi}`;

            } catch (err) {
                console.error("CommonUtilities - getFormatDateTime: " + err.message);
            }
        }

        return retVal;
    }

    static getStringFromDateString(inputDate) {
        //torna una stringa in formato "YYYYMMDD" a partire da una stringa formato "DD/MM/YYYY"

        let retVal = "";

        try {
            const yyyy = inputDate.substring(6, 10);
            const mm = inputDate.substring(3, 5);
            const dd = inputDate.substring(0, 2);

            retVal = `${yyyy}${mm}${dd}`;

        }catch (err) {
            console.error("CommonUtilities - getStringFromDateString: " + err.message);
        }

        return retVal;
    }

    static getStringFromDate(inputDate) {
        //torna una stringa formato "YYYYMMDD"  a partire da un Date

        let retVal = "";

        try {
            const d = inputDate.getDate().toString().padStart(2, '0');
            const m = (inputDate.getMonth() + 1).toString().padStart(2, '0');
            const y = inputDate.getFullYear().toString().padStart(4, '0');
            retVal = `${y}${m}${d}`;
        }catch (err) {
            console.error("CommonUtilities - getStringFromDate: " + err.message);
        }

        return retVal;
    }

    static getDateFromString(inputDate) {
        //torna un Date a partire da una stringa formato "YYYYMMDD"

        let retVal = null;

        try {
            retVal = new Date(parseInt(inputDate.substring(0, 4)), parseInt(inputDate.substring(4, 6)) - 1, parseInt(inputDate.substring(6, 8)));
        }catch (err) {
            console.error("CommonUtilities - getDateFromString: " + err.message);
        }

        return retVal;
    }

    static getStringFromTime(inputTime) {
        //torna una stringa formato "HH24MI" a partire da un DateTime

        let retVal = "";

        try {
            const hh = inputTime.getHours().toString().padStart(2, '0');
            const mi = inputTime.getMinutes().toString().padStart(2, '0');
            retVal = `${hh}${mi}`;

        }catch (err) {
            console.error("CommonUtilities - getStringFromTime: " + err.message);
        }

        return retVal;
    }

    static getTimeFromString(inputTime) {
        //torna un Date per la parte time (hh:mi) a partire da una stringa formato "HH24MI"

        let retVal = null;

        try {
            let today = new Date();
            today.setHours(inputTime.substring(0, 2));
            today.setMinutes(inputTime.substring(2, 4));
            retVal = today;
        }catch (err) {
            console.error("CommonUtilities - getTimeFromString: " + err.message);
        }

        return retVal;
    }


    static isFloatKey(evt) {
        //controlla l'inserimento di caratteri validi per numeri con decimali (","). Ok anche negativi ("-")
        const key = evt.key;
        //passano solo i seguenti caratteri di controllo
        if (key === "Tab" || key === "Backspace" || key === "Delete" || key === "ArrowLeft" || key === "ArrowRight" || key === "Home" || key === "End") {
            return true;
        }else{
            //passano solo caratteri dei numeri (0..9), il meno ("-") e la virgola (",")
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            return (charCode === 188 ||charCode === 189 || (charCode >= 48 && charCode <= 57)|| (charCode >= 96 && charCode <= 105))
        }
    }

    static  formatInt(n) {
        if(n == null){
            return '';
        }
        const pin = parseInt(n);
        const pinf = this.numberFormat(pin, 0, ',', '.');
        return (pinf === null ? '' : pinf);
    }

    static  formatNumber(n) {
        if(n == null){
            return '';
        }
        const pin = parseFloat(n);
        const pinf = this.numberFormat(pin, 0, ',', '.');
        return (pinf === null ? '' : pinf);
    }

    static formatFloat(num, withZero = false) {
         //visualizza una stringa numerica mettendo la virgola come separatore dei decimali
        let str = '';

        if(num != null && num.toString().trim() !== ""){

            if(withZero && num === 0){
                return '0';
            }


            const f =  +(Math.round(num + "e+2")  + "e-2");
            str = f.toString();
            str = str.replace(/\./g, ',');
        }

        return str;
    }

    static formatFloatToDB(strIn) {
        //formatta una stringa numerica mettendo il punto come separatore dei decimali
        let str = '';
        if(strIn != null){
            str = strIn.toString().trim();
            if(str !== ''){
                str = str.replace(/,/g, '.')
            }
        }
        return str;
    }

    static getISOWeekNumber(dt)
    {
        let tdt = new Date(dt.valueOf());
        let dayn = (dt.getDay() + 6) % 7;
        tdt.setDate(tdt.getDate() - dayn + 3);
        let firstThursday = tdt.valueOf();
        tdt.setMonth(0, 1);
        if (tdt.getDay() !== 4)
        {
            tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
        }
        return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    }

    static truncateString(str, limit) {
        if (str == null) {
            return '';
        }

        if(str.length < limit){
            return str;
        }else{
            return str.substr(0, limit) + "...";
        }
    };

    static formatDateTime = (

            stringDate,

            {   // defaults
                 input    = 'YYYYMMDDHHmmss'
                ,output   = 'D MMMM YYYY HH:mm'
                ,useTimezone = false
                ,fromNow  = false
            } = { input: 'YYYYMMDDHHmmss', output: 'D MMMM YYYY HH:mm', fromNow: false }

        ) => {

        let   outputDate     = '';
        const cellMomentDate = moment(stringDate, input);
        const cellDate       = cellMomentDate.toDate();
        const timezoneOffset = cellDate.getTimezoneOffset() * -1; // Es. -120 (minuti) uguale a UTC+2

        // eccezione inserita in attesa di modifiche lato database, cioè in fase di rebuild che venga salvata la data UTC
        const sCustomer = ( localStorage.getItem('appid') || process.env.REACT_APP_ENV || 'undefined' ).toLowerCase();
        const isCairo   = sCustomer.includes('cairo');

        if ( cellMomentDate.isValid() ) {

            if ( useTimezone && isCairo ) {

                if ( timezoneOffset >= 0 ) {
                    cellMomentDate.add(      timezoneOffset,      'minutes' );
                } else {
                    cellMomentDate.subtract( timezoneOffset * -1, 'minutes' );
                }

            }

            if ( output ) {
                outputDate = cellMomentDate.format( output );
                if ( useTimezone && isCairo ) {
                    // outputDate += ' UTC ' + ( timezoneOffset >= 0 ? '+' : '' ) + ( timezoneOffset / 60 );
                }
            }

            if ( fromNow ) {
                outputDate += output ? ' (' : '';
                outputDate += cellMomentDate.fromNow();
                outputDate += output ? ')' : '';
            }

        }

        return outputDate;

    };

    // vengono aggiunti i punti come separatori delle migliaia solo alla parte intera, la parte decimale rimane inalterata
    // funziona sia con numeri che hanno come separatore di decimali il "." (punto) sia la "," (virgola)
    static formatPositiveNum = ( numero, decimali = 0 ) => {
        // 5 agosto 2020
        let retVal = '', stringNum = '', numParsed = '' ;
        if ( numero != null ) {

            // Elimino i caratteri che non sono numeri (lascio il segno meno e il punto)
            stringNum = numero.toString().replace(',', '.');
            stringNum = stringNum.replace(/[^0-9.-]?/gi, '');
            numParsed = parseFloat(stringNum);

            if ( !isNaN(numParsed) && numParsed >= 0 ) {
                retVal += numParsed.toFixed(decimali);
                retVal =  retVal.replace('.', ',');
                let [ strInt, strDec ]  = retVal.split(',');

                if ( strInt.length > 3 ) { // Aggiungo il separatore delle migliaia - ogni 3 numeri sulla parte intera
                    strInt = strInt.replace(/\B(?=(?:\d{3})+(?!\d))/g, '.'); // oppure '˙'
                }
                return strInt + ',' + strDec;
            }

        }
        return '';
    }

    // vengono aggiunti i punti come separatori delle migliaia
    // anche se viene passato un numero con decimali viene presa in considerazione e restituita solo la parte intera
    static formatPositiveIntNum = numero => {
        // 5 agosto 2020
        let stringNum = '', numParsed = '' ;
        if ( numero != null ) {

            stringNum       = numero.toString();            // converto in stringa per usare split()
            let [ strInt ]  = stringNum.split('.');         // prendo solo il primo elemento dell'array (la parte intera)
            numParsed       = parseInt(strInt);             // eseguo il parseInt()

            if ( !isNaN(numParsed) && numParsed >= 0 ) {    // se il risultato è NaN oppure un numero negativo esco
                let numAsString = numParsed.toString();     // altrimenti riconverto in stringa il numero, per controllare la lunqhezza e usare replace()
                if ( numAsString.length > 3 ) {             // aggiungo il separatore delle migliaia - ogni 3 numeri sulla parte intera
                    numAsString = numAsString.replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // oppure '˙'
                }
                return numAsString;
            }

        }
        return '';
    }

    static defaultSort = () => {
        let toStr      = anyVar => ( anyVar || 0 ).toString();          // per avere stringhe simili da confrontare (i falsy vengono uniformati)
        let uniformRes = res    => res < 0 ? -1 : ( res > 0 ? 1 : 0 );  // serve per uniformare i risultati del localeCompare (-1 0 +1)
        return ( e1, e2 ) => uniformRes(                                // restituisce un valore uniformato per la comparazione di stringhe
            toStr(e1).localeCompare( toStr(e2), 'en', {numeric: false, sensitivity: 'base'} )
        )
    };
    
    // sortBy consente di ordinare un array di oggetti in base ad un array di proprietà (chiavi) scelte,
    // specificando per ognuna se in ordine crescente o decrescente (con 'ASC', 'DESC', oppure '1', '-1')
    static sortBy = ( arrayOfObjects, arrayOfProperties, arrayOfSortingType ) => {
        // console.log('arrayOfProperties: ', arrayOfProperties)
        // console.log('arrayOfSortingType: ', arrayOfSortingType)
        // se non è un array, lo converto in array ( per chiamare il sortBy con un solo parametro di tipo string )
        if ( !Array.isArray(arrayOfProperties) ) { arrayOfProperties = [ arrayOfProperties + '' ]; }
        
        let
            // toStr serve per avere stringhe simili da confrontare (i falsy vengono uniformati)
            toStr                = anyVar => ( anyVar || 0 ).toString()
            // uniformRes serve per uniformare i risultati del localeCompare ( -1 0 +1 )
            ,uniformRes           = ( res, sortType ) => (
                ( ( res < 0 ) ? -1 : ( res > 0 ? 1 : 0 ) ) * ( [ 'DESC', '-1' ].includes( ( sortType + '' ).toUpperCase() ) ? -1 : 1  )
            )
            // compare restituisce un valore uniformato per la comparazione di stringhe
            ,compare              = ( e1, e2, sortType ) => uniformRes(
                 toStr(e1).localeCompare( toStr(e2), 'en', {numeric: true, sensitivity: 'base'} )
                ,sortType
            )
            // validateString controlla se è una stringa non vuota
            ,validateString       = str        => ( typeof str === 'string' && str !== '' ) ? str : ( 'err: "' + str + '"' )
            // validateProperty restituisce il valore di quella proprietà (eventualmente normalizzato a zero)
            ,validateProperty     = ( elToDebug, prop )  => {
                // console.assert(
                //      typeof elToDebug[prop] !== 'undefined'
                //     ,'ERROR in sortBy: proprietà ' + prop + ' non trovata in'
                //     ,elToDebug
                // );
                return elToDebug[prop] || 0;
            }
            // compareAllProperties restituisce ( -1 0 +1 ) in base al confronto di tutte le proprietà dei due oggetti
            ,compareAllProperties = ( obj1, obj2, props = [], sortTypes = [] ) => {
                let retVal = 0;
                for ( let nProp = 0; nProp < props.length; nProp++ ) {
                    const property = validateString(props[nProp]);
                    retVal         = retVal || compare(
                         validateProperty(obj1, property)
                        ,validateProperty(obj2, property)
                        ,sortTypes[nProp]
                    );
                }
                return retVal;
            }
        ;
        // restituisce l'array di oggetto ordinato
        return [...arrayOfObjects].sort( ( a, b ) => compareAllProperties( a, b, arrayOfProperties, arrayOfSortingType ) );
        
    }

    static formatSortingMeasureClauseForDB( misura, direzione, nDimensionsLength ) {
        let finalString = '';
        for ( let i = 0 ; i < nDimensionsLength; i++ ) {
            finalString += misura + '_' + i + ' ' + ( direzione || '' ) + ', ';
        }
        return finalString + ' ' + misura + ' ' + ( direzione || '' );

    }

    /**
     *  funzione utile per confrontare due stati e ottenere una stringa (indentata) con le differenze
     * @param oOld
     * @param oNew
     * @param nRecursive
     * @returns {string|*}
     */
    static getDifference( oOld, oNew, nRecursive = 0 ) {

        if ( typeof oNew === 'undefined' ) {
            return 'MANCANTE ' + JSON.stringify(oOld);
        } else if ( oOld && ( typeof oOld === 'object' ) && oNew && ( typeof oNew === 'object' ) ) {

            let asDifferences = [];

            const asKeys = ( [ ...new Set([ ...Object.keys(oOld), ...Object.keys(oNew) ] ) ] );
            for ( let nIndex = 0; nIndex < asKeys.length; nIndex++ ) {

                const
                    sKey   = asKeys[nIndex],
                    newValue = oNew[sKey],
                    diff   = this.getDifference( oOld[sKey], newValue, nRecursive + 1 ),
                    spaces = '    '.repeat(nRecursive),
                    final  = ( diff.includes('MANCANTE') || diff.includes('AGGIUNTIVO') ) ? diff :
                             Array.isArray( newValue )    ? `[ ${diff} \n${spaces}]` :
                             ( typeof newValue === 'object' ) ? `{ ${diff} \n${spaces}}` :
                             diff
                ;

                if ( diff !== '' ) {
                    asDifferences.push( `\n${spaces}${sKey}: ` + final );
                }

            }

            return asDifferences.join(','); // se nessuna delle chiavi dell'oggetto è diversa ritornerà stringa vuota

        } else if ( oOld !== oNew ) {
            return ( typeof oOld === 'undefined' ? 'AGGIUNTIVO ' : '' ) + JSON.stringify(oNew);
        } else {
            return ''; // nessuna differenza
        }

    }

    static logDifferences( sText, oOld, oNew ) {
        if ( JSON.stringify(oOld) !== JSON.stringify(oNew) ) {
            console.log(sText);
            console.log( this.getDifference(oOld, oNew ) );
        }
    }

    static setStateAsync( state, that ) {
        return new Promise((resolve) => {
            that.setState(state, resolve);
        });
    }


    // trasforma un array di oggetti in un array di stringhe prendendo il valore della prima proprietà
    // Es. da [ { CHANNEL_DESC: 'CANALE 5' }, { CHANNEL_DESC: 'RAI 1' } ] a [ 'CANALE 5', 'RAI 1' ]
    static getAsValuesFromAo( arrObj ) {
        return ( arrObj && ( arrObj.length > 0 ) ) ? arrObj.map( o => o[ Object.keys(o)[0] ]) : [];
    }

    // trasforma un array di stringhe in un array di oggetti con una proprietà fissa come nome di campo
    // Es. da [ 'CANALE 5', 'RAI 1' ] a [ { CHANNEL_DESC: 'CANALE 5' }, { CHANNEL_DESC: 'RAI 1' } ]
    static getAoFromAsValues( arrStr, sPropertyName ) {
        return ( sPropertyName && arrStr && ( arrStr.length > 0 ) ) ? arrStr.map( s => ({ [ sPropertyName ]: s }) ) : [];
    }

    static GETsObjectFirstKeyFromAO( ao ){
        return Object.keys(( ao || [] )[0] || {} )[0] || '';
    }

    // da usare durante la digitazione in un campo di input
    static isValidPartialNumber( num ) {
        return ( num === '-' ) || /(?=^.{1,20}$)(^[-]?([0-9]+)([.]?)([0-9]+)?$)/.test(num)
    }

    static isValidNumber( num ) {
        return /^(?=.{1,20}$)[-]?[0-9]\d*(\.\d+)?$/.test(num)
        // return ( ( typeof num === 'number' ) || ( ( typeof num === 'string' ) && ( num !== '' ) ) ) && !Number.isNaN(+num)
    }

    static isValidNumberOrEmpty( num ) {
        return ( num === '' ) || /^(?=.{1,20}$)[-]?[0-9]\d*(\.\d+)?$/.test(num)
    }
    
    static isTruthyOrZero( val ) {
        return ( val || ( val === 0 ) )
    }


    static mapMonths(n) {
        return  ( ['number','string'].includes(typeof n) && !Number.isNaN(+n) && ( +n > 0 ) && ( +n < 13 ) )
                    ? [ 'JANUARY', 'FEBRUARY' , 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER' ][ (+n) - 1 ]
                    : (
                        ( ( n === '' ) || ( n == null ) )
                            ? ''
                            : '?'
                    );
    }

    static convertDataType = ( value, sType ) => {
        return (
            !( [ 'string', 'number' ].includes( typeof value ) ) ? '' // se non è stringa o numero restituisco stringa vuota
            : ( sType === 'D' ) ? this.getFormatDate(value)  // se il datatype è 'D' allora è una data
            : ( sType === 'M' ) ? this.mapMonths(value)            // se il datatype è 'M' allora è un mese
            : ( sType === 'T' ) ? 'Q' + value                         // se il datatype è 'T' allora è un trimestre
            : ( sType === 'S' ) ? 'H' + value                         // se il datatype è 'S' allora è un semestre
            : value                                                   // in tutti gli altri casi lo restituisco così com'è
        )
    }

    static removeSpecialChars = s => s.toString().replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

    static debounce(fn, time) {

        function wrapper(...args) {

            if ( timeoutId ) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(
                () => {
                    timeoutId = null;
                    fn(...args);
                }, time
            );

        }

        return wrapper;

    }

    /*
        if ( Array.isArray(anyVariable) ) {
            return anyVariable.map(deepCopy);
        } else if ( typeof anyVariable === 'object' ) {
            return Object.keys(anyVariable).reduce(
                ( o, sObjectKey ) => ({ ...o, [sObjectKey]: deepCopy( anyVariable[sObjectKey] ) }),
                {}
            );
        } else {
            return anyVariable;
        }
    */

    static deepCopyArrayOrObject = ( variable ) => {
        function deepCopy( anyVariable ) {
            if ( ( typeof anyVariable !== 'object' ) || ( anyVariable === null ) ) {
                return anyVariable;
            }
            const ObjectOrArray = Array.isArray(anyVariable) ? [] : {};
            let key;
            for ( key in anyVariable ) {
                ObjectOrArray[key] = deepCopy( anyVariable[key] );
            }
            return ObjectOrArray;
        }
        return deepCopy(variable);
    }

    static sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static arrayMove = (array, fromIndex, toIndex) => {

        const newArray = [...array];

        const startIndex = fromIndex < 0 ? newArray.length + fromIndex : fromIndex;

        if (startIndex >= 0 && startIndex < newArray.length) {
            const endIndex = toIndex < 0 ? newArray.length + toIndex : toIndex;

            const [item] = newArray.splice(fromIndex, 1);
            newArray.splice(endIndex, 0, item);
        }


        return newArray;

    }
    
    /* Es. logObject( 'alfabeto:', { alpha: 1, beta: 2, gamma: 3 }, 'red'); */
    static logObject = ( prefix = '', obj = {}, postfix = '', nPad = 40, color = 'blue' )  => {
        
        let keys        = Object.keys(obj);
        let coloredJson = keys.map( s => '"' + s + '": ' + obj[s] ).join(','); // conversione manuale dell'oggetto in JSON
        let styles      = []; // array che conterrà gli stili CSS
        
        // per ogni chiave dell'oggetto
        keys.forEach( key => {
            
            const searchStr = `"${ key }":`; // stringa specifica per cercare la chiave in coloredJson
            
            // aggiunge %c prima e dopo la chiave
            coloredJson = coloredJson.replace((new RegExp(searchStr)), `"%c${ key }%c":`);
            
            styles.push(`color:${ color }`); // aggiunge lo stile CSS di inizio all'array degli stili
            styles.push(`color:black`);      // aggiunge lo stile CSS di fine all'array degli stili
            
        });
        
        // aggiunge padding di spazi per incolonnare meglio chiavi e valori
        coloredJson = coloredJson.split(',').map( s => s.padEnd( nPad, ' ' ) ).join(',');
        
        // stampa il JSON colorato con gli stili CSS corrispondenti
        // aggiungendo prima e dopo eventuali stringhe passate a questa funzione
        console.log( prefix + ' { ' + coloredJson + ' } ' + postfix, ...styles );
        
    }
    
    // Esempio di utilizzo
    // const matrix = [[{ a: 1, b: 1 }, { a: 2, b: 2 }], [{ a: 3, b: 3 }, { a: 4, b: 4 }]];
    // const textTable = logAAO(matrix);
    // console.log(textTable);
    static logAAO = ( matrix, keys, limitObject = 30 ) => {
        let table = '';
        const sColumnDelimiter = '║'; // ║
        
        const print = (v) => ( v && ( typeof v === 'object' ) ) ? JSON.stringify(v).slice(0,limitObject||30) : ( v + '');
        
        for (const row of matrix) {
            const maxKeyLength = Math.max(...row.flatMap(
                obj => (
                    keys
                    ? keys.filter(key => obj.hasOwnProperty(key)).map(key => key.length)
                    : Object.keys(obj).map(key => key.length)
                )
            ));
            const maxValueLength = Math.max(...row.flatMap(
                obj => (
                    keys
                    ? keys.filter(key => obj.hasOwnProperty(key)).map(key => print(obj[key]).length)
                    : Object.values(obj).map(value => print(value).length)
                )
            ));
            const columnWidth = maxKeyLength + maxValueLength + 3;
            
            table += ' '.repeat(row.length * columnWidth) + '\n';
            
            const maxEntries = keys ? keys.length : Math.max(...row.map(obj => Object.entries(obj).length));
            for (let i = 0; i < maxEntries; i++) {
                for (const obj of row) {
                    const entries = (
                        keys
                        ? keys.filter(key => obj.hasOwnProperty(key)).map(key => [key, obj[key]])
                        : Object.entries(obj)
                    );
                    if (i < entries.length) {
                        const [key, value] = entries[i];
                        table += sColumnDelimiter + ` ${key.padEnd(maxKeyLength)} : ${print(value).padEnd(maxValueLength)} `;
                    } else {
                        table += ' '.repeat( columnWidth + 3 );
                    }
                }
                table += sColumnDelimiter + '\n';
            }
            
            table += ' ' + '_'.repeat(row.length * ( columnWidth + 3 ) );
        }
        
        return table;
    }
    
    /**
     * Crea una classe CSS per applicare bordi multipli a elementi HTML.
     * @param {string}   sClassName     - Nome base della classe CSS da creare.
     * @param {string[]} asColors       - Array di colori per i bordi (e quindi numero di bordi)
     * @param {number}  [borderWidth=1] - Larghezza di ogni bordo in pixel.
     * @param {string}  [side='left']   - Lato su cui applicare i bordi ('left', 'right', o 'both' per entrambi).
     * @returns {string}                - Restituisce il nome base della classe
    **/
    static createMultiBorderClass( sClassName, asColors, borderWidth = 1, side = 'left' ) {
        
        // Crea un elemento <style> per la nuova regola CSS
        const style      = document.createElement('style');
        const totalWidth = asColors.length * ( borderWidth || 1 );
        let leftShadows  = '';
        let rightShadows = '';
        
        // Genera le regole CSS per i bordi interni
        for ( let i = 0; i < asColors.length; i++ ) {
            // Funzione per creare la regola css
            const rule = ( segno = '' ) => `inset ${ segno }${ ( i + 1 ) * borderWidth }px 0 0 0 ${ asColors[i] }`;
            // Aggiunge la regola per il lato sinistro
            leftShadows  += ( leftShadows  ? ', ' : '' ) + rule('');
            // Aggiunge la regola per il lato destro
            rightShadows += ( rightShadows ? ', ' : '' ) + rule('-');
        }
        
        if ( ( side === 'left' ) || ( side === 'both' ) ) {
            style.textContent += `
                .${sClassName}-left {
                  box-shadow: ${leftShadows};
                  padding-left: ${totalWidth}px;
                }
            `;
        }
        
        if ( ( side === 'right' ) || ( side === 'both' ) ) {
            style.textContent += `
                .${sClassName}-right {
                  box-shadow: ${rightShadows};
                  padding-right: ${totalWidth}px;
                }
            `;
        }
        
        // Aggiunge la nuova regola CSS al <head> del documento
        document.head.appendChild(style);
        
        // Restituisce il nome della classe creata
        return sClassName;
        
    }
    
    /**
     * Genera regole CSS per bordi colorati in una tabella.
     * @param {string[]}        asColors   - Array di colori da utilizzare nelle regole.
     * @param {string|function} sDarkColor - Colore scuro fisso o funzione per generarlo.
     * @returns {string}                   - Stringa contenente le regole CSS generate.
     *
     * @example
     * const asColors  = ["verde04", "verde03", "verde02" ]; // dal più scuro al più chiaro
     * const darkColor = 'var(--verde06)'; // (currentColor) => `var(--${ currentColor.slice(0, -2) }${ ( ( +( currentColor.slice(-2) ) + 2 ) + '' ).padStart(2,'0') })`
     * const sRules    = generateBorderColorRules( asColors, darkColor );
     * // risultato: (esempio senza bordi scuri e senza bordi grigi, solo per le th)
     * // .cubeTable th.border-color3   { box-shadow: inset 5px 0 0 0 var(--verde04 ) !important; }
     * // .cubeTable th.border-color32  { box-shadow: inset 5px 0 0 0 var(--verde04 ), inset 10px 0 0 0 var(--verde03 ) !important; }
     * // .cubeTable th.border-color31  { box-shadow: inset 5px 0 0 0 var(--verde04 ), inset 10px 0 0 0 var(--verde02 ) !important; }
     * // .cubeTable th.border-color321 { box-shadow: inset 5px 0 0 0 var(--verde04 ), inset 10px 0 0 0 var(--verde03 ), inset 15px 0 0 0 var(--verde02 ) !important; }
     * // .cubeTable th.border-color2   { box-shadow: inset 5px 0 0 0 var(--verde03 ) !important; }
     * // .cubeTable th.border-color21  { box-shadow: inset 5px 0 0 0 var(--verde03 ), inset 10px 0 0 0 var(--verde02 ) !important; }
     * // .cubeTable th.border-color1   { box-shadow: inset 5px 0 0 0 var(--verde02 ) !important; }
     * 
     * // ad esempio per 4 colori la sequenza generata sarebbe: 4, 43, 432, 4321, 431, 42, 421, 41, 3, 32, 321, 31, 2, 21, 1
     **/
    static generateBorderColorRules(
         asColors   = ['verde04','verde03','verde02'] // ['verde05','verde04','verde03','verde02']
        ,sDarkColor = 'var(--verde06)'
    ) {
        
        // Determina il colore scuro
        const getDarkColor = ( typeof sDarkColor === 'function' ) ? sDarkColor : () => sDarkColor;
        
        let sRules  = '\n';
        
        /**
         * Genera una regola CSS per una specifica combinazione di colori.
         * @param {number[]} anColorIndexes - Indici dei colori da utilizzare per questa regola.
         */
        const generateRule = ( anColorIndexes ) => {
            
            let sShadows  = '';
            let nOffset   = 1;
            let sRuleName = '';
            
            // Itera attraverso gli indici dei colori forniti
            for ( const nIndex of anColorIndexes ) {
                // Aggiunge il colore scuro
                sShadows  += `inset ${ nOffset }px 0 0 0 ${ getDarkColor( asColors[ nIndex ] ) }, `;
                nOffset   += 4; // Incrementa l'nOffset di 4px per il prossimo colore
                
                // Aggiunge il colore base
                sShadows  += `inset ${ nOffset }px 0 0 0 var(--${ asColors[ nIndex ]} ), `;
                nOffset   += 1; // Incrementa l'nOffset di 1px per il prossimo colore scuro (bordo divisore tra colori)
                
                // viene generato un nome di regola css in base al colore del bordo
                // (più scuro il primo a partire da sinistra e poi man mano più chiaro verso destra) 
                // Es: per asColors = ['verde04', 'verde03', 'verde02'], 'verde04' sarà 3, 'verde03' sarà 2, 'verde02' sarà 1
                sRuleName += ( asColors.length - nIndex );
            }
            
            // Aggiunge l'ultimo colore scuro
            sShadows += `inset ${ nOffset }px 0 0 0 ${ getDarkColor( asColors[ anColorIndexes[ anColorIndexes.length - 1 ] ] ) }`;
            
            // Genera le regole CSS per th e td
            sRules   += `.cubeTable th.border-color${ sRuleName } { box-shadow: ${ sShadows } !important; }\n`;
            sRules   += `.cubeTable td.border-color${ sRuleName } { box-shadow: ${ sShadows }, inset 0 -1px 0 0 var(--grigio01) !important; }\n`;
            
        };
        
        /**
         * Genera tutte le possibili combinazioni di indici.
         * 
         * @param   {number}     n - Numero totale di colori.
         * @returns {number[][]}     Array di combinazioni di indici.
         * @example
         * // Per n = 3, restituirà:
         * // [[0], [0,1], [0,1,2], [0,2], [1], [1,2], [2]]
         */
        const generateCombinations = (n) => {
            
            const aanResults = [];
            
            /**
             * Funzione helper ricorsiva per generare combinazioni, manipola aanResults
             * 
             * @param {number}   nStart    - Indice di partenza per la combinazione corrente.
             * @param {number[]} anCurrent - Combinazione corrente in costruzione.
             * @example
             * // Se n = 3, una chiamata potrebbe essere:
             * // generateHelper(1, [0])
             * // Questo aggiungerà [0,1] e [0,1,2] a aanResults
             */
            const generateHelper = ( nStart, anCurrent ) => {
                
                aanResults.push( [...anCurrent] );  // Aggiunge la combinazione corrente al risultato
                
                // Esempio: Se nStart = 1 e anCurrent = [0], questo ciclo eseguirà 2 iterazioni
                for ( let i = nStart; i < n; i++ ) {
                    anCurrent.push(i);  // Aggiunge l'indice corrente alla combinazione
                    // Esempio: Prima iterazione -> anCurrent diventa [0,1]
                    generateHelper( i + 1, anCurrent );  // Chiamata ricorsiva per generare sottocombinazioni
                    anCurrent.pop();  // Rimuove l'ultimo elemento per backtracking
                    // Esempio: Dopo il backtracking, anCurrent torna a [0]
                }
                
            };
            
            generateHelper( 0, [] );
            
            // Esempio: Per n = 3, aanResults a questo punto sarà:
            // [[], [0], [0,1], [0,1,2], [0,2], [1], [1,2], [2]]
            
            return aanResults.slice(1);  // Rimuove la combinazione vuota iniziale
            // Esempio: Risultato finale per n = 3:
            // [[0], [0,1], [0,1,2], [0,2], [1], [1,2], [2]]
        };

        // Genera tutte le combinazioni possibili di indici e le regole CSS per ogni combinazione
        // Esempio: Se asColors.length = 3, genererà regole per [0], [0,1], [0,1,2], [0,2], [1], [1,2], [2]
        generateCombinations( asColors.length )
            .forEach( generateRule );
        
        return sRules;
    }
    
    /**
     * Aggiunge regole CSS al DOM creando o aggiornando un elemento <style>.
     * @param {string} cssRules - Le regole CSS da aggiungere al DOM.
     * @param {string} sHtmlStyleID - l'id specifico per il blocco stile
     * @throws {Error} Se non è possibile creare o aggiornare l'elemento <style>.
     *
     * @example
     * addCSSRulesToDOM(sRules);
     */
    static addCSSRulesToDOM( cssRules, sHtmlStyleID ) {
        
        // recupero lo stile attuale (se c'è), si tratta di un nodo style con id
        const previousStyle = document.getElementById(sHtmlStyleID);
        // se c'è lo rimuovo
        if ( previousStyle ) { previousStyle.parentNode.removeChild(previousStyle); }
        // in ogni caso lo creo, assegnando l'id scelto
        const styleElement  = document.createElement('style');
        styleElement.id     = sHtmlStyleID || 'myCustomStyles';
        
        // Aggiungi l'elemento <style> all'head del documento
        const head = document.head || document.getElementsByTagName('head')[0];
        if (!head) {
            throw new Error("Impossibile trovare l'elemento head nel documento.");
        }
        head.appendChild(styleElement);
        
        // Aggiorna il contenuto dell'elemento style con le nuove regole CSS
        try {
            styleElement.textContent = cssRules;
        } catch (error) {
            throw new Error(`Impossibile aggiungere le regole CSS: ${error.message}`);
        }
        
        // Verifica che le regole siano state effettivamente aggiunte
        if (!styleElement.sheet) {
            throw new Error("Impossibile creare il foglio di stile.");
        }
        
    }
    
    /** generateLeftRulesForSticky
     * Genera regole CSS per il blocco delle colonne in una tabella cube.
     *
     * @param {number} nColumns - Il numero di colonne per cui generare le regole CSS.
     * @returns {string} Il CSS generato per il blocco delle colonne.
    **/
    static generateLeftRulesForSticky( nColumns ) {
        // Selettore di base
        const baseSelector = '.cubeTable.colsLocked th:nth-of-type';
        let css = '';
        
        /**
         * Genera la parte calc() del valore left per una colonna specifica.
         *
         * @param {number} n - Il numero della colonna corrente.
         * @returns {string} La stringa calc() per il valore left.
        **/
        function generateLeftCalc(n) {
            if ( n === 1 ) return '0'; // La prima colonna ha sempre left: 0
            
            // Genera un array di variabili CSS per le colonne precedenti
            const vars = Array.from(
                {length: n - 1},
                (_, i) => `var(--Col${String(i + 1).padStart(2, '0')})`
            );
            
            // Unisce le variabili in una singola espressione calc()
            return `calc(${ vars.join(' + ') })`;
        }
        
        // Genera una regola CSS per ogni colonna
        for ( let i = 1; i <= ( nColumns + 1 ); i++ ) {
            css += `${ baseSelector }(${ ( i === ( nColumns + 1 ) ) ? ( 'n+' + i ) : i }) { left: ${ generateLeftCalc(i) } !important; }\n`;
        }
        
        return css;
    }
    
    
    /**
     * Esegue un merge profondo di più oggetti in un oggetto target.
     * (è già efficiente sia come utilizzo di memoria sia come tempi di esecuzione)
     *
     * @param {...Object} aSources - Gli oggetti sorgente da unire nell'oggetto target.
     * @returns {Object} L'oggetto target modificato dopo il merge.
     *
     * @example
     * const obj1 = { a: { b: 2 } };
     * const obj2 = { a: { c: 3 }, d: 5 };
     * const risultato = deepMerge(obj1, obj2);
     * // risultato sarà { a: { b: 2, c: 3 }, d: 5 }
     */
    static staticDeepMerge( ...aSources ) {
        
        function deepMerge(...sources) {
            
            // Inizializza l'oggetto target con il primo oggetto sorgente, o un oggetto vuoto
            let target = sources[0] || {};
            
            // Itera sugli oggetti sorgente a partire dal secondo (indice 1)
            for ( let source of sources ) {
                
                // Itera su tutte le proprietà dell'oggetto sorgente corrente
                for ( let key in source ) {
                    
                    // Verifica se la proprietà appartiene direttamente all'oggetto (non ereditata)
                    if ( source.hasOwnProperty(key) ) {
                        
                        // Verifica se il valore della proprietà è un oggetto (e non null)
                        if ( source[key] && ( typeof source[key] === 'object' ) ) {
                            // Se la proprietà è un oggetto, esegue una chiamata ricorsiva per unire gli oggetti annidati
                            if ( !target[key] || ( typeof target[key] !== 'object' ) ) {
                                target[key] = {};
                            }
                            deepMerge( target[key], source[key] );
                            
                        } else {
                            // Se la proprietà non è un oggetto, assegna direttamente il valore
                            target[key] = source[key];
                        }
                        
                    }
                    
                }
                
            }
            
            // Restituisce l'oggetto target modificato
            return target;
            
        }
        
        return deepMerge( ...aSources );
        
    }

}
